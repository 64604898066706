import React, { useEffect, useState } from "react";
import "styles/walmart.scss";
import { mapStringToDefinedMessage } from "lib/translate/mapStringToDefinedMessage";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";
import { getLanguage } from "lib/language";
import feature_icon_1 from "images/walmart/feature_icon_1.png";
import feature_icon_2 from "images/walmart/feature_icon_2.png";
import feature_icon_3 from "images/walmart/feature_icon_3.png";
import feature_icon_4 from "images/walmart/feature_icon_4.png";
import walmart_image_cn from "images/walmart/walmart_image_cn.png";
import walmart_image_en from "images/walmart/walmart_image_en.png";

export default function Walmart(): JSX.Element {
  const translate =  {
    title: mapStringToDefinedMessage("Key Features  1"),
    subTitle1: mapStringToDefinedMessage("key_features_subtitle_1"),
    subTitle2: mapStringToDefinedMessage("key_features_subtitle_2"),
    subTitle3: mapStringToDefinedMessage("key_features_subtitle_3"),
    subTitle4: mapStringToDefinedMessage("key_features_subtitle_4"),
    body1: mapStringToDefinedMessage("key_features_body_1"),
    body2: mapStringToDefinedMessage("key_features_body_2"),
    body3: mapStringToDefinedMessage("key_features_body_3"),
    body4_1: mapStringToDefinedMessage("key_features_body_4_1"),
    body4_2: mapStringToDefinedMessage("key_features_body_4_2"),
    body4_3: mapStringToDefinedMessage("key_features_body_4_3"),
    start_selling: mapStringToDefinedMessage("start_selling"),
    Walmart: mapStringToDefinedMessage("Walmart"),
    today: mapStringToDefinedMessage("today_FundPark"),
  };

  const results = mapToTranslatedProps(translate)
  const { title, subTitle1, subTitle2, subTitle3, subTitle4, body1, body2, body3, body4_1, body4_2, body4_3, start_selling, Walmart, today } = results  

  const [walmartSlogan, setWalmartSlogan] = useState(walmart_image_en)
  
  const language = getLanguage();

  useEffect(()=>{
    if (language === 'en'){
      setWalmartSlogan(walmart_image_en)
    } else {
      setWalmartSlogan(walmart_image_cn)
    }
  },[language])

  return (
    <div className="walmartPageContainer">
      <div className="sloganContainer">
        <img src={walmartSlogan} alt="walmartSlogan" className="walmartSlogan" />
        <div className="keyFeaturesContainer">
            <div className="keyFeaturesTitle">
              <span>{title}</span>
            </div>
            <div className="keyFeaturesItems">
              <div className="container featuresContainer" >
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 ">
                  
                  <div className="keyFeaturesItemContainer">
                    <div className="wordContainer">
                      <div className="wordTitle">
                        <div className="iconContainer">
                          <img src={feature_icon_1} alt="icon" className="icon"/>
                        </div>
                        <span className="wordingTitle">{subTitle1}</span>
                      </div>
                      <div className="wordBody">
                        <span>
                          {body1}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="keyFeaturesItemContainer">
                    <div className="wordContainer">
                      <div className="wordTitle">
                        <div className="iconContainer">
                          <img src={feature_icon_2} alt="icon" className="icon"/>
                        </div>
                        <span className="wordingTitle">{subTitle2}</span>
                      </div>
                      <div className="wordBody">
                        <span>
                          {body2}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="keyFeaturesItemContainer">
                    <div className="wordContainer">
                      <div className="wordTitle">
                        <div className="iconContainer">
                          <img src={feature_icon_3} alt="icon" className="icon"/>
                        </div>
                        <span className="wordingTitle">{subTitle3}</span>
                      </div>
                      <div className="wordBody">
                        <span>
                          {body3}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="keyFeaturesItemContainer">
                    <div className="wordContainer">
                      <div className="wordTitle">
                        <div className="iconContainer">
                          <img src={feature_icon_4} alt="icon" className="icon"/>
                        </div>
                        <span className="wordingTitle">{subTitle4}</span>
                      </div>
                      <div className="wordBody">
                        <ul>
                          <li>{body4_1}</li>
                          <li>{body4_2}</li>
                          <li>{body4_3}</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="keyFeaturesStartNow">
              <div className="row">
                <div className="startNowSectionLeft col-sm-4">
                  <img src="https://aimg8.dlssyht.cn/u/2022819/module/simplepicbackground/7305/14608213_1688098641.jpeg?x-oss-process=image/resize,m_fixed,w_299,h_202,limit_0" alt="pic" className="pic"/>
                </div>
                <div className="startNowSectionRight col-sm-8">
                  <span>{start_selling} <a href="https://seller.walmart.com/signup?q=&amp;origin=solution_provider&amp;src=0018Y00002z0an7" target="_blank" rel="noopener noreferrer" className="walmartLink"><u>{Walmart}</u></a> {today}</span>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}
