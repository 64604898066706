import {BubbleIntroBlockProps} from "interface/bubbleIntroInfo.interface";
import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";

import henry from "images/staffPhoto/henry.png"
import bear from "images/staffPhoto/bear.png"
import anson from "images/staffPhoto/anson.png"
import hay from "images/staffPhoto/hay.png"
import paul from "images/staffPhoto/paul.png"


export const staffIntroInfo : BubbleIntroBlockProps = {
    block_title: mapStringToDefinedMessage("Our team"),
    block_description: mapStringToDefinedMessage("Strong execution with rich industry experience"),
    items:[
        {
            img: anson,
            title: mapStringToDefinedMessage("Anson Suen"),
            description: mapStringToDefinedMessage("CEO & Co-Founder"),
            point_list:[
                {
                    point: mapStringToDefinedMessage("Scaled FundPark’s net revenue by 63X in the past 5 years"),
                }
            ]
        },
        {
            img: hay,
            title: mapStringToDefinedMessage("Hay Yip"),
            description: mapStringToDefinedMessage("Chief Operating Officer"),
            point_list:[
                {
                    point: mapStringToDefinedMessage("15 years in commercial banking in business management and relationship management across Asia and Europe"),
                }
            ]
        },
        {
            img: henry,
            title: mapStringToDefinedMessage("Henry Mok"),
            description: mapStringToDefinedMessage("Executive Director & Co-Founder"),
            point_list:[
                {
                    point: mapStringToDefinedMessage("Scaled total loan advanced by 800X in the past 5 years"),
                }
            ]
        },
        {
            img: bear,
            title: mapStringToDefinedMessage("Bear Huo"),
            description: mapStringToDefinedMessage("China office General manager"),
            point_list:[
                {
                    point: mapStringToDefinedMessage("13 years in general management and e-commerce"),
                }
            ]
        },
        {
            img: paul,
            title: mapStringToDefinedMessage("Paul Mok"),
            description: mapStringToDefinedMessage("Chief Innovation Officer"),
            point_list:[
                {
                    point: mapStringToDefinedMessage("20 years in product and technology"),
                }
            ]
        },
    ]
}