// @ts-nocheck
import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";

export function Tabs({ faqList, currentIdx, setCurrentIdx }): JSX.Element {
  return faqList.map(
    ({ tab }, idx: number): JSX.Element => {
      return (
        <div
          key={`tab_${idx}`}
          className={idx === currentIdx ? "tab currentTab" : "tab"}
          onClick={(): void => {
            setCurrentIdx(idx);
          }}
        >
          {tab}
        </div>
      );
    }
  );
}

export function TogglePanelList({ faqList, currentIdx }): JSX.Element {
  return faqList.map(({ faqs }, idx: number): JSX.Element | null => {
    return idx === currentIdx ? (
      <div key={`block_${idx}`} className="list-block">
        {faqs.map(
          (faq, idx): JSX.Element => {
            return <TogglePanel {...faq} key={`faq=${idx}`} />;
          }
        )}
      </div>
    ) : null;
  });
}

// eslint-disable-next-line react/prop-types
function TogglePanel({ question, answer }): JSX.Element {
  const [shouldExpendMore, setShouldExpendMore] = useState(true);

  return (
    <Accordion>
      <Card>
        <Accordion.Toggle
          as="div"
          eventKey="0"
          className="q-wrapper"
          onClick={(): void => {
            setShouldExpendMore(!shouldExpendMore);
          }}
        >
          <div className="q-mark">Q</div>
          <section>{question}</section>
          <div className={shouldExpendMore ? "expendMore" : "expendLess"} />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          {Array.isArray(answer)? (
            
            <>
              {
                answer.map(
                  (paragraph: JSX.Element, idx: number): JSX.Element => (
                    <section className="ans" key={`des_${idx}`}>
                      {paragraph.length === 1 ? //paragraph only 1/2
                      paragraph : 
                      paragraph.length > 3 
                      ? ( 
                            <>
                              {paragraph[0]}<b><a href={paragraph[1][0]} className="gsHyperLink" target="_blank" rel="noopener noreferrer">{paragraph[1][1]}</a></b>{paragraph[2]}
                              {paragraph[3]}<b><a href={paragraph[4][0]} className="gsHyperLink" target="_blank" rel="noopener noreferrer">{paragraph[4][1]}</a></b> {paragraph[5]} 
                            </>
                        ):(
                          <>
                            {paragraph[0]}{paragraph[1][2]}<b><a href={paragraph[1][0]} className="gsHyperLink" target="_blank" rel="noopener noreferrer">{paragraph[1][1]}</a></b>{paragraph[1][3]}{paragraph[2]}
                          </>
                        )                    
                      }
                    </section>
                  )
                )
              }
            </>
          ) : (
            <section className="ans">{answer}</section>
          )}
          {/* <section className="ans">{answer}</section> */}
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}
