import url from "url";
import { LANGUAGE, EN, AVAILABLE_LANGUAGE } from "constants/language";

export const getLanguage = (): string => {
  if (typeof window !== "undefined") {
    const storagedLanguage = localStorage.getItem(LANGUAGE);
    return storagedLanguage !== null ? storagedLanguage : EN;
  }
  return EN;
};

export const getLanguageFromPath = (path: string = ""): string => {
  if (!path && typeof window === "undefined") {
    return "";
  }
  const pathname = path || window.location.pathname;
  const result = pathname.split("/");
  return result.length >= 2 && AVAILABLE_LANGUAGE.includes(result[1])
    ? result[1]
    : "";
};

export const getPathnameWithoutLanguage = (
  pathname: URL["pathname"],
): URL["pathname"] => {
  const target = url.parse(pathname);
  if (!pathname || !target.pathname) {
    return "";
  }

  const folderArray: string[] = target.pathname.split("/").splice(2);

  let result = folderArray.join("/");
  if (result[0] !== "/") {
    return `/${result}`;
  }
  return result;
};
