// the key of path must be include 'PATH'
// in order to generate sitemap.xml in server/index.ts

export default {
  INDEX: "/",
  INDEX_PATH: "/:language",
  BUSINESS: "/business",
  BUSINESS_PATH: "/:language/business",
  BENEFITS: "/business#benefits",
  PROCEDURES: "/business#procedures",
  CALCULATOR: "/business#calculator",
  TESTIMONIALS: "/business#testimonials",
  ELIGIBILITY: "/business#eligibility",
  FUNDER: "/funder",
  FUNDER_PATH: "/:language/funder",
  REFERRER: "/referrer",
  REFERRER_PATH: "/:language/referrer",
  INVOICE_FINANCING: "/invoice-financing",
  INVOICE_FINANCING_PATH: "/:language/invoice-financing",
  PURCHASE_ORDER: "/purchase-order",
  PURCHASE_ORDER_PATH: "/:language/purchase-order",
  SUPPLY_CHAIN_FINANCE:"/supply-chain-finance",
  SUPPLY_CHAIN_FINANCE_PATH:"/:language/supply-chain-finance",
  TRADE_ASSET_BASED_FINANCE:"/trade-asset-based-finance",
  TRADE_ASSET_BASED_FINANCE_PATH:"/:language/trade-asset-based-finance",
  ECOMMERCE_FINANCE:"/eCommerce-finance",
  ECOMMERCE_FINANCE_PATH:"/:language/eCommerce-finance",
  ABOUT_US: "/about-us",
  ABOUT_US_PATH: "/:language/about-us",
  NEWS_AND_EVENTS: "/news-and-events",
  NEWS_AND_EVENTS_PATH: "/:language/news-and-events",
  NEWS_CONTENT_PATH: "/:language/news-and-events/:newsId/:title",
  FAQ: "/FAQ",
  FAQ_PATH: "/:language/FAQ",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  TERMS_AND_CONDITIONS_PATH: "/:language/terms-and-conditions",
  PRIVACY_POLICY: "/privacy-policy",
  PRIVACY_POLICY_PATH: "/:language/privacy-policy",
  PERSONAL_PRIVACY_POLICY: "/personal-privacy-policy",
  PERSONAL_PRIVACY_POLICY_PATH: "/:language/personal-privacy-policy",
  DATA_POLICY: "/data-policy",
  // DATA_POLICY_PATH: "/:language/data-policy",
  CONTACT_US: "/contact-us",
  CONTACT_US_PATH: "/:language/contact-us",
  CAREER: "/career",
  CAREER_PATH: "/:language/career",
  CAREER_DETAILS: "/career/details/",
  CAREER_DETAILS_PATH: "/:language/career/details/:jobId",
  CORE_VALUE: "/core-value",
  CORE_VALUE_PATH: "/:language/core-value",
  SIF: "https://u01-sif.fundpark.com/",
  INSIGHTS: "https://blog.fundpark.com/",
  REGISTER: `${process.env.REACT_APP_WEBSITE_HOST}/signup.html?language=:language`,
  REGISTER_PATH: `${process.env.REACT_APP_WEBSITE_HOST}/signup.html?language=:language`,
  LOGIN: `${process.env.REACT_APP_WEBSITE_HOST}/login.html?language=:language`,
  LOGIN_PATH: `${process.env.REACT_APP_WEBSITE_HOST}/login.html?language=:language`,
  FACEBOOK: "https://www.facebook.com/fundpark.co/",
  LINKEDIN: "https://www.linkedin.com/company/fundpark/",
  MEDIA_COVERAGE_1:
    "https://news.mingpao.com/pns/%E6%B8%AF%E8%81%9E/article/20190520/s00002/1558289721649/%E7%9C%8B%E7%99%BC%E7%A5%A8%E8%A8%82%E5%96%AE-%E5%B9%B3%E5%8F%B0%E6%8E%A8%E4%B8%AD%E5%B0%8F%E4%BC%81%E5%A4%A7%E9%A1%8D%E8%B2%B8%E6%AC%BE-%E8%9E%8D%E8%B3%87%E9%A1%8D%E5%8F%AF%E9%81%94%E5%8D%83%E8%90%AC-%E6%95%B8%E5%B7%A5%E4%BD%9C%E5%A4%A9%E5%8F%AF%E6%89%B9",
  MEDIA_COVERAGE_2: "http://startupbeat.hkej.com/?p=68384",
  MEDIA_COVERAGE_3: "https://meet.bnext.com.tw/articles/view/44762",
  MEDIA_COVERAGE_JUMPSTARTMAG: "https://jumpstartmag.com/fundpark-collaborates-with-cainiao-and-chinese-local-bank-in-promoting-digitalisation-of-supply-chain-financing-one-click-financing-for-cross-border-e-commerce-merchants-significant/",
  MEDIA_COVERAGE_ZGSWCN: "http://www.zgswcn.com/article/202006/202006181101461078.html",
  MEDIA_COVERAGE_PCHOME: "https://pchome.megatime.com.tw/news/cat6/20200622/9900100000001023054.html",
  SOFR: "/:language/SOFR",
  WALMART: "/:language/walmart"
};
