import React from "react";
import { Card } from "react-bootstrap";
import img1 from "images/contact-us/img1.png";
import img2 from "images/contact-us/img2.png";
import img3 from "images/contact-us/img3.png";
import PureImage from 'components/PureImage/pureImage';

function ContactUsCard(props) {
  const { title, address, phone, mailto } = props;
  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <div className="card-text-row d-flex">
            <PureImage src={img1} />
            <Card.Text className="card-address">{address}</Card.Text>
          </div>
          <div className="card-text-row d-flex">
            <PureImage src={img2} />
            <Card.Text>{phone}</Card.Text>
          </div>
          {mailto && (
            <div className="card-text-row d-flex">
              <PureImage src={img3} />
              <Card.Text>
                <a href={`mailto:${mailto}`}>{mailto}</a>
              </Card.Text>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default ContactUsCard;
