import React, { useState } from "react";

import { Form, Button } from "react-bootstrap";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";
import { contactUsFormInfo } from "constants/contactUs/info";
import { TranslatedContactUsFormProps } from "interface/connectForm.interface";
import { CONTACT_US_URL } from "api";
import ResponseMessage from "components/ContactUs/responseMessage";
import { useCurrentTextValue } from "./contactUs.custom.hook";

import FormGroup from "./FormGroup";

const contactUsFormInfoProps: TranslatedContactUsFormProps = mapToTranslatedProps(
  contactUsFormInfo,
);

function ContactUsForm() {
  const { title, inputFields, btnText } = contactUsFormInfoProps;

  const formTextValue = {
    name: useCurrentTextValue(""),
    phoneNumber: useCurrentTextValue(""),
    email: useCurrentTextValue(""),
    message: useCurrentTextValue(""),
  };

  const [validated, setValidated] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageType, setMessageType] = useState("");

  const handleSubmit = async (e): Promise<void> => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    setValidated(true);
    const data = {
      title: "Contact Us",
      content: {
        Name: formTextValue.name.textValue,
        // eslint-disable-next-line @typescript-eslint/camelcase
        Contact_number: formTextValue.phoneNumber.textValue,
        Email: formTextValue.email.textValue,
        Message: formTextValue.message.textValue,
      },
    };

    if (form.checkValidity()) {
      try {
        const response = await fetch(CONTACT_US_URL, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const { content } = await response.json();
        if (content === "success") {
          setMessageOpen(true);
          setMessageType(content);
          console.log("success:", content);
          Object.values(formTextValue).forEach((obj) => {
            obj.resetInput();
          });
          // init the value after submit
          setValidated(false);
        }
      } catch (error) {
        setMessageOpen(true);
        setMessageType("failed");
        console.error("Error:", error);
      }
    }
  };

  return (
    <div className="contact-us-form">
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="d-flex flex-column"
      >
        <h5>{title}</h5>
        {inputFields.map((inputField, idx) => {
          const { controlId } = inputField;
          return (
            <FormGroup
              key={`formGroup_${idx}`}
              {...inputField}
              getCurrentValue={formTextValue[controlId]}
            />
          );
        })}
        <Button type="submit" className="btn btn-info submit">
          {btnText}
        </Button>
      </Form>
      <ResponseMessage
        messageOpen={messageOpen}
        setMessageOpen={setMessageOpen}
        messageType={messageType}
      />
    </div>
  );
}

export default ContactUsForm;
