import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { JobCardProps } from '../../interface/jobCard.interface';

import '../../styles/job-card.scss';

function JobCard(props: JobCardProps): JSX.Element {
  return (
    <Card onClick={props.onClick} className="job-card" >
      <CardContent>
        <p className="job-card-title">
          {props.title}
        </p>
        {
          props.captions.map((caption, index) => (
            <p className="job-card-caption" key={index}>
              {caption}
            </p>
          ))
        }
      </CardContent>
    </Card>
  )
}

export default JobCard;
