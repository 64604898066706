// import React from "react";
import { CardListProps } from "interface/card.interface";
import { CardsWithTitleProps } from "interface/cardsWithTitle.interface";
import {
  mapStringToDefinedMessage,
  // mapStringToFormattedStringProps,
} from "lib/translate/mapStringToDefinedMessage";
// import img1 from "images/iconsForCard/img1.png";
import img1 from "images/supplyChainFinance/card-logo-1.svg";
import img2 from "images/supplyChainFinance/card-logo-2.svg";
import img3 from "images/supplyChainFinance/card-logo-3.svg";
import img4 from "images/supplyChainFinance/card-logo-4.svg";
// import img5 from "images/iconsForCard/img5.svg";


import ROUTES from "constants/ROUTES";

export const cardListInfo: CardListProps = {
  cards: [
    {
      image: img1,
      className: "element-card",
      description: mapStringToDefinedMessage(
        "Build and expand relationship with preferred suppliers and in turn to help them achieve economies of scale to lower the supply cost to Buyers",
      ),
      btn: {
        display: false,
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.INVOICE_FINANCING,
        externalLink: false,
      },
    },
    {
      image: img2,
      className: "element-card",
      description: mapStringToDefinedMessage(
        "Help suppliers to access working capital source in return suppliers can supply more and tie to Buyers’ ecosystem",
      ),
      btn: {
        display: false,
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.PURCHASE_ORDER,
        externalLink: false,
      },
    },
    {
      image: img3,
      className: "element-card",
      description: mapStringToDefinedMessage(
        "All financing costs are borne by suppliers while Buyers benefits from optimizing the cash flow for other yield enhancement activities",
      ),
      btn: {
        display: false,
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.PURCHASE_ORDER,
        externalLink: false,
      },
    },
    {
      image: img4,
      className: "element-card",
      description: mapStringToDefinedMessage(
        "Buyers can rely on FundPark to directly deal with suppliers in the whole process while further cement the relationships with suppliers",
      ),
      btn: {
        display: false,
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.PURCHASE_ORDER,
        externalLink: false,
      },
    },
  ],
};

export const benefitToBuyerInfo: CardsWithTitleProps = Object.assign(
  { ...cardListInfo },
  { title: mapStringToDefinedMessage("Benefit to Buyers") },
);
