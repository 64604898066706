import Grid from '@material-ui/core/Grid';
import React from 'react';
import JobCard from 'components/Card/jobCard';
import { TranslatedOpportunitiesBlockProps } from 'interface/opportunitiesBlock.interface';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ROUTES from 'constants/ROUTES';
import { getLanguage } from 'lib/language';
import suitcase from 'images/career/suitcase.png';

function OpportunitiesBlock(props: RouteComponentProps<any> & TranslatedOpportunitiesBlockProps) {

  return (
    <section className="career-opportunities" id="career-opportunities">
      <p className="career-opportunities-title">
        {props.title}
      </p>
      <div className="career-opportunities-feature-image">
        <img src={props.image} alt="teamwork" className="w-100" />
      </div>
      <div className="career-opportunities-feature-image-mobile">
        <img src={props.mobileImage} alt="teamwork" className="w-100" />
      </div>
      <p className="career-opportunities-description">
        {props.description.map(description => (<>{description}<br /></>))}
      </p>

      <Grid container spacing={2} className="career-opportunities-list">
        {
          props.jobs.length > 0 ?
            props.jobs.map(({ title, category, region, id }) => (
              <Grid item lg={4} xs={12} key={id}>
                <JobCard
                  title={title}
                  captions={[category, region]}
                  onClick={() => props.history.push(`/${getLanguage()}${ROUTES.CAREER_DETAILS}${id}`)}
                />
              </Grid>
            )
            ) : (
              <div className="w-100 d-flex flex-column align-items-center no-job-message">
                <img src={suitcase} alt="job" width={96} />
                <p>
                  {props.noJobMessage}
                </p>
              </div>
            )
        }
      </Grid>
    </section>
  )
}

export default withRouter(OpportunitiesBlock);
