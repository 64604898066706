import { Job, OpportunitiesBlockProps } from "interface/opportunitiesBlock.interface";

import jobs from 'mock/jobs.json';
import teamwork from 'images/career/teamwork.jpg';
import teamworkMobile from 'images/career/teamwork-mobile.png';
import mapStringToDefinedMessage, { mapStringToFormattedStringProps } from "lib/translate/mapStringToDefinedMessage";

const formattedJobs = jobs as Job[] ;

export const opportunitiesBlockProps: OpportunitiesBlockProps = {
  title: mapStringToDefinedMessage('Career Opportunities'),
  description: [mapStringToFormattedStringProps({
    id: 'Career Opportunity Description 1',
    defaultMessage: "At FundPark, you’ll have the opportunity to work with a great team, tackle challenges and make things happen! We look for people with a passion for excellence who believe in the power of the teamwork and integrity."
  }),
  mapStringToFormattedStringProps({
    id: 'Career Opportunity Description 2',
    defaultMessage: "Want to be a FundParker? Find your role.",
  })],
  image: teamwork,
  mobileImage: teamworkMobile,
  jobs: formattedJobs,
  noJobMessage: mapStringToFormattedStringProps({ id: 'No Job Opening', defaultMessage: "We don’t have any job opening now. If you are interested to be a part of us, please feel free to send your resume to recruitment@fundpark.com. We will consider your application in the future. (By sending your resume, you acknowledged and agreed the FundPark Data Privacy Policy. Data privacy policy link)" }),
}
