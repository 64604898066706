import { MediaEnquiryInfoProps } from "interface/mediaEnquiriesBlock.interface";
import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";

export const mediaEnquiriesBlockInfo: MediaEnquiryInfoProps = {
  title: mapStringToDefinedMessage("Media Enquiries"),
  subtitle: mapStringToDefinedMessage(
    "Contact our Corporate Communications & Marketing Team"
  ),
  content: mapStringToDefinedMessage(
    "For enquiries, please feel free to contact our Corporate Communications & Marketing Team at"
  ),
  mailto: "pr@fundpark.com"
};
