import React from "react";
import { TranslatedSeekingBlockProps } from "interface/seekingBlock.interface";
import { ExternalLinkProps } from "interface/linkProps.interface";
import { getLanguage } from "lib/language";
import { BUTTON_CLASSNAME } from "constants/language";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import "styles/learnMoreBlock.scss";
// import img from 'images/bg-images/contact-photo.jpg';

const LearnMoreBlock = (props: TranslatedSeekingBlockProps): JSX.Element => {
  const { description, btns } = props;
  const language = getLanguage();
  const matches = useMediaQuery('(min-width:576px)');
  return (
    <div className="learn-more-wrapper d-flex w-100">
      {matches && <div className="image-div"></div>}
      <section className="d-flex flex-column">
        {/* <div className="learn-more-title font-weight-bold">{title}</div> */}
        <div className="learn-more-description">{description}</div>
        <div className="learn-more-block-btn pt-2">
          {btns.map(
            (
              { href, text, btnType }: ExternalLinkProps,
              index: number
            ): JSX.Element => {
              return (
                <a
                  key={`btn_${index}`}
                  className={
                    btnType &&
                    `${BUTTON_CLASSNAME[btnType][language]} btn font-weight-bold py-2`
                  }
                  href={href}
                >
                  {text}
                </a>
              );
            }
          )}
        </div>
      </section>
    </div>
  );
};

export default LearnMoreBlock;
