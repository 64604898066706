import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import PlainIntroBlock from "components/PlainIntroBlock/plainIntroBlock";

import { opportunitiesBlockProps } from "constants/opportunitiesBlock/info";
import { PlainIntroProps, TranslatedPlainIntroProps } from "interface/plainIntro.interface";
import ROUTES from "constants/ROUTES";
import { useIntl } from "react-intl";
import BackButton from "components/BackButton/BackButton";

const jobs = opportunitiesBlockProps.jobs;


const CareerDetailPage = (props): JSX.Element => {
  const { jobId } = props.match.params;
  const intl = useIntl();
  const selectedJob = jobs.find((job) => job.id === +jobId);
  let plainIntroProps: TranslatedPlainIntroProps;

  if (!selectedJob) {
    plainIntroProps = {
      title: '-',
      paragraphIsHTML: true,
      isCareer: true,
      html: '-'
    }
  } else {
    plainIntroProps = mapToTranslatedProps({
      title: selectedJob.title,
      description: [selectedJob.description],
      id: selectedJob.id,
      paragraphIsHTML: true,
      isCareer: true,
      html: selectedJob.description,
      requirements: selectedJob.requirements,
      responsibilities: selectedJob.responsibilities
    } as PlainIntroProps)
  }

  const translatedHtml = intl.formatMessage({ id: `app.JobItem-${selectedJob?.id}.description` });

  return (
    <div id="news-content" style={{ marginTop: 80 }}>
      <BackButton
        onClick={() => props.history.replace(`/${props.match.params.language}${ROUTES.CAREER}#career-opportunities`)}
      />

      <PlainIntroBlock
        {...plainIntroProps}
        html={translatedHtml}
        afterContentChildren={selectedJob && (
          [
          <br/>,
          <div className="content">{intl.formatMessage({ id: 'app.Apply_method', defaultMessage: 'Personal data collected' })} <a href='/en/personal-privacy-policy' target='_blank' className='default-color' ><u>FundPark Data Privacy Policy</u></a>.</div>,
          <br/>,
          <div className="content personal-data">{intl.formatMessage({ id: 'app.Personal_data_collected', defaultMessage: 'Personal data collected' })}</div>,
          <a
            className="mb-5 mt-3 btn btn-info"
            target="_blank"
            rel="noopener noreferrer"
            href={selectedJob.link}
          >
            {intl.formatMessage({ id: 'Send Email Now', defaultMessage: 'Send Email Now' })}
          </a>,
          ]
        )}
      />
    </div>
  );
};

export default CareerDetailPage;
