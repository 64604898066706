import { NavItemListProps } from "interface/navbar.interface";
import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";
import ROUTES from "constants/ROUTES";

// NOTE: "dataLayer" is for Google Analytics Event Tracking
export const navItemsList: NavItemListProps = {
  navItemList: [
    {
      link: {
        text: mapStringToDefinedMessage("Solutions"),
        href: ROUTES.BUSINESS,
        dataLayer: { label: "Solutions" },
      },
    },
    // {
    //   link: {
    //     text: mapStringToDefinedMessage("Funder"),
    //     href: ROUTES.FUNDER,
    //     dataLayer: { label: "Funders" },
    //   },
    // },

    // {
    //   link: {
    //     text: mapStringToDefinedMessage("Products"),
    //     href: "#",
    //     dataLayer: { label: "Products" },
    //   },
    //   dropdownItems: [
    //     {
    //       text: mapStringToDefinedMessage("eCommerce Finance"),
    //       href: ROUTES.ECOMMERCE_FINANCE,
    //       externalLink: false,
    //       dataLayer: { label: "eCommerce Finance" },
    //     },
    //     {
    //       text: mapStringToDefinedMessage("Trade Asset Based Finance"),
    //       href: ROUTES.TRADE_ASSET_BASED_FINANCE,
    //       externalLink: false,
    //       dataLayer: { label: "Trade Asset Based Finance" },
    //     },
    //     {
    //       text: mapStringToDefinedMessage("Receivable Finance"),
    //       href: ROUTES.INVOICE_FINANCING,
    //       externalLink: false,
    //       dataLayer: { label: "Receivable Finance" },
    //     },
    //     {
    //       text: mapStringToDefinedMessage("Purchase Order Finance"),
    //       href: ROUTES.PURCHASE_ORDER,
    //       externalLink: false,
    //       dataLayer: { label: "Purchase Order Finance" },
    //     },
    //     // {
    //     //   text: mapStringToDefinedMessage("Supply Chain Finance"),
    //     //   href: ROUTES.SUPPLY_CHAIN_FINANCE,
    //     //   externalLink: false,
    //     //   dataLayer: { label: "Supply Chain Finance" },
    //     // },
        
    //   ],
    // },
    // {
    //   link: {
    //     text: mapStringToDefinedMessage("Referrer"),
    //     href: ROUTES.REFERRER,
    //     dataLayer: { label: "Partners" },
    //   },
    // },
    {
      link: {
        text: mapStringToDefinedMessage("More"),
        href: '#',
        dataLayer: { label: "More" },
      },
      dropdownItems: [
        {
          text: mapStringToDefinedMessage("About FundPark"),
          href: ROUTES.ABOUT_US,
          externalLink: false,
          dataLayer: { label: "About FundPark" },
        },
        {
          text: mapStringToDefinedMessage("Our Funders"),
          href: ROUTES.FUNDER,
          externalLink: false,
          dataLayer: { label: "Our Funders" },
        },
        // {
        //   text: mapStringToDefinedMessage("Insights"),
        //   href: ROUTES.INSIGHTS,
        //   externalLink: true,
        //   dataLayer: { label: "Insights" },
        // },
        {
          text: mapStringToDefinedMessage("FAQ"),
          href: ROUTES.FAQ,
          externalLink: false,
          dataLayer: { label: "FAQ" },
        },
        {
          text: mapStringToDefinedMessage("Press Room"),
          href: ROUTES.NEWS_AND_EVENTS,
          externalLink: false,
          dataLayer: { label: "Press Room" },
        },
        {
          text: mapStringToDefinedMessage("Referrer"),
          href: ROUTES.REFERRER,
          externalLink: false,
          dataLayer: { label: "Referrer" },
        },
        {
          text: mapStringToDefinedMessage("Contact Us"),
          href: `${ROUTES.CONTACT_US}?ref=navbar`,
          externalLink: false,
          btnType: "IM",
          dataLayer: { label: "Contact" },
        }
      ]
    },
    {
      link: {
        text: mapStringToDefinedMessage("Careers"),
        href: ROUTES.CAREER,
        dataLayer: { label: "Careers" },
      },
    },
  ],
  navBtnList: [
    // {
    //   btn: {
    //     text: mapStringToDefinedMessage("Register"),
    //     href: ROUTES.REGISTER,
    //     externalLink: true,
    //     btnType: "signup",
    //     dataLayer: { label: "Sign Up" },
    //   },
    //   className: "register",
    // },
    {
      btn: {
        text: mapStringToDefinedMessage("Login"),
        href: ROUTES.LOGIN,
        externalLink: true,
        dataLayer: { label: "Login" },
      },
      className: "login",
    },
  ],
};
