import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import HeaderBanner from "components/Banner/headerBanner";
// import SimpleBanner from "components/Banner/simpleBanner";
import Procedure from "components/Procedure/procedure";
import Solutions from "components/Solutions/solutions";
import TalkToUs from "components/TalkToUs/talkToUs";
import LookingForFunding from "components/LookingForFunding/lookingForFunding"

import {
  TranslatedBannerProps,
  // TranslatedSimpleBannerProps,
} from "interface/banner.interface";
import { homeBannerInfo
  // , simpleBannerInfo 
} from "constants/banner/info";

// import { TranslatedCardListProps } from "interface/card.interface";
// import { cardListInfo } from "constants/card/info";
// import Cards from "components/Card/cards";

// import BenefitsWithDetailsBlock from "components/BenefitsWithDetailsBlock/BenefitsWithDetailsBlock";
// import { TranslatedIntroInfoListProps } from "interface/introInfo.interface";
// import { benefitsIntroInfo } from "constants/BenefitsWithDetails/info";

// import SeekingBlocks from "components/SeekingBlock/seekingBlocks";
// import { seekingBlocksList } from "constants/seekingBlock/info";
// import { TranslatedSeekingBlockListProps } from "interface/seekingBlock.interface";

import PureImage from "components/PureImage/pureImage";
import MediaCoverage from "components/MediaCoverage";


// import Products from "components/Products";
import YtbVideo from "components/YtbVideo/ytbVideo";
// import PointFormWithTitle from "components/PointFormWithTitle/pointFormWithTitle";
// import {pointFormInfo} from "constants/pointForm/pointforminfo";
// import {TranslatedPointFormInfoProps} from "interface/pointFormWithTitle.interface";
// import Cards from "components/Card/cards";

const Home = (): JSX.Element => {

  const bannerInfoProps: TranslatedBannerProps = mapToTranslatedProps(
    homeBannerInfo,
  );

  // const cardListInfoProps: TranslatedCardListProps = mapToTranslatedProps(
  //   cardListInfo,
  // );

  // const benefitsIntroInfoProps: TranslatedIntroInfoListProps = mapToTranslatedProps(
  //   benefitsIntroInfo,
  // );

  // const seekingBlockListProps: TranslatedSeekingBlockListProps = mapToTranslatedProps(
  //   seekingBlocksList,
  // );

  // const simpleBannerInfoProps: TranslatedSimpleBannerProps = mapToTranslatedProps(
  //   simpleBannerInfo,
  // );
  // const matches = useMediaQuery('(min-width:768px)');

  // const pointFormInfoProps : TranslatedPointFormInfoProps = mapToTranslatedProps(
  //   pointFormInfo,
  // );

  return (
    <div className="home">
      <HeaderBanner {...bannerInfoProps} isHomePage/>
      <Procedure />
      <Solutions />
      <YtbVideo titleName="Watch_our_customer_stories"/>
      {/* <PointFormWithTitle {...pointFormInfoProps}/> */}
      {/* <Products /> */}
      {/* <CardsWithTitle {...businessBannerInfoProps} isSlide={matches} />
      <Cards {...cardListInfoProps}/> */}
      {/* <BenefitsWithDetailsBlock {...benefitsIntroInfoProps} /> */}
      {/* <SeekingBlocks {...seekingBlockListProps} /> */}
      <LookingForFunding />
      <PureImage isPure />
      <MediaCoverage />
      <TalkToUs />
      {/* <SimpleBanner {...simpleBannerInfoProps} /> */}
    </div>
  );
};

export default Home;
