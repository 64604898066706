import {
  mapStringToDefinedMessage,
  mapStringToFormattedStringProps,
} from "lib/translate/mapStringToDefinedMessage";
import { BannerInfo, SimpleBannerInfo } from "interface/banner.interface";
import ROUTES from "constants/ROUTES";

export const homeBannerInfo: BannerInfo = {
  // header: mapStringToFormattedStringProps({
  //   defaultMessage: "Accelerating growth",
  //   tagName: "b"
  // }),
  title: [
    // mapStringToFormattedStringProps({
    //   defaultMessage: "ASIA'S LEADING FINTECH FINANCING PLATFORM TO E-COMMERCE SMES",
    //   tagName: "h2"
    // }),
    mapStringToFormattedStringProps({
      defaultMessage:'We power fast-growth digital enterprises and the entrepreneurs behind them',
      tagName: "span"
    })
  ],
  // description: [
  //   mapStringToFormattedStringProps({
  //     defaultMessage: "Get over USD 10m in 24 hours. Only pay as you get the funds",
  //     tagName: "p"
  //   }),
  //   // mapStringToFormattedStringProps({
  //   //   defaultMessage: "Your companion to expand your business globally",
  //   //   tagName: "p"
  //   // }),
  // ],
  btn: {
    text: mapStringToDefinedMessage("Register Now"),
    href: ROUTES.REGISTER,
    externalLink: true,
    btnType: "signup",
  },
  classNameText: "banner-bg-home",
};

export const funderBannerInfo: BannerInfo = {
  title: [
    mapStringToDefinedMessage("Innovative Credit"),
    mapStringToDefinedMessage("Investment Opportunities"),
  ],
  description: mapStringToDefinedMessage(
    "Alternative Asset Class with Flexibility",
  ),
  // btn: {
  //   text: mapStringToDefinedMessage("Register Now"),
  //   href: ROUTES.REGISTER,
  //   externalLink: true,
  //   btnType: "signup",
  // },
  classNameText: "banner-bg-funder",
};

export const referrerBannerInfo: BannerInfo = {
  title: [
    mapStringToDefinedMessage("Partnering with FundPark"),
    mapStringToDefinedMessage("Referral Programme"),
  ],
  description: mapStringToDefinedMessage(
    "Reward Your Commitment - Upon successful referral of client to FundPark, we will pay our partners base on the recurring drawdown amount from the lead you referred to us. The more referrals you make, the more rewards you can earn!",
  ),
  classNameText: "banner-bg-referrer",
};

export const invFinancingBannerInfo: BannerInfo = {
  title: [mapStringToDefinedMessage("Financing Can Be Innovative")],
  description: mapStringToDefinedMessage(
    "Provide receivable finance for corporate that allows you to finance your account receivables from slow-paying customers.",
  ),
  // btn: {
  //   text: mapStringToDefinedMessage("Register Now"),
  //   href: ROUTES.REGISTER,
  //   externalLink: true,
  //   btnType: "signup",
  // },
  classNameText: "banner-bg-invFinancing",
};

export const purchaseOrderBannerInfo: BannerInfo = {
  title: [
    mapStringToDefinedMessage("Financing can be Easy"),
    mapStringToDefinedMessage("In a New Way"),
  ],
  description: mapStringToDefinedMessage(
    "FundPark provides purchase order financing for corporate clients to ease pressure on securing funding for production.",
  ),
  // btn: {
  //   text: mapStringToDefinedMessage("Register Now"),
  //   href: ROUTES.REGISTER,
  //   externalLink: true,
  //   btnType: "signup",
  // },
  classNameText: "banner-bg-purchaseOrder",
};

export const aboutUsBannerInfo: BannerInfo = {
  title: [mapStringToDefinedMessage("About FundPark")],
  classNameText: "banner-bg-aboutUs",
};

export const termsAndConditionsBannerInfo: BannerInfo = {
  title: [mapStringToDefinedMessage("Terms of use")],
  classNameText: "banner-bg-aboutUs banner-bg-terms-and-conditions",
};

export const privacyPolicyBannerInfo: BannerInfo = {
  title: [mapStringToDefinedMessage("Privacy Policy")],
  classNameText: "banner-bg-aboutUs banner-bg-privacy-policy",
};

export const personalPrivacyPolicyBannerInfo: BannerInfo = {
  title: [mapStringToDefinedMessage("Personal Data (Privacy) Ordinance")],
  classNameText: "banner-bg-aboutUs banner-bg-privacy-policy",
};

export const dataPolicyBannerInfo: BannerInfo = {
  title: [mapStringToDefinedMessage("Data Policy")],
  classNameText: "banner-bg-aboutUs banner-bg-data-policy",
};

export const newsAndEventsBannerInfo: BannerInfo = {
  // Financing Trade Across Asia
  title: [mapStringToDefinedMessage("A digital financing platform for e-Commerce SMEs in Asia")],
  description: mapStringToDefinedMessage(
    //"An online trade finance platform that connects corporates with institutional funders in Asia.",
    "A working capital finance technology platform that connects corporates with institutional funders in Asia.",
  ),
  classNameText: "banner-bg-news",
};

export const coreValueBannerInfo: BannerInfo = {
  title: [mapStringToDefinedMessage("RITE")],
  description: mapStringToDefinedMessage("Respect • Integrity • Team Work • Excellence • Embrace Changes"),
  classNameText: "banner-bg-core-value",
};

export const simpleBannerInfo: SimpleBannerInfo = {
  btn: {
    text: mapStringToDefinedMessage("Register Now"),
    href: ROUTES.REGISTER,
    externalLink: true,
    btnType: "signup",
  },
  title: mapStringToDefinedMessage(
    "Get Funded in just a Few Steps without Pledging Your Assets or Collaterals",
  ),
  classNameText: "simple-banner-bg-01",
};

export const supplyChainFinaceBannerInfo: BannerInfo = {
  title: [mapStringToDefinedMessage("A WIN-WIN-WIN situation")],
  description: mapStringToDefinedMessage(
    "A win-win-win situation for buyers, suppliers and funders to minimize the risk across the supply chain.",
  ),
  classNameText: "banner-bg-supply-chain-finance",
}

export const tradeAssetBasedFinanceBannerInfo: BannerInfo = {
  title: [mapStringToDefinedMessage("Import: Trade Asset Based Finance")],
  description: mapStringToDefinedMessage(
    "Provide a fast-accessible short-term loan to the e-commerce merchants.",
  ),
  classNameText: "banner-bg-trade-asset-based-finance",
}

export const eCommerceFinance: BannerInfo = {
  title: [mapStringToDefinedMessage("Export: Trade Asset Based Finance")],
  description: mapStringToDefinedMessage(
    "Powering the growth of your export eCommerce business"
  ),
  classNameText: "banner-bg-e-commerce-finance",
}

