/* eslint-disable react/prop-types */
import React from "react";
import FAQCard from "./FAQCard";
import { TogglePanelList } from "./FAQList";
import { faqTitleInfo } from "constants/faq/info";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";
import "styles/faq.scss";
import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";
import ROUTES from "constants/ROUTES";
import { getLanguage } from "lib/language";

export default function FAQ(): JSX.Element {
  const language = getLanguage();
  const lang = language === 'en' ? 'en' : 'zh-hk'
  const faqListInfo = [
    {
      tab: mapStringToDefinedMessage("General"),
      faqs: [
        {
          question: mapStringToDefinedMessage(
            "What solutions does FundPark provide?"
          ),
          answer: [
            [
              mapStringToDefinedMessage(
                "We provide funding solutions for e-Commerce entrepreneurs, including sales optimisation, inventory purchase, and payment to suppliers."
              )
            ],
            [
              mapStringToDefinedMessage(
                "Please refer to"
              ),
              [
                `/${lang}${ROUTES.BUSINESS}?ref=faq`,
                mapStringToDefinedMessage(
                  'our solutions'
                ),
                " ",
                " "
              ],
              mapStringToDefinedMessage(
                "page for further information."
              ),
            ],
          ]
        },
        {
          question: mapStringToDefinedMessage(
            "How do I apply for financing from FundPark?"
          ),
          answer: [
            [
              mapStringToDefinedMessage(
                `click`
              ),
              [
                ROUTES.REGISTER,
                mapStringToDefinedMessage(
                  'Register Now'
                ),
                "",
                ""
              ],
              mapStringToDefinedMessage(
                `to create your account. Or you could simply2`
              ),              
            ],
          ]
        },
        {
          question: mapStringToDefinedMessage("How much funding can I obtain?"),
          answer: [
            [
              mapStringToDefinedMessage(
                "This will vary depending on your specific needs. Our funding facility can range from USD10,000 to USD 20 million."
              )
            ],
            [
              mapStringToDefinedMessage(
                "If you would like to understand more and/or request a larger amount, please contact our customer service team."
              )
            ]
            ],
        },
        {
          question: mapStringToDefinedMessage("Who is eligible to apply for FundPark's financing solutions?"),
          answer: mapStringToDefinedMessage(
            "Entrepreneurs or companies selling on e-Commerce platform(s) or those conducting cross-border businesses."
          )
        },
        {
          question: mapStringToDefinedMessage(
            "How much is the interest rate?"
          ),
          answer: [
            [
              mapStringToDefinedMessage(
                "It varies depending on the business information of each customer, such as revenue, payment and inventory data. Please contact us to get more information."
              ),
            ]
          ]
        },
        {
          question: mapStringToDefinedMessage("Is land property required as collateral?"),
          answer: mapStringToDefinedMessage(
            "Our credit approval is based on forward-looking business data. No property is required as collateral."
          )
        }
      ]
    },
  ];
  
  const faqList = mapToTranslatedProps(faqListInfo);
  const faqTitle = mapToTranslatedProps(faqTitleInfo);
  const { title } = faqTitle;
  // const [currentIdx, setCurrentIdx] = useState(0);

  return (
    <div className="faq-wrapper">
      <h3>{title}</h3>
      <div className="content-wrapper">
        <div className="card-wrapper">
          <FAQCard />
        </div>
        <div className="list-wrapper">
          {/* <div className="tabs d-flex">
            <Tabs
              faqList={faqList}
              currentIdx={currentIdx}
              setCurrentIdx={setCurrentIdx}
            />
          </div> */}
          <TogglePanelList faqList={faqList} currentIdx={0} />
        </div>
      </div>
    </div>
  );
}
