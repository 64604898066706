import React from "react";
import ConnectForm from "./connectForm";
import { TranslatedConnectFormProps } from "interface/connectForm.interface";
import "styles/connect-form.scss";

const ConnectFormBlock = (props: TranslatedConnectFormProps): JSX.Element => {
  const { title, subtitle } = props;
  return (
    <div className="connect-form-wrapper d-flex justify-content-center">
      <div className="connect-form d-flex flex-column align-items-center">
        <div className="connect-form-title font-weight-bold">{title}</div>
        <div className="connect-form-subtitle text-center">{subtitle}</div>
        <ConnectForm {...props} />
      </div>
    </div>
  );
};

export default ConnectFormBlock;
