// @ts-nocheck

import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import get from 'lodash/get';
import { useIntl } from "react-intl";

import { TranslatedCardProps } from "interface/card.interface";
import { TranslatedSubDescriptionItemProps } from "interface/introInfo.interface";
import { getLanguage } from "lib/language";
import { BUTTON_CLASSNAME } from "constants/language";

const CardTemplate = (props: TranslatedCardProps): JSX.Element => {
  const { image, title, description, btn, className, subdescription, descriptionIsHTML = false } = props;
  let { alt } = props;
  const { text, href, externalLink, btnType, display = true } = btn;
  const language = getLanguage();

  const intl = useIntl();

  alt =
    alt ||
    intl.formatMessage({
      id: "app.Trade_Financing",
      defaultMessage: "Trade Financing",
    });

    let descriptionHTML = '';
    if (descriptionIsHTML) {
      if (get(description, 'props.id')) {
        descriptionHTML = intl.formatMessage({
          id: get(description, 'props.id'),
        });
      } else {
        descriptionHTML = String(description);
      }
    }
    if(Array.isArray(subdescription) && subdescription.length > 0){
        const subitem = subdescription.map(
          (subdescriptionitem:TranslatedSubDescriptionItemProps, index:number):JSX.Element=>{
            return <li key={`car_sub_${index}`}>{subdescriptionitem.item}</li>
        });
    

        return (
        <Card className={className}>
          {image && <Card.Img variant="top" src={image} alt={alt} />}
          <Card.Body>
            <section>
              <Card.Title style={{
                  marginTop: image ? '' : 20,
              }}>{title}</Card.Title>
              {descriptionIsHTML ?
                <Card.Text dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
                : <Card.Text>{description}</Card.Text>
              }

              <ul style={{ textAlign: "left", fontSize: "14px", paddingLeft:"18px"}}>
                    {subitem}
              </ul>
            </section>

            {display &&( externalLink ?
              <a className={
                btnType
                  ? `${BUTTON_CLASSNAME[btnType][language]} mb-5 mt-3 btn btn-dark`
                  : "mb-5 mt-3 btn btn-dark"
              } href={btnType ? `${href}${language.slice(0, 2)}/SIF` : href}>
                {text}
              </a> : <Link className="mb-5 mt-3 btn btn-dark" to={`/${language}${href}`}>
                {text}
              </Link> 
            )}
          </Card.Body>
        </Card>
      );
    }else{
          return (
            <Card className={className}>
              {image && <Card.Img variant="top" src={image} alt={alt} />}
              <Card.Body>
                <section>
                  <Card.Title style={{
                      marginTop: image ? '' : 20,
                  }}>{title}</Card.Title>
                  {descriptionIsHTML ?
                    <Card.Text dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
                    : <Card.Text>{description}</Card.Text>
                  }
                </section>
        
                {display &&( externalLink ?
                  <a className={
                    btnType
                      ? `${BUTTON_CLASSNAME[btnType][language]} mb-5 mt-3 btn btn-dark`
                      : "mb-5 mt-3 btn btn-dark"
                  } href={btnType ? `${href}${language.slice(0, 2)}/SIF` : href}>
                    {text}
                  </a> : <Link className="mb-5 mt-3 btn btn-dark" to={`/${language}${href}`}>
                    {text}
                  </Link> 
                )}
              </Card.Body>
            </Card>
          );
      }
};

export default CardTemplate;
