// @ts-nocheck

import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { AVAILABLE_LANGUAGE, LANGUAGE_MAPPING, EN} from "constants/language";
import { HTMLHeaderProps } from "interface/HTMLHeader.interface";
import metadataArray from "constants/strapi/metadata.json";

const HTMLHeader = (props: HTMLHeaderProps) => {
  const { title, href, pathname, language } = props;
  const Metadata = useMemo(() => {
    if (Array.isArray(metadataArray) && metadataArray.length > 0) {
      const target = metadataArray.find((item) => item.path === pathname);
      let metadataObject = {
        title: "",
        description: "",
        keywords: "",
      };
      if (target) {
        if (language === EN) {
          console.log(target,'targetAR')

          metadataObject = {
            title: target["English"].title || "",
            description: target["English"].description || "",
            keywords: target["English"].keywords || "",
          };
        } else {
          if (Array.isArray(target.AllLanguages) && target.AllLanguages.length > 0) {
            for(let i=0; i<target.AllLanguages.length; i++){
              if(target.AllLanguages[i].language === LANGUAGE_MAPPING[language]){
                metadataObject = {
                title: target.AllLanguages[i].title || "",
                description: target.AllLanguages[i].description || "",
                keywords: target.AllLanguages[i].keywords || "",
                };
              } else {
                continue
              }
            }
          } else {
            metadataObject = {
              title: target.title || "",
              description: target.description || "",
              keywords: target.keywords || "",
            };
          }
        }
        return [
          !metadataObject.title && <title>{title}</title>,
          metadataObject.title && <title>{metadataObject.title}</title>,
          metadataObject.description && (
            <meta
              name="description"
              content={String(metadataObject.description)}
            />
          ),
          metadataObject.keywords && (
            <meta name="keywords" content={String(metadataObject.keywords)} />
          ),
        ];
      }
    }

    return [<title>{title}</title>];
  }, [language, pathname, title]);

  return (
    <Helmet>
      {/* <title>{title}</title> */}
      <link rel="canonical" href={href} />
      {AVAILABLE_LANGUAGE.map((language) => (
        <link
          rel="alternate"
          hrefLang={language}
          href={`${process.env.REACT_APP_WEBSITE_HOST}/${language}${pathname}`}
        />
      ))}
      {Metadata}
    </Helmet>
  );
};

export default HTMLHeader;
