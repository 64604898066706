import { ConnectFormProps } from "interface/connectForm.interface";
import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";

export const connectFormInfo: ConnectFormProps = {
  title: mapStringToDefinedMessage("Connecting Partners to Business"),
  subtitle: mapStringToDefinedMessage(
    "Be our referrer for growth and join our FundPark Partner Referral Programme!"
  ),
  nameLabel: mapStringToDefinedMessage("Name"),
  nameWarning: mapStringToDefinedMessage("Please enter your name"),
  countryCodeLabel: mapStringToDefinedMessage("Country Code"),
  countryCodeWarning: mapStringToDefinedMessage(
    "Please enter your country code"
  ),
  contactNumberLabel: mapStringToDefinedMessage("Contact Number"),
  contactNumberWarning: mapStringToDefinedMessage(
    "Please enter your phone number"
  ),
  emailLabel: mapStringToDefinedMessage("Email Address"),
  emailWarning: mapStringToDefinedMessage("Please enter a correct Email"),

  companyNameLabel: mapStringToDefinedMessage("Company Name (Optional)"),
  btnText: mapStringToDefinedMessage("Refer to Us")
};
