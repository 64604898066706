// @ts-nocheck
import React from "react";
import { TranslatedIntroInfoProps } from "interface/introInfo.interface";
import PureImage from 'components/PureImage/pureImage';

const InfoBlock = ({
  image,
  title,
  description,
  hyperlink
}: TranslatedIntroInfoProps): JSX.Element => {
  // console.log(hyperlink,'hyperlink')
  return (
    <div className="info-block d-flex my-4">
      <div className="info-block-img">
        <PureImage src={image} />
      </div>
      <section>
        <div className="info-block-title font-weight-bold mb-2"> {title} </div>
        
        {Array.isArray(description)?
          ( 
            <>
              {description.map(
                (paragraph: JSX.Element, idx: number): JSX.Element => (
                  <div className="info-block-description" key={`des_${idx}`}>{paragraph}</div>
                )
              )}
            </>
          ):(
            <div className="info-block-description"> 
              {description}
            </div>
          )} 
        
        <div className="info-block-description"> 
          {hyperlink?<b><a href="https://www.bloomberg.com/news/videos/2022-04-20/fundpark-gets-250m-abs-facility-with-goldman-video" className="gsHyperLink" target="_blank" rel="noopener noreferrer">{hyperlink}</a></b>:""}
        </div>
      </section>
    </div>
  );
};

export default InfoBlock;
