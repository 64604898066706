import { CultureBlocksProps } from "interface/cultureBlocks.interface";
import respect from 'images/core-value/respect.jpg';
import integrity from 'images/core-value/integrity.jpg';
import teamwork from 'images/core-value/teamwork.jpg';
import excellence from 'images/core-value/excellence.jpg';
import embraceChanges from 'images/core-value/embrace-changes.jpg';
import mapStringToDefinedMessage, { mapStringToFormattedStringProps } from "lib/translate/mapStringToDefinedMessage";

export const cultureBlocksInfo: CultureBlocksProps = {
  cultures: [
    {
      title: mapStringToDefinedMessage('Respect'),
      image: respect,
      paragraphs: [
        {
          text: mapStringToFormattedStringProps({
            defaultMessage: 'Just as there are two sides to a coin there are two sides to every story. Every idea can be seen from more than one viewpoint. Be open to other perspectives and ideas. ',
            id: 'Respect Paragraph 1'
          }),
        },
        {
          className: 'heading',
          text: mapStringToFormattedStringProps({
            defaultMessage: 'Put yourself in others’ shoes',
            id: 'Respect Paragraph 2',
          }),
        },
        {
          text: mapStringToFormattedStringProps({
            defaultMessage: 'Look at a situation from a different point of view before being quick to judge someone for their actions. Treat others how you want to be treated.',
            id: 'Respect Paragraph 3',
          }),
        },
        {
          className: 'heading',
          text: mapStringToFormattedStringProps({
            defaultMessage: 'Be open to other perspectives and ideas',
            id: 'Respect Paragraph 4',
          }),
        },
        {
          text: mapStringToFormattedStringProps({
            defaultMessage: 'Listen and learn from those who are different from you. To learn more about who they are, you can connect and build trust with each other easier.',
            id: 'Respect Paragraph 5',
          }),
        }
      ]
    },
    {
      title: mapStringToDefinedMessage('Integrity'),
      image: integrity,
      className: 'dark-color-swatch',
      paragraphs: [
        {
          text: mapStringToFormattedStringProps({
            id: 'Integrity Paragraph 1',
            defaultMessage: 'Do the right thing. Even though nobody’s watching, or whether you could get credit or not.',
          })
        },
        {
          className: 'heading',
          text: mapStringToFormattedStringProps({
            id: 'Integrity Paragraph 2',
            defaultMessage: 'Do the right thing',
          })
        },
        {
          text: mapStringToFormattedStringProps({
            id: 'Integrity Paragraph 3',
            defaultMessage: 'Do the right thing from the beginning, and make sure what you do is correct.',
          })
        },
        {
          className: 'heading',
          text: mapStringToFormattedStringProps({
            id: 'Integrity Paragraph 4',
            defaultMessage: 'Keep your words',
          })
        },
        {
          text: mapStringToFormattedStringProps({
            id: 'Integrity Paragraph 5',
            defaultMessage: 'Stay committed to what you have promised.',
          })
        },
        {
          className: 'heading',
          text: mapStringToFormattedStringProps({
            id: 'Integrity Paragraph 6',
            defaultMessage: 'Being upright is good',
          })
        },
        {
          text: mapStringToFormattedStringProps({
            id: 'Integrity Paragraph 7',
            defaultMessage: 'Accept feedback without going defensive and encourage people to be upright and honest.',
          })
        },
        {
          className: 'heading',
          text: mapStringToFormattedStringProps({
            id: 'Integrity Paragraph 8',
            defaultMessage: 'Raise your concerns or allegations of non-compliance immediately',
          })
        },
        {
          text: mapStringToFormattedStringProps({
            id: 'Integrity Paragraph 9',
            defaultMessage: 'Staying silent is not an option.',
          })
        },
      ]
    },
    {
      title: mapStringToDefinedMessage('Team Work'),
      image: teamwork,
      paragraphs: [
        {
          text: mapStringToFormattedStringProps({
            id: 'Team Work Paragraph 1',
            defaultMessage: 'We are not a team because we work together. We are a team because we respect, trust and care for each other.',
          })
        },
        {
          className: 'heading',
          text: mapStringToFormattedStringProps({
            id: 'Team Work Paragraph 2',
            defaultMessage: 'Help others to achieve good result',
          })
        },
        {
          text: mapStringToFormattedStringProps({
            id: 'Team Work Paragraph 3',
            defaultMessage: 'The way to achieve your own success is to be willing to help others to get to success together.',
          })
        },
        {
          className: 'heading',
          text: mapStringToFormattedStringProps({
            id: 'Team Work Paragraph 4',
            defaultMessage: 'Share responsibility together',
          })
        },
        {
          text: mapStringToFormattedStringProps({
            id: 'Team Work Paragraph 5',
            defaultMessage: 'Be proud of what your team has achieved and take full responsibility of your teamwork together.',
          })
        },
        {
          className: 'heading',
          text: mapStringToFormattedStringProps({
            id: 'Team Work Paragraph 6',
            defaultMessage: 'Effective and transparent communication',
          })
        },
        {
          text: mapStringToFormattedStringProps({
            id: 'Team Work Paragraph 7',
            defaultMessage: 'Things that are hidden or simply unspoken waste time and energy. We communicate clearly and react quickly, say what we mean and mean what we say.',
          })
        },
      ]
    },
    {
      className: 'light-color-swatch',
      title: mapStringToDefinedMessage('Excellence'),
      image: excellence,
      paragraphs: [
        {
          text: mapStringToFormattedStringProps({
            id: 'Excellence Paragraph 1',
            defaultMessage: 'Always strive to do better.',
          })
        },
        {
          className: 'heading',
          text: mapStringToFormattedStringProps({
            id: 'Excellence Paragraph 2',
            defaultMessage: 'Excel yourself',
          })
        },
        {
          text: mapStringToFormattedStringProps({
            id: 'Excellence Paragraph 3',
            defaultMessage: 'Be the expert at your role.',
          })
        },
        {
          className: 'heading',
          text: mapStringToFormattedStringProps({
            id: 'Excellence Paragraph 4',
            defaultMessage: 'Hungry for success',
          })
        },
        {
          text: mapStringToFormattedStringProps({
            id: 'Excellence Paragraph 5',
            defaultMessage: 'Eager for advancement. Be passionate in what you do and stay committed to your goals.',
          })
        },
        {
          className: 'heading',
          text: mapStringToFormattedStringProps({
            id: 'Excellence Paragraph 6',
            defaultMessage: 'Challenge yourself to do better',
          })
        },
        {
          text: mapStringToFormattedStringProps({
            id: 'Excellence Paragraph 7',
            defaultMessage: 'If you always heard yourself or the others saying, “I always do things this way”, challenge yourself and the others to do it even better.',
          })
        },
      ]
    },
    {
      title: mapStringToDefinedMessage('Embrace changes'),
      image: embraceChanges,
      paragraphs: [
        {
          text: mapStringToFormattedStringProps({
            id: 'Embrace Changes Paragraph 1',
            defaultMessage: 'Keep an open mind and always improve. If you stand still you will go backward.',
          })
        },
        {
          className: 'heading',
          text: mapStringToFormattedStringProps({
            id: 'Embrace Changes Paragraph 2',
            defaultMessage: "Adopt a 'can' positive attitude to change",
          })
        },
        {
          text: mapStringToFormattedStringProps({
            id: 'Embrace Changes Paragraph 3',
            defaultMessage: 'Maintain a ‘can do’ attitude and come up with new ways of seeing and doing things to the unfamiliar and the unexpected situations.',
          })
        },
        {
          className: 'heading',
          text: mapStringToFormattedStringProps({
            id: 'Embrace Changes Paragraph 4',
            defaultMessage: 'Think out of the box',
          })
        },
        {
          text: mapStringToFormattedStringProps({
            id: 'Embrace Changes Paragraph 5',
            defaultMessage: 'Try to find the best solution in every specific situation. Unconventional thinking can contribute to ground-breaking solutions.',
          })
        },
      ]
    },
  ],
};