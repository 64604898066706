import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";
import {
  PlainIntroProps,
  PlainIntroWithSubtitleProps,
  NestedIntroProps,
} from "interface/plainIntro.interface";

export const supplyChainFinanceIntro : PlainIntroProps = {
  tag: "h1",
  title: mapStringToDefinedMessage("Supply Chain Finance"),
  description: [
    mapStringToDefinedMessage(
      "FundPark offers a financing solution for both buyers and supplier to optimize working capital. FundPark’s Supply Chain Finance helps buyers or core enterprise to improve their cashflow and liquidity by leveraging payment terms while providing an option for suppliers to receive payment early. Under Supply Chain Finance, suppliers sell their invoices at a discount rate to FundPark. This is a win-win-win situation for buyers, suppliers and funders to minimize the risk across the supply chain.",
    )
  ],
};

export const eCommerceFinanceIntro : PlainIntroProps = {
  tag: "h1",
  title: mapStringToDefinedMessage("Export: Trade Asset Based Finance"),
  description: [
    mapStringToDefinedMessage(
      "Need funds to grow your eCommerce business?"
    ),
    mapStringToDefinedMessage(
      "FundPark offers a working capital financing to support your Growth."
    ),
    mapStringToDefinedMessage(
      "Sharing the business performance of your company, the funding (up to USD 10M) is just one click away."
    ),
    mapStringToDefinedMessage(
      "Pay only when you use it. No collaterals required."
    ),
  ],
};

export const tradeAssetBasedFinance: PlainIntroWithSubtitleProps = {
  paragraph: [
    {
      tag: "h1",
      title: mapStringToDefinedMessage("Import: Trade Asset Based Finance"),
      description: [
        mapStringToDefinedMessage(
          "Strategic partnership with e-commerce platform to provide a fast-accessible short-term loan to the e-commerce merchants, based on the trade assets collateral.",
        )
      ]
    },
    {
      tag: "h2",
      title: mapStringToDefinedMessage("Current Product Overview"),
      description: [
        mapStringToDefinedMessage("FundPark formed a tripartite cross-border collaboration with Cainiao Smart Logistics Network Limited (“Cainiao”) and a Chinese bank, providing a fast accessible short-term loan to the cross-border e-commerce merchants on Tmall Global, based on the inventory and receivables collateral."),
        mapStringToDefinedMessage("Through API integration with CaiNiao and the bank, FundPark can simplify the evaluation process of the loan applications. The funds can be dispatched within one business day."),
        mapStringToDefinedMessage("Based on our proprietary innovative risk model, FundPark is able to conduct dynamic loan approval and monitoring with real-time data of e-commerce merchant’s performance."),
      ]
    }
  ]
}

export const invFinancingPlainIntroInfo: PlainIntroProps = {
  tag: "h1",
  title: mapStringToDefinedMessage("Invoice Financing Page Title"),
  description: [
    mapStringToDefinedMessage(
      "Corporate clients can discount its account receivables (invoices confirmed by buyer) to FundPark to get immediate payment to keep cash flow running.",
    ),
    mapStringToDefinedMessage(
      "We have flexible tenor up to 6 months, providing finance solutions without collateral requirements and finance up to 80% of the invoice value. Through our own proprietary risk management tool, your invoices will get the most reasonable interest quotation within 1 day approval time.",
    ),
    mapStringToDefinedMessage(
      "Partnering with the world’s largest trade credit agencies and trade credit insurers, corporate clients are able to understand buyer’s creditability thus utilize different buyer’s invoices to achieve the best trade financing solutions.",
    )
  ],
};

export const purchaseOrderPlainIntroInfo: PlainIntroProps = {
  title: mapStringToDefinedMessage("Purchase Order Finance"),
  description: [
    mapStringToDefinedMessage(
      "Corporate clients can use its purchase orders or contracts to FundPark as a discount to get immediate payment to keep cash flow running.",
    ),
    mapStringToDefinedMessage(
      "We provide finance solutions without collateral requirements and finance up to 70% of the purchase order or contract value. Through our own proprietary risk management tool, your purchase orders or contracts will get the most reasonable interest quotation within 1 day approval time.",
    ),
    mapStringToDefinedMessage(
      "Partnering with the world’s largest trade credit agencies and trade credit insurers, corporate clients are able to understand buyer’s creditability thus utilize different buyer’s purchase orders to achieve the best trade financing solutions.",
    ),
  ],
};

export const aboutUsPlainIntroInfo: PlainIntroProps = {
  description: [
    mapStringToDefinedMessage(
      "FundPark Limited is a fintech scale-up with a vision to power fast-growth digital entrepreneurs to realise their growth potential and drive prosperity.",
    ),
    mapStringToDefinedMessage(
      "Founded in 2016, FundPark has developed a digital platform to support fast growth digital enterprises, by delivering insights and capital that bridge their needs to growth. This is enabled by its proprietary credit model and technology allowing to harness real-time data from its ecosystem of partners.",
    ),
    mapStringToDefinedMessage(
      "In 2022, FundPark secured US $250 million in one of the largest asset-backed securitisation facility deals in Asia, to bolster its ability to fund SMEs in the fast-growing eCommerce sector.",
    )
  ],
};

export const termsAndConditionsInfo: PlainIntroWithSubtitleProps = {
  paragraph: [
    {
      title: mapStringToDefinedMessage("1 Terms of use"),
      description: [
        mapStringToDefinedMessage(
          "Please read the terms of use (the “Terms of Use”) set forth below, as they apply to your access and use of our services, including our various websites, APIs, email notifications, applications, buttons and widgets (“Services”), and any information, text, graphics, photos or other materials uploaded, downloaded or appearing on the Services (collectively referred to as the “Content”). Your access to and use of the Services signifies your acceptance of these Terms of Use and agreement  to  be  bound  by  them  and  any  and  all  other  applicable  terms  referenced  herein absolutely.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("2 Other applicable terms"),
      description: [
        mapStringToDefinedMessage(
          "Our Privacy Policy sets out the terms on which we process any personal data we collect from you, or that you provide to us. By using our Services, you consent to such processing and you warrant that all data provided by you is accurate. You understand that through your use of the Services  you  consent  to  the  collection  and  use  (as  set  forth  in  the  Privacy  Policy)  of  this information, including the transfer of this information both within and outside of Hong Kong for storage, processing and use by us. As part of providing you the Services, we may need to provide you with certain communications, such as service announcements and administrative messages. These communications are considered part of the Services and your User Account, which you may not be able to opt-out from receiving.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("3 Information about us"),
      description: [
        mapStringToDefinedMessage(
          "The  Services  are  provided  and  operated  by  FundPark  Limited.  We  are  a  limited  liability company incorporated and registered in Hong Kong and have our registered office at 16/F, 9 Chong Yip Street, Kwun Tong, Kowloon, Hong Kong.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("4 Changes of terms"),
      description: [
        mapStringToDefinedMessage(
          "We may amend the Terms of Use from time to time at our sole discretion without notice or liability to you. By continuing to use the Services following such amendments to the Terms of Use, you agree to be bound by such amendments.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("5 Changes to our services"),
      description: [
        mapStringToDefinedMessage(
          "5.1 The Services that we provide are always evolving and the form and nature of the Services that we provide may change from time to time without prior notice to you. In addition, we may stop (permanently or temporarily) providing the Services (or any features within the Services) to you or to users generally and not be able to provide you with prior notice. We also retain the right to create limits on use and storage at our sole discretion at any time and without prior notice to you.",
        ),
        mapStringToDefinedMessage(
          "5.2 We make no representations, warranties or guarantees, whether expressed or implied, that our Services or any content on our site is accurate, complete or up to date.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("6 Accessing our services"),
      description: [
        mapStringToDefinedMessage(
          "6.1 Our website is made available free of charge.",
        ),
        mapStringToDefinedMessage(
          "6.2  We  do  not  guarantee  that  our  Services,  or  any  content,  will  always  be  available  or  be uninterrupted. Access to our Services is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of our Services without notice. We will not be liable to you if for any reason our Services are unavailable at any time or for any period.",
        ),
        mapStringToDefinedMessage(
          "6.3 You are responsible for making all arrangements necessary for you to have access to our Services.",
        ),
        mapStringToDefinedMessage(
          "6.4 You are also responsible for ensuring that all persons who access our Services through your internet  connection  are  aware  of  these  Terms  of  Use  and  other  applicable  terms  and conditions, and that they comply with them.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("7 Your User Account and password"),
      description: [
        mapStringToDefinedMessage(
          "7.1 You are responsible for safeguarding the password that you use to access the Services and for  any  activities  or  actions  under  your  password.  We  encourage  you  to  use  “strong“ passwords (passwords that use a combination of upper and lower case letters, numbers and symbols) with your User Account. You must treat such information as confidential. You must not disclose it to any third party. FundPark Limited cannot and will not be liable for any loss or damage arising from your failure to comply with the above.",
        ),
        mapStringToDefinedMessage(
          "7.2 We have the right to disable any password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these Terms of Use.",
        ),
        mapStringToDefinedMessage(
          "7.3  If  you  know  or  suspect  that  anyone  other  than  you  knows  your  password,  you  must promptly notify us at admin@fundpark.com.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("8 Your licence to use the services"),
      description: [
        mapStringToDefinedMessage(
          "FundPark  Limited  gives  you  a  personal,  worldwide,  royalty-free,  non-assignable  and  non-exclusive licence to use the software that is provided to you by us as part of the Services. This licence is for the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by FundPark Limited, in the manner permitted by these Terms of Use.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("9 Our intellectual property rights"),
      description: [
        mapStringToDefinedMessage(
          "9.1 All intellectual property rights subsisting in respect of the website and the Services belong to  FundPark  Limited  or  have  been  lawfully  licensed  to  FundPark  Limited  for  use  in connection with the website and the Services. All rights under applicable laws are hereby reserved. You are not allowed to upload, post, publish, reproduce, transmit or distribute in any  way  any  component  of  the  website  itself  or  create  derivative  works  with  respect thereto, as the website is copyrighted under applicable laws.",
        ),
        mapStringToDefinedMessage(
          "9.2 You agree that we are free to use, disclose, adopt and modify all and any ideas, concepts, knowhow, proposals, suggestions, comments and other communications and information provided by you to us (“Feedback”) in connection with the Services and/or your use of the Services without any payment to you. You hereby waive and agree to waive all and any rights and claims for any consideration, fees, royalties, charges and/or other payments in relation to our use, disclosure, adoption and/or modification of any or all of your Feedback.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("10 Your rights"),
      description: [
        mapStringToDefinedMessage(
          "10.1  You  retain  your  rights  to  any  Content  you  submit,  post  or  display  on  or  through  the Services. By submitting, posting or displaying Content on or through the Services, you grant us a worldwide, non-exclusive, royalty-free licence (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute such  Content  in  any  and  all  media  or  distribution  methods  (now  known  or  later developed).",
        ),
        mapStringToDefinedMessage(
          "10.2 You agree that this licence includes the right for us to provide, promote, and improve the Services  and  to  make  Content  submitted  to  or  through  the  Services  available  to  other companies,  organisations  or  individuals  who  partner  with  FundPark  Limited  for  the syndication, broadcast, distribution or publication of such Content on other media and services, subject to our terms and conditions for such Content use.",
        ),
        mapStringToDefinedMessage(
          "10.3  Such  additional  uses  by  FundPark  Limited  or  other  companies,  organisations  or individuals who partner with FundPark Limited may be made with no compensation paid to  you  with  respect  to  the  Content  that  you  submit,  post,  transmit  or  otherwise  make available through the Services.",
        ),
        mapStringToDefinedMessage(
          "10.4 We may modify or adapt your Content in order to transmit, display or distribute it over computer  networks  and  in  various  media  and/or  make  changes  to  your  Content  as  are necessary to conform and adapt that Content to any requirements or limitations of any networks, devices, services or media.",
        ),
        mapStringToDefinedMessage(
          "10.5 You are responsible for your use of the Services, for any Content you provide, and for any consequences  thereof,  including  the  use  of  your  Content  by  other  users  and  our  third-party  partners.  You  understand  that  your  Content  may  be  syndicated,  broadcast, distributed,  or  published  by  our  partners  and  if  you  do  not  have  the  right  to  submit Content  for  such  use,  it  may  subject  you  to  liability.  FundPark  Limited  will  not  be responsible  or  liable  for  any  use  of  your  Content  by  FundPark  Limited  in  accordance with  these  Terms.  You  represent  and  warrant  that  you  have  all  the  rights,  power  and authority necessary to grant the rights granted herein to any Content that you submit.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("11 Limited liability and warranty"),
      description: [
        mapStringToDefinedMessage(
          "Please read this section carefully since it limits the liability of FundPark Limited and its parents, subsidiaries,    affiliates,    related    companies,    officers,    directors,    employees,    agents, representatives,  partners,  and  licensors  (collectively,  the  “Entities  of  FundPark  Limited”). Each  of  the  subsections  below  only  applies  up  to  the  maximum  extent  permitted  under applicable  law.  Some  jurisdictions  do  not  allow  the  disclaimer  of  implied  warranties  or  the limitation of liability in contracts, and as a result the contents of this section may not apply to you. Nothing in this section is intended to limit any rights you may have which may not be lawfully  limited.  All  Information  is  for  your  general  reference  only.  We  do  not  accept  any responsibility whatsoever in respect of such information.",
        ),
        mapStringToDefinedMessage(
          "11.1 Your access to and use of our services is at your sole risk and is provided “as is”, “as available”. The services are for your personal use only and the entities of the FundPark Limited make no representation or warranty of any kind, expressed or implied, including and without limitation, any warranties on merchantability or fitness for any particular purpose or non-infringement.",
        ),
        mapStringToDefinedMessage(
          "11.2 The entire liability of the entities of FundPark Limited and your exclusive remedy with respect to the services or otherwise, is re-performance of defective services. In jurisdictions which do not allow the exclusion or limitation  of certain types of liability, our liability will be limited to the maximum extent permitted by law. We do not endorse, warrant or guarantee any material, product or service offered through us or our services. We are not and will not be a party to any transaction between you and any third party.",
        ),
        mapStringToDefinedMessage(
          "11.3 In no event shall the aggregate liability of the entities of FundPark Limited exceed one thousand Hong Kong dollars (HKD 1,000).",
        ),
        mapStringToDefinedMessage(
          "11.4 The Entities of FundPark Limited shall not guarantee or assume any responsibility that:",
        ),
        mapStringToDefinedMessage(
          "a. the  information  presented  in  our  Services  is  accurate,  adequate,  current  or reliable, or may be used for any purpose other than for general reference;",
        ),
        mapStringToDefinedMessage(
          "b. the information presented in our Services is free of defect, error, omission, virus or anything which may change, erase, add to or damage your software, data or equipment;",
        ),
        mapStringToDefinedMessage(
          "c. messages sent through the internet including in connection with the services will be free from interception, corruption, error, delay or loss;",
        ),
        mapStringToDefinedMessage(
          "d. access to the Services will be available or be uninterrupted;",
        ),
        mapStringToDefinedMessage(
          "e. use of the Services will achieve any particular result; or",
        ),
        mapStringToDefinedMessage(
          "f. defects in the Services will be corrected.",
        ),
        mapStringToDefinedMessage(
          "11.5 Without limiting the generality of the foregoing, in no event will the Entities of FundPark Limited be liable to you or any other person for any direct, indirect, incidental, special, punitive or consequential loss or damages, including any loss of business or profit, arising out  of  any  use,  or  inability  to  use,  the  information  or  the  services,  even  if  any  of  the Entities of FundPark Limited has been advised of the possibility of such loss or damages.",
        ),
        mapStringToDefinedMessage(
          "11.6 You  will  exercise  and  rely  solely  on  your  own  skill  and  judgment  in  your  use  and interpretation of the information and use of the services. You are responsible to ensure that  your  use  of  the  information  and  the  Services  complies  with  all  applicable  legal requirements.",
        ),
        mapStringToDefinedMessage(
          "11.7 Without  prejudice  to  the  foregoing,  if  your  use  of  the  Services  does  not  proceed satisfactorily and/or where applicable you do not receive appropriate responses to such use from us, as set out in these Terms of Use or otherwise, you are advised to contact us at  admin@fundpark.com.  No  such  lack  of  response  shall  be  deemed  to  constitute  any acquiescence or waiver.",
        ),
        mapStringToDefinedMessage(
          "11.8 The limitation of liability contained in these Terms of Use will apply to the fullest extent permitted by applicable laws.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("12 Content on the services"),
      description: [
        mapStringToDefinedMessage(
          "12.1 All Content, whether publicly posted or privately transmitted, is the sole responsibility of the person who originated such Content.",
        ),
        mapStringToDefinedMessage(
          "12.2 You warrant that any such contribution does comply with those standards, and you will be  liable  to  us  and  indemnify  us  for  any  breach  of  that  warranty  and  you  will  be responsible for any loss or damage we suffer as a result of your breach of warranty.",
        ),
        mapStringToDefinedMessage(
          "12.3 Any  content  you  upload  to  our  site  will  be  considered  non-confidential  and  non-proprietary,  and  we  have  the  right  to  use,  copy,  distribute  and  disclose  to  third  parties any such content for any purpose. We also have the right to disclose your identity to any third  party  who  is  claiming  that  any  content  posted  or  uploaded  by  you  to  our  site constitutes a violation of their intellectual property rights, or of their right to privacy. We will not be responsible, or liable to any third party, for the content or accuracy of any content posted by you or any other user of our site.",
        ),
        mapStringToDefinedMessage(
          "12.4 The views expressed by other users on our site do not represent our views or values. We do maintain the right to remove any posting you make on our site if, in our opinion, your post does not comply with our content standards. We do not endorse, support, represent or  guarantee  the  completeness,  truthfulness,  accuracy,  or  reliability  of  any  Content  or communications  posted  via  the  Services  or  endorse  any  opinions  expressed  via  the Services. You understand that by using the Services, you may be exposed to Content that might  be  offensive,  harmful,  inaccurate  or  otherwise  inappropriate,  or  in  some  cases, postings that have been mislabelled or are otherwise deceptive.",
        ),
        mapStringToDefinedMessage(
          "12.5 Under no circumstances will we be liable in any way for any Content, including, but not limited to, any errors or omissions in any Content, or any loss or damage of any kind, incurred  directly  or  indirectly  as  a  result  of  the  use  of  the  Services  by  any  third  party, including  without  limitation  any  defamatory,  offensive,  or  illegal  conduct  of  the  third party, or the use of any Content posted, emailed, transmitted, or otherwise made available via the Services or broadcast elsewhere.",
        ),
        mapStringToDefinedMessage(
          "12.6 We may not monitor or control the Content posted via the Services and, we cannot take responsibility for such Content. Any use or reliance on any Content or materials posted via the Services or obtained by you through the Services is at your own risk.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("13 Content copyright policy"),
      description: [
        mapStringToDefinedMessage(
          "13.1 FundPark Limited respects the intellectual property rights of others and expects users of the Services to do the same. We will respond to notices of alleged copyright infringement that comply with applicable law and are properly provided to us. If you believe that your Content has been copied in a way that constitutes copyright infringement, please provide us with the following information: (i) a physical or electronic signature of the copyright owner or a person authorised to act on their behalf; (ii) identification of the copyrighted work claimed to have been infringed; (iii) identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the  material;  (iv)  your  contact  information,  including  your  address,  telephone  number, and an email address; (v) a statement by you that you have a good faith belief that use of the material in the manner complained of is not authorised by the copyright owner, its agent, or the law; and (vi) a statement that the information in the notification is accurate, and that you are authorised to act on behalf of the copyright owner.",
        ),
        mapStringToDefinedMessage(
          "13.2 We reserve the right to remove Content alleged to be infringing without prior notice, at our  sole  discretion,  and  without  liability  to  you.  In  appropriate  circumstances,  we  will also  terminate  a  User  Account  if  the  user  is  determined  to  be  a  repeat  infringer.  Our address  for  notice  of  alleged  copyright  infringement  appearing  on  the  Services  is admin@fundpark.com.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("14 Use of the services"),
      description: [
        mapStringToDefinedMessage(
          "14.1 We reserve the right at all times (but will not have an obligation) to remove or refuse to distribute  any  Content  on  the  Services,  to  suspend  or  terminate  users,  and  to  reclaim usernames without liability to you. We also reserve the right to access, read, preserve, and  disclose  any  information  as  we  reasonably  believe  is  necessary  to  (i)  satisfy  any applicable law, regulation, legal process or governmental request, (ii) enforce the Terms of  Use,  including  investigation  of  potential  violations  hereof,  (iii)  detect,  prevent,  or otherwise  address  fraud,  security  or  technical  issues,  (iv)  respond  to  user  support requests, or (v) protect the rights, property or safety of FundPark Limited its users and the public.",
        ),
        mapStringToDefinedMessage(
          "14.2 We do not guarantee that our Services will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmes and platform in order to access our site. You should use your own virus protection software.",
        ),
        mapStringToDefinedMessage(
          "14.3 You may not do any of the following while accessing or using the Services: (i) access, tamper  with,  or  use  non-public  areas  of  the  Services,  FundPark  Limited's  computer systems, or the technical delivery systems of FundPark Limited's providers; (ii) probe, scan,  or  test  the  vulnerability  of  any  system  or  network  or  breach  or  circumvent  any security or authentication measures; (iii) access or search or attempt to access or search the  Services  by  any  means  (automated  or  otherwise)  other  than  through  our  currently available, published interfaces that are provided by us (and only pursuant to those terms and conditions), (scraping the Services without our prior consent is expressly prohibited); (iv) forge any TCP/IP packet header or any part of the header information in any email or  posting,  or  in  any  way  use  the  Services  to  send  altered,  deceptive  or  false  source-identifying information; or (v) interfere with, or disrupt, (or attempt to do so), the access of any user, host or network, including, without limitation, sending a virus, trojan, worm, logic bomb or other material which is malicious or technologically harmful, overloading, flooding, spamming, mail-bombing the Services, or by scripting the creation of Content in such a manner as to interfere with or create an undue burden on the Services.",
        ),
        mapStringToDefinedMessage(
          "14.4 We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our Services will cease immediately.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("15 Linking to us"),
      description: [
        mapStringToDefinedMessage(
          "You may link to our Services provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to  suggest  any  form  of  association,  approval  or  endorsement  on  our  part  where  none  exists. You  must  not  establish  a  link  to  our  Services  in  any  website  that  is  not  owned  by  you.  We reserve the right to withdraw linking permission without notice.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage(
        "16 Third-party links and resources in our site",
      ),
      description: [
        mapStringToDefinedMessage(
          "16.1 The links from the Services may take you to other sites or services and you acknowledge and agree that FundPark Limited has no responsibility for the accuracy or availability of any Information provided by third parties services and websites.",
        ),
        mapStringToDefinedMessage(
          "16.2 The  Services  may  include  advertisements,  which  may  be  targeted  to  the  Content  or information  on  the  Services,  queries  made  through  the  Services,  or  other  information. The types and extent of advertising by FundPark Limited on the Services are subject to change. In consideration for us granting you access to and use of the Services, you agree that  FundPark  Limited  and  its  third-party  providers  and  partners  may  place  such advertising on the Services or in connection with the display of Content or information from the Services whether submitted by you or others.",
        ),
        mapStringToDefinedMessage(
          "16.3 Links  to  other  websites  and  services  do  not  constitute  an  endorsement  by  us  of  such websites or services, or the Information, products, advertising or other materials available made available by such third parties.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("17 Inherent risks"),
      description: [
        mapStringToDefinedMessage(
          "17.1 You agree to accept the risks inherent in relation of using our Services including but not limited to the followings:",
        ),
        mapStringToDefinedMessage(
          "a. the  lack  of  authority  of  accessing  our  Services  or  systems  provided  by  our Services;",
        ),
        mapStringToDefinedMessage(
          "b. the  presence  of  computer  viruses  or  such  other  malicious  programs  that  may adversely affect your computer system;",
        ),
        mapStringToDefinedMessage(
          "c. the hacking of your computer system; and",
        ),
        mapStringToDefinedMessage(
          "d. the  hacking  and  impersonation  of  your  User  Account  in  relation  to  our Services;",
        ),
        mapStringToDefinedMessage(
          "e. the consequences of using our Services being misrouted, delayed or otherwise not delivered.",
        ),
        mapStringToDefinedMessage(`(collectively as the "Risks").`),
        mapStringToDefinedMessage(
          "17.2 You agree that FundPark Limited will not be liable to you for any losses, liabilities, claims or  expenses  which  you  may  suffer  if  FundPark  Limited  have  acted  on  any  instruction arising from one or more of the Risks or any other Risks which you accept in connection with the usage of our Services provided only that FundPark Limited have acted in good faith believing it to be genuine or authorised, as the case may be.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("18 Indemnity"),
      description: [
        mapStringToDefinedMessage(
          "18.1 You  agree  to  defend,  indemnify  and  hold  us  harmless  from  and  against  all  liabilities, damages, claims, actions, costs and expenses (including but not limited to legal fees), in connection with or arising from your breach of any of these Terms of Use and/or your use of the website. We may, if necessary, participate in the defence of any claim or action and any negotiations for settlement. No settlement which may adversely affect our rights or obligations shall be made without our prior written approval. We reserve the right, at our own expense and on notice to you, to assume exclusive defence and control of any claim or action.",
        ),
        mapStringToDefinedMessage(
          "18.2 FundPark Limited’s rights and remedies under these Terms of Use shall be in addition to and shall not in any way prejudice or affect the rights and/or remedies in any other terms and conditions, agreement or document or to which FundPark Limited may be otherwise entitled.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("19 Severance"),
      description: [
        mapStringToDefinedMessage(
          "The illegality, invalidity or unenforceability of any provision of these Terms of Use under the law of any jurisdiction shall not affect its legality, validity or enforceability under the laws of any other jurisdiction nor the legality, validity or enforceability of any other provision.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("20 Several users"),
      description: [
        mapStringToDefinedMessage(
          "If there are two or more persons adhering to these Terms of Use as user, their liability under the Terms of Use is joint and several, and their rights are joint.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("21 Waiver"),
      description: [
        mapStringToDefinedMessage(
          "No failure or delay by a party to exercise any right or remedy provided under these Terms of Use or by law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy shall prevent or restrict the further exercise of that or any other right or remedy.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("22 Termination"),
      description: [
        mapStringToDefinedMessage(
          "22.1 The Terms of Use will continue to apply until terminated by either you or us as follows.",
        ),
        mapStringToDefinedMessage(
          "22.2 You may end your agreement with us at any time for any reason by deactivating your User Account  and  discontinuing  your  use  of  the  Services.  You  do  not  need  to  specifically inform us when you stop using the Services.",
        ),
        mapStringToDefinedMessage(
          "22.3 We may suspend or terminate your User Accounts or cease providing you with all or part of the Services at any time for any reason, including, but not limited to, if we reasonably believe: (i) you have violated these Terms of Use or (ii) you create risk or possible legal exposure for us; or (iii) our provision of the Services to you is no longer commercially viable.  We  will  make  reasonable  efforts  to  notify  you  by  the  email  address  associated with your User Account or the next time you attempt to access your User Account.",
        ),
        mapStringToDefinedMessage(
          "22.4 In all such cases, any provision of these Terms of Use that expressly or by implication is intended to come into or continue in force on or after termination of this agreement shall remain in full force and effect.",
        ),
        mapStringToDefinedMessage(
          "22.5 Nothing in this section shall affect our rights to change, limit or stop the provision of the Services without prior notice, as provided above in Clause 5 Changes to our services.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("23 Governing law and jurisdiction"),
      description: [
        mapStringToDefinedMessage(
          "These Terms of Use shall be governed by the laws of the Hong Kong Special Administrative Region. You agree to submit to the non-exclusive jurisdiction of the Hong Kong courts.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("24 Languages"),
      description: [
        mapStringToDefinedMessage(
          "In case of discrepancies between the English and Chinese language versions of these Terms of Use and contents of this site, the English version shall prevail.",
        ),
      ],
    },
  ],
};

export const privacyPolicyInfo: PlainIntroWithSubtitleProps = {
  paragraph: [
    {
      title: mapStringToDefinedMessage(
        "Privacy Policy relating to Personal Data (Privacy) Ordinance",
      ),
      description: [
        mapStringToDefinedMessage(
          "In terms of collecting, storing, processing and/or transferring of Personal Data, FundPark recognises its responsibilities under the Personal Data (Privacy) Ordinance (the “Ordinance”) and FundPark will take all practicable steps to ensure the security and confidentiality of the Personal Data and to avoid unauthorised access or other use.",
        ),
      ],
    },
    {
      description: [
        mapStringToDefinedMessage(
          "This  Policy  shall  apply  to  each  and  every  subsidiaries,  affiliates  and  related  companies  of  FundPark (collectively, “Group of Companies”). Any reference to “Personal Data” shall adopt the definition as provided under the Ordinance.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("Collection of Personal Data"),
      description: [
        mapStringToDefinedMessage(
          "1. There are two kinds of information which may involve Personal Data:",
        ),
        mapStringToDefinedMessage(
          "a. Customer  Records,  which  are  necessary  for  customers  to  supply  to  FundPark  from time to time in connection with matters such as:",
        ),
        mapStringToDefinedMessage(
          "(i) the opening or operation of user accounts;",
        ),
        mapStringToDefinedMessage(
          "(ii) credit underwriting and approval and renewal of credit facilities;",
        ),
        mapStringToDefinedMessage(
          "(iii) Know-your-client (KYC) related identification and verification process;",
        ),
        mapStringToDefinedMessage(
          "(iv) review and update of customer profiles, audit and monitoring;",
        ),
        mapStringToDefinedMessage(
          "(v) preparation and execution of legal and finance documents;",
        ),
        mapStringToDefinedMessage(
          "(vi) credit control and risk management of the credit facilities;",
        ),
        mapStringToDefinedMessage(
          "(vii) launch of product and provision of services;",
        ),
        mapStringToDefinedMessage(
          "(viii) processing and answering customer enquiries and/or complaints;",
        ),
        mapStringToDefinedMessage(
          "(ix) establishment, continuance and termination of the business relationship.",
        ),
        mapStringToDefinedMessage(
          `The  subject  of  the  Personal  Data  in  relation  to  the  Customer  Records  may  include individuals holding the capacity of shareholders, directors, controlling person, officers, senior executives, guarantors and third party obligors in connection with the facilities offered to the customers ("Data Subjects”).`,
        ),
        mapStringToDefinedMessage(
          `b. Personnel  Records,  which  include  but  not  limited  to  C.V.s;  application  forms; references;  appraisal  and  disciplinary  records;  salary,  pension  and  benefits  details; results  of  medical,  security,  background  and  financial  checks;  sickness  records; personal  contact  details  and;  bank  account  and  tax  details  of  the  individual,  the subject  of  which  may  include  employees,  potential  employees,  job  applicants, contractors, secondees and referees.`,
        ),
        mapStringToDefinedMessage(
          "2.  If  the  Personal  Data  requested  by  FundPark  is  not  provided,  FundPark  may  not  be  able  to establish  or  continue  to  provide  products  or  services  to  the  customers  or  carry  out  human resources-related  function;  and/or  FundPark  may  not  be  able  to  comply  with  all  applicable laws and regulations.",
        ),
        mapStringToDefinedMessage(
          "3.  In most cases FundPark will collect Personal Data directly from the Data Subjects by making request (in the form of either written request or oral request). In some cases FundPark may collect Personal Data from different channels including without limitation to the following:",
        ),
        mapStringToDefinedMessage(
          "a. FundPark corporate website at www.fundpark.com (“Our Website”);",
        ),
        mapStringToDefinedMessage("b. any record of correspondence;"),
        mapStringToDefinedMessage("c. any surveys;"),
        mapStringToDefinedMessage(
          "d. any details of transactions through Our Website and of the fulfilment of orders; and",
        ),
        mapStringToDefinedMessage(
          "e. any details of the visits to Our Website, including cookies.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("Use of Personal Data"),
      description: [
        mapStringToDefinedMessage(
          "4 The purposes for which Personal Data relating to the Data Subjects may be used are as follows:",
        ),
        mapStringToDefinedMessage(
          "a. processing,  considering  and  assessing  the  Data  Subjects’  application  for  FundPark’s products and services;",
        ),
        mapStringToDefinedMessage(
          "b. the daily operation of the products, services and credit facilities provided to the Data Subjects;",
        ),
        mapStringToDefinedMessage(
          "c. conducting credit checks at the time of application for credit and at the time of regular or special reviews which normally takes place one or more times each year; ",
        ),
        mapStringToDefinedMessage(
          "d. creating and maintaining FundPark’s credit scoring models; ",
        ),
        mapStringToDefinedMessage(
          "e. ensuring ongoing credit worthiness of Data Subjects;",
        ),
        mapStringToDefinedMessage(
          "f. designing financial services or related products for the use of Data Subjects; ",
        ),
        mapStringToDefinedMessage(
          "g. marketing  services,  products  and  other  subjects  (please  see  further  details  in paragraph 5 below);",
        ),
        mapStringToDefinedMessage(
          "h. verifying  the  Personal  Data  or  information  provided  by  any  Data  Subjects  or  other third parties;",
        ),
        mapStringToDefinedMessage(
          "i. enforcing   Data   Subjects’   obligations,   including   but   not   limited   to   determining amounts  owed  by  Data  Subjects;  the  collection  of  amounts  outstanding  from  Data Subjects and those providing security for Data Subjects’ obligations;",
        ),
        mapStringToDefinedMessage(
          "j. complying  with  the  obligations,  requirements  or  arrangements  for  disclosing  and using  Personal  Data  that  apply  to  FundPark  or  its  Group  of  Companies  or  that  it  is expected to comply according to:",
        ),
        mapStringToDefinedMessage(
          "(i) any  law  binding  or  applying  to  it  within  or  outside  the  Hong  Kong  existing currently and in the future;",
        ),
        mapStringToDefinedMessage(
          "(ii) any   guidelines   or   guidance   given   or   issued   by   any   legal,   regulatory, governmental, tax, law enforcement or other authorities, or self-regulatory or industry bodies or associations of financial services providers within or outside the Hong Kong existing currently and in the future; and",
        ),
        mapStringToDefinedMessage(
          "(iii) any  present  or  future  contractual  or  other  commitment  with  local  or  foreign legal, regulatory, governmental, tax, law enforcement or other authorities, or self-regulatory or industry bodies or associations of financial services providers that is assumed by or imposed on FundPark or its Group of Companies by reason of its financial, commercial, business or other interests or activities in or related to   the   jurisdiction   of   the   relevant   local   or   foreign   legal,   regulatory, governmental,  tax,  law  enforcement  or  other  authority,  or  self-regulatory  or industry bodies or associations.",
        ),
        mapStringToDefinedMessage(
          "k. complying  with  any  obligations,  requirements,  policies,  procedures,  measures  or arrangements  for  sharing  Personal  Data  and  information  within  the  Group  of Companies and/or any other use of Personal Data and information in accordance with any   group-wide   programmes   for   compliance   with   sanctions   or   prevention   or detection of money laundering, terrorist financing or other unlawful activities; and",
        ),
        mapStringToDefinedMessage(
          "l. all other incidental and associated purposes relating to any of the foregoing purposes.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage(
        "Use of Personal Data in Direct Marketing",
      ),
      description: [
        mapStringToDefinedMessage(
          "5. FundPark may use the Personal Data of the Data Subjects in direct marketing, provided that FundPark obtained the consent for that purpose, and if the Data Subjects do not provide such consent to FundPark, FundPark shall not use such Personal Data for that purpose. The Data Subject may exercise an opt-out right by notifying FundPark at any time and without charge.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("Disclosure of Personal Data"),
      description: [
        mapStringToDefinedMessage(
          "6. Any  Personal  Data  relating  to  the  Data  Subjects  obtained  by  FundPark  may  be  used, transferred, stored and disclosed for such purposes in accordance with the FundPark's policies and in connection with the matching procedures as defined in the Ordinance. FundPark will disclose and transfer from time to time Personal Data of the Data Subjects to:",
        ),
        mapStringToDefinedMessage(
          "a. FundPark’s Group of Companies and any officers, directors, employees, professional advisers, partners and representatives of its Group of Companies;",
        ),
        mapStringToDefinedMessage(
          "b. any  agent,  contractor  or  third  party  service  provider  which  provides  services  of  any kind   to   FundPark   in   connection   with   the   operation   of   its   business   (including FundPark's   auditors   and   legal   advisors,   insurance   companies,   credit   reference agencies); ",
        ),
        mapStringToDefinedMessage(
          "c. any  party  who  executes  the  Facility  Letter,  Charge,  Guarantee,  Debenture,  other security  documents  and  other  finance  agreements  for  the  purpose  of  receiving products and/or services provided by FundPark;",
        ),
        mapStringToDefinedMessage(
          "d. any insurer, valuer and auditor of the Data Subjects;",
        ),
        mapStringToDefinedMessage(
          "e. any  actual  or  proposed  participant  or  sub-participant  in,  or  assignee,  transferee  or novatee of FundPark’s rights in relation to the Finance Documents;",
        ),
        mapStringToDefinedMessage(
          "f. any person with (or through) whom FundPark may enter into (or may potentially enter into) any transaction in connection with the purchase or sale of any credit insurance or any other contractual protection or hedging with respect to FundPark’s rights and obligations;",
        ),
        mapStringToDefinedMessage(
          "g. any intermediary institution and/or agent or sub-agent thereof upon their request for the purpose of completing any transaction under the Finance Documents;",
        ),
        mapStringToDefinedMessage(
          "h. any local or overseas governmental or regulatory authorities upon their request or for the purpose of ensuring compliance with applicable laws and regulations;",
        ),
        mapStringToDefinedMessage(
          "i. any person if such disclosure is necessary or desirable for FundPark’s performance of its duties or exercise or enforcement of its rights and powers under or in connection with  any  agreement  or  document  with  the  Data  Subjects  such  as  debt  collection agencies; and",
        ),
        mapStringToDefinedMessage(
          "j. any  other  third  party  in  connection  with  such  purposes  as  FundPark  may  consider necessary or desirable.",
        ),
        mapStringToDefinedMessage(
          "k. The parties prescribed in paragraph 6a – 6j and they may be in a place outside of Hong Kong and Personal Data may be transferred in and/or to a place outside Hong Kong.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("Security of Personal Data"),
      description: [
        mapStringToDefinedMessage(
          "7.  FundPark shall take all practicable steps to ensure that Personal Data held by it is protected against  unauthorized  or  accidental  access,  processing,  erasure  or  other  use.  FundPark  shall make sure that it processes only those Personal Data that are adequate, relevant and limited to what is necessary in relation to the specific purposes for which they are collected. FundPark shall apply all reasonable and practical measures to suppress or rectify all Personal Data that may be irrelevant, inaccurate or incomplete, with respect to the said purposes.",
        ),
        mapStringToDefinedMessage(
          "8.  FundPark retains Personal Data only for the time strictly necessary for lawful processing. After that  period,  the  Personal  Data  shall  be  deleted  or,  where  appropriate,  kept  locked  in accordance  with  the  legal  retention  periods  and  limitation  periods  for  the  liabilities  arising from the processing indicated in the data protection information when providing the Personal Data.  The  Personal  Data  obtained  from  Data  Subjects  are  stored  electronically  in  database, computers or portable storage devices and it is protected with adequate IT security measures and access control. ",
        ),
        mapStringToDefinedMessage(
          "9. In  engaging  a  marketing  company  to  carry  out  direct  marketing  purposes,  FundPark  shall ensure  the  safe  handling  and  erasure  after  use  by  the  marketing  company  of  the  Personal Data transferred to it in order to safeguard against unauthorised access or use of that Personal Data.  If FundPark engages a data processor (whether within or outside Hong Kong) to process Personal  Data  on  FundPark’s  behalf,  FundPark  would  adopt  contractual  or  other  means  to prevent  unauthorised  or  accidental  access,  processing,  erasure,  loss  or  use  of  the  Personal Data transferred to the Personal Data processor for processing. FundPark shall also ensure the data processor are competent to protect security and confidentiality of the Personal Data.",
        ),
        mapStringToDefinedMessage(
          "10. FundPark shall ensure that the Personal Data will be processed with the appropriate level of security, including protection against unauthorized or illicit processing activity and against its loss,  destruction  or  accidental  damage,  through  the  application  of  appropriate  technical  or organizational  measures,  such  as  encryption  of  the  Personal  Data.  Likewise,  FundPark  shall apply the appropriate measures to ensure the permanent confidentiality, integrity, availability and resilience of the processing activity systems and services.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("Application of Cookies"),
      description: [
        mapStringToDefinedMessage(
          "11. FundPark  may  use  third  party  web  analytic  services  on  Our  Website.  The  service  providers who  administer  these  services  use  technologies  such  as  cookies,  web  server  logs  and  web beacons  to  help  FundPark  analyse  how  visitors  use  the  website.  The  information  collected through these means (including IP address) is disclosed to these service providers, who use the information to evaluate use of Our Website. These service providers may use the Personal Data  collected  to  contextualise  and  personalise  the  marketing  materials  of  their  own advertising network.",
        ),
        mapStringToDefinedMessage(
          "12. In addition, FundPark uses cookies to distinguish different users of Our Website. By continuing to browse Our Website, the Data Subjects are agreeing to the use of cookies.",
        ),
        mapStringToDefinedMessage(
          "13. Cookies  are  small  data  files  consisting  of  letters  and  numbers  that  FundPark  store  on  the computer browser or the hard drive of the Data Subjects if consent is obtained.",
        ),
        mapStringToDefinedMessage(
          "14. Cookies and similar technologies are used to identify the device for the following purposes:",
        ),
        mapStringToDefinedMessage(
          "a. Strictly  necessary  cookies. These  essential  cookies  are  set  throughout  Our  Website and are required for the operation of Our Website to:",
        ),
        mapStringToDefinedMessage(
          "(i) allow  the  web  server  to  determine  whether  the  cookies  setting  on  the  Data Subjects  web  browser  has  been  enabled  or  disabled.  This  allows  FundPark  to know whether data can be collected from the Data Subjects web browser;",
        ),
        mapStringToDefinedMessage(
          "(ii) temporarily allow the Data Subjects to carry information between pages of Our Website to avoid having to re-enter that information;",
        ),
        mapStringToDefinedMessage(
          "(iii) temporarily  identify  the  device  after  the  Data  Subjects  have  logged  in  to  a secure  page  on  Our  Website  so  that  our  web  server  can  maintain  a  dialogue with  the  web  browser  in  order  for  the  Data  Subjects  to  carry  out  certain activities.",
        ),
        mapStringToDefinedMessage(
          "b. Analytical/performance cookies. These are used to help us improve Our Website by:",
        ),
        mapStringToDefinedMessage(
          "(i) tracking  the  Data  Subjects’  visits  to  Our  Website  and  recognising  the  web browser when the Data Subjects are a repeat visitor so that FundPark can gather statistics on new and repeat visitors to evaluate site effectiveness.",
        ),
        mapStringToDefinedMessage(
          "c. Functionality cookies. These are used to recognise the Data Subjects when the Data Subjects return to Our Website. This enables FundPark to:",
        ),
        mapStringToDefinedMessage(
          "(i) personalise the content for the Data Subjects and remember their preferences (for example, the choice of language and region);",
        ),
        mapStringToDefinedMessage(
          "(ii) store login and other preferences so the Data Subjects do not have to re-enter that information when they return to Our Website.",
        ),
        mapStringToDefinedMessage(
          "d. Targeting  /  advertising  cookies. These  cookies  record  the  visit  to  Our  Website,  the Data Subjects to our online advertisements, track the pages they have visited and the website links they have followed. FundPark use this information to:",
        ),
        mapStringToDefinedMessage(
          "(i) make Our Website more relevant to their interests;",
        ),
        mapStringToDefinedMessage(
          "(ii) provide online advertisements or offers on Our Website or third-party websites which are most likely to interest the Data Subjects;",
        ),
        mapStringToDefinedMessage(
          "(iii) evaluate the effectiveness of our online marketing and advertising programs.",
        ),
        mapStringToDefinedMessage(
          "The above cookies may be placed on the device by FundPark or by third parties on our behalf (for example, advertising networks and providers of external services like web traffic analysis services). No personally identifiable information about the Data Subjects is collected or shared with third parties as a result of this research.",
        ),
        mapStringToDefinedMessage(
          "Most web browsers are initially set up to accept cookies. the Data Subjectscan choose to ‘not accept’ cookies by changing the settings on the web browser but if the Data Subjectsblock all cookies, including strictly necessary cookies, they may find that certain features on Our Website will not work properly.",
        ),
        mapStringToDefinedMessage(
          "15. Third parties (for example, advertising networks and providers of external services like web traffic  analysis  services)  may  also  use  cookies,  over  which  FundPark  have  no  control.  These cookies are likely to be analytical/performance cookies or targeting cookies.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("Changes to Data Privacy Policy"),
      description: [
        mapStringToDefinedMessage(
          "16. Any changes of this Privacy Policy in the future will be posted on Our Website and will become effective without prior notice.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("Record Retention"),
      description: [
        mapStringToDefinedMessage(
          "17. FundPark  retains  Personal  Data  in  accordance  with  regulatory  obligation.  Under  normal circumstances, this will be kept up to 7 years after the termination of business with FundPark and  this  may  be  retained  for  a  longer  period  if  there  is  a  subsisting  reason  that  obliges FundPark to do so or FundPark has to fulfil any applicable statutory or contractual obligations.",
        ),
        mapStringToDefinedMessage(
          "18. If  FundPark  engages  a  data  processor  (whether  within  or  outside  Hong  Kong)  to  process Personal  Data  on  FundPark’s  behalf,  FundPark  would  adopt  contractual  or  other  means  to prevent any Personal Data transferred to the data processor from being kept longer than is necessary for processing of the data.",
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("Access of Personal Data"),
      description: [
        mapStringToDefinedMessage(
          "19. The  Data  Subjects  shall  have  the  right  to  access  and  update  the  information  and  contact FundPark in order:",
        ),
        mapStringToDefinedMessage(
          "a. to  enquire  whether  FundPark  holds  Personal  Data  about  him  and  of  access  to  such Personal Data;",
        ),
        mapStringToDefinedMessage(
          "b. to require FundPark to correct any Personal Data relating to him which is inaccurate;",
        ),
        mapStringToDefinedMessage(
          "c. to ascertain FundPark’s policies and practices in relation to the Personal Data and to be informed of the kind of Personal Data held by FundPark;",
        ),
        mapStringToDefinedMessage(
          "d. to  be  informed  on  request  which  items  of  Personal  Data  are  routinely  disclosed  to credit reference agencies and debt collection agencies and be provided with further information to enable the making of Personal Data access and correction requests to the relevant credit reference agency(ies) or debt collection agency(ies).",
        ),
        mapStringToDefinedMessage(
          "20. Reasonable service fee may be charged by FundPark for Personal Data access request.",
        ),
        mapStringToDefinedMessage(
          "21. The Data Subject should send requests in writing for accessing or correction of Personal Data to FundPark and such request should be addressed to:",
        ),
        mapStringToDefinedMessage("Data Protection Officer"),
        mapStringToDefinedMessage("FundPark Limited"),
        mapStringToDefinedMessage(
          "16/F, 9 Chong Yip Street, Kwun Tong, Kowloon, Hong Kong ",
        ),
        mapStringToDefinedMessage("Telephone: 3460 2871"),
        mapStringToDefinedMessage("Email: admin@fundpark.com"),
        mapStringToDefinedMessage(
          "22. In  the  event  that  the  Data  Subjects  wish  to  access  or  amend  Personal  Data,  FundPark  may request  them  to  provide  personal  details  in  order  to  verify  and  confirm  their  identity. FundPark will provide a response to a request within reasonable time and will endeavour to do so wherever possible.",
        ),
      ],
    },
  ],
};

export const personalPrivacyPolicyInfo: PlainIntroWithSubtitleProps = {
  paragraph: [
    {
      title:
        mapStringToDefinedMessage(
          "Personal Information Collection Statement To Job Applicants, Employees, and Job Service Providers*",
        ),
      description: [
        mapStringToDefinedMessage(
          'In compliance with the Personal Data (Privacy) Ordinance (“the Ordinance”), FundPark Limited (the “Company”, “we”, “us”, or “our”) would like to inform you of the following: ',
        )
      ]
    },
    {
      title: mapStringToDefinedMessage("Collection"),
    },
    {
      description: [
        mapStringToDefinedMessage(
          '1. It is necessary for job applicants, potential service providers to provide the Company with personal data about themselves in respect of their application or proposed provision of Services (the "Services").'
        ),
        mapStringToDefinedMessage(
          '2. For job applicants, if your employment application is successful, your personal data collected in respect of such application will be retained by the Company and form part of your employee records. For potential service providers, if you are engaged to provide Services, your personal data collected will be retained by the Company. More personal data about you and/or your family members may also be collected from you during your period of employment with, or the period of your provision of Services to the Company.'
        ),
        mapStringToDefinedMessage(
          '3. If you cannot provide data requested by the Company, we will be unable to process your application for employment, or application(s) for benefits, or unable to continue your employment with the Company, or unable to allow you to provide or continue to provide the Services to the Company.'
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("PURPOSES"),
      description: [
        mapStringToDefinedMessage(
          'The purposes for which data relating to job applicants, employees and job service providers may be used are as follows:'
        ),
        mapStringToDefinedMessage(
          "i. Considering your current and/or future application(s) of employment or your provision of Services;"
        ),
        mapStringToDefinedMessage(
          "ii. Conducting (pre)-employment reference checks and background vetting and whenever appropriate during your employment period;"
        ),
        mapStringToDefinedMessage(
          "iii. Conducting background vetting prior to your provisions of Services and whenever appropriate during the period of your provision of Services;"
        ),
        mapStringToDefinedMessage(
          "iv. Reviewing salaries, incentives, bonuses and other benefits;"
        ),
        mapStringToDefinedMessage(
          "v. Considering promotion, training, assignments or transfer;"
        ),
        mapStringToDefinedMessage(
          "vi. Considering eligibility for and processing medical benefits, retirement benefits and other benefits arrangements;"
        ),
        mapStringToDefinedMessage(
          "vii. Providing your business contact details in the ordinary course of our business; "
        ),
        mapStringToDefinedMessage(
          "viii. Providing employee references;"
        ),
        mapStringToDefinedMessage(
          "ix. Monitoring and administering compliance with internal policies, procedures, guidelines or rules of the Company;"
        ),
        mapStringToDefinedMessage(
          "x. Complying with the obligations, requirements or arrangements for disclosing and using data that apply to the Company that it is expected to comply with ",
        ),
        mapStringToDefinedMessage(
          "(a) any law, regulation, court order or applying to it within or outside the Hong Kong Special Administrative Region (“HKSAR”);"
        ),
        mapStringToDefinedMessage(
          "(b) any guidelines or requests given or issued by any legal, regulatory, governmental, tax, law enforcement or other authorities within or outside the HKSAR;"
        ),
        mapStringToDefinedMessage(
          "xi. for human resources management or purposes relating thereto."
        )
      ],
    },
    {
      title: mapStringToDefinedMessage("PREREQUISITES FOR YOUR ACTIONS TO PROVIDE ANY PERSONAL DATA OF OTHER PERSONS"),
      description: [
        mapStringToDefinedMessage(
          "Whenever you provide any personal data of a third party (no matter whether his/her role involved is a contact person for whatever purpose, a referee, former or current colleague, coworker or partner, a family member, a close associate, a recommender, an advocate, etc.) to us for any use during a job application process, or any of our recruiting or due diligence exercises, you shall ensure, warrant and represent that:",
        ),
        mapStringToDefinedMessage(
          "•  you have already informed the third party of any intended provision and use in any of the above contexts;"
        ),
        mapStringToDefinedMessage(
          "•  you have already obtained the consent of the third party on such kinds of provision and use of his/her personal data;",
        ),
        mapStringToDefinedMessage(
          "•  such kinds of provision and usage or utilization of personal data is in compliance with and covered by the consent of the third party"
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("DISCLOSURE"),
      description: [
        mapStringToDefinedMessage(
          "Data held by the Company relating to jobs applicants, employees and job service providers will be kept confidential but the Company may provide such information to the following parties, whether within or outside the HKSAR:"
        ),
        mapStringToDefinedMessage(
          "i. any agents, contractors, sub-contractors, insurers/insurance brokers, professional advisers or third party service providers who provide services to the Company in respect of the operation or maintenance of our business (including but not limited to employment reference check, background vetting, provision or administration of benefits and services);"
        ),
        mapStringToDefinedMessage(
          "ii. any persons under a duty of confidentiality to the Company which has undertaken to keep such information confidential;"
        ),
        mapStringToDefinedMessage(
          "iii. any persons to whom the Company is under an obligation or otherwise required to make disclosure for the purposes set out in Paragraph – “Purpose” (x) above;"
        ),
        mapStringToDefinedMessage(
          "iv.  any persons seeking employee references with the prescribed consent of the employee concerned in accordance with Section 2(3) of the Ordinance; and"
        ),
        mapStringToDefinedMessage(
          "v. any of your emergency contacts."
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("PROTECTION OF DATA"),
      description: [
        mapStringToDefinedMessage(
          "Safeguarding the privacy of your personal data is important to us, whether you interact with us personally, by phone, mail, over the internet or other electronic medium or through third party platforms or channels. We preserve your personal data in a combination of secure computer storage facilities and paper-based files and other records which are only in the possession of our HR or its direct reporting line, and take such steps as are reasonable in the circumstances to protect the personal data we hold from misuse, interference and loss or unauthorised access, modification or disclosure. "
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("RETENTION OF DATA"),
      description: [
        mapStringToDefinedMessage(
          "The Company may retain:"
        ),
        mapStringToDefinedMessage(
          "i. the data of unsuccessful applicants for future recruitment purposes up to two (2) years from the date of receipt and that the data may be retained for a longer period if there is a subsisting reason that obliges us to do so;"
        ),
        mapStringToDefinedMessage(
          "ii. your personal data for a period of no longer than seven (7) years after your cessation of employment, provided that there is a subsisting reason that obliges the Company to retain the data for a longer period or the data is necessary for the Company to fulfil any applicable statutory or contractual obligations."
        ),
      ],
    },
    {
      title: mapStringToDefinedMessage("RIGHTS"),
      description: [
        mapStringToDefinedMessage(
          "1. In accordance with the terms of the Ordinance, you have the right"
        ),
        mapStringToDefinedMessage(
          "(i). to check whether the Company holds data about you and access to such data;"
        ),
        mapStringToDefinedMessage(
          "(ii). to require the Company to correct any data relating to you which is inaccurate; and"
        ),
        mapStringToDefinedMessage(
          "(iii). to ascertain the Company’s policies and practices in relations to data and to be informed of the kind of personal data held by the Company. "
        ),
        mapStringToDefinedMessage(
          "2. In accordance with the terms of the Ordinance, the Company has the right to charge a reasonable fee for the processing of any data access request."
        ),
        mapStringToDefinedMessage(
          "3. You should send requests for access to data or correction of data or for information regarding the Company’s personnel privacy policies and practices and kinds of personal data held to the following:"
        ),
        mapStringToDefinedMessage(
          "The Human Resources Manager"
        ),
        mapStringToDefinedMessage(
          "FundPark Limited"
        ),
        mapStringToDefinedMessage(
          "16/F, 9 Chong Yip Street, Kwun Tong, Hong Kong"
        ),
        mapStringToDefinedMessage(
          "4. Nothing in this statement shall limit your rights under the Ordinance."
        )
      ],
    },
    {
      title: mapStringToDefinedMessage('EXEMPTIONS'),
      description: [
        mapStringToDefinedMessage('1. The Company is exempted/entitled to refuse requests for accessing and/or correcting personal data in the circumstances prescribed in the Ordinance, including:'),
        mapStringToDefinedMessage('(i). Personal data which are contained of information relevant to any staff planning proposals to fill any series of either current or potential positions or terminate employment.'),
        mapStringToDefinedMessage('(ii). Personal data involved in a “relevant process” as defined in the Ordinance (such as the process for determining the suitability, eligibility or qualifications of an employee for employment or promotion, awards or any disciplinary action) until the completion of that process.'),
      ]
    },
    {
      title: mapStringToDefinedMessage('Note:'),
      description: [
        mapStringToDefinedMessage('A job service provider refers to an individual who is not employed by the Company but is employed by a third party. i.e. a contractor or secondee, for the purposes of this statement.')
      ]
    }
  ],
};

export const dataPolicyInfo: NestedIntroProps = {
  descriptions: [
    mapStringToDefinedMessage(
      "This Data Policy Notice (this “Notice”) sets out the data policies of FundPark Limited (the “Platform”, which includes its successors and assigns) in the Hong Kong Special Administrative Region of the People's Republic of China (“Hong Kong”). This Notice forms part of the other agreements or arrangements that the Data Subjects enter into with the Platform. If any inconsistency is found among this Notice and the other agreements or arrangements, the provisions of this Notice shall prevail.",
    ),
    mapStringToDefinedMessage(
      "References to “Data Subjects” in this Notice means the customers of the Platform and various other persons, including without limitation, applicants for financial services and credit facilities, security providers, sureties, guarantors, shareholders, directors, corporate officers and managers, sole proprietors, partners, suppliers, contractors, service providers and other contractual counterparties supplying data (including personal data as defined in the Personal Data (Privacy) Ordinance (the “Ordinance”)) to the Platform.",
    ),
  ],
  articles: [
    {
      article: mapStringToDefinedMessage(
        "From time to time, it is necessary for Data Subjects to supply the Platform with data in connection with various matters such as the opening or continuation of accounts, establishment or continuation of credit facilities, provision of financial services, provision of supplies or services to the Platform and/or Data Subjects or compliance with any laws, regulations, guidelines or requests issued by Authorities.",
      ),
    },
    {
      article: mapStringToDefinedMessage(
        "Failure to supply such data may result in the Platform being unable to open or continue accounts or establish or continue credit facilities or provide financial services or accept or continue with the provision of services.",
      ),
    },
    {
      article: mapStringToDefinedMessage(
        "It is also the case that data are collected from Data Subjects in the ordinary course of the continuation of the relationships with them (e.g. when Data Subjects apply for credit facilities or services or give instructions).",
      ),
    },
    {
      article: mapStringToDefinedMessage(
        "The purposes for which data relating to any of the Data Subjects may be used will vary depending on the nature of the Data Subjects' relationship with the Platform. Broadly, they may comprise any or all of the following purposes:",
      ),
      paragraphs: [
        {
          paragraph: mapStringToDefinedMessage(
            "processing of applications for financial services and credit facilities;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "operation of services and credit facilities provided by or to the Platform or to Data Subjects;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "provision of reference (status enquiries);",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "conducting credit and other status checks;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "assisting other financial institutions to conduct credit checks and collect debts;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "ensuring ongoing credit-worthiness of Data Subjects;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "researching and/or designing financial services or related products for Data Subjects' use;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "marketing services, products and other subjects (see further details in paragraph (f) below);",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "operating internal controls including determining the amount of indebtedness owed to or by Data Subjects;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "performing treasury functions;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "enforcement of Data Subjects' obligations, including without limitation the collection of amounts outstanding from Data Subjects and those providing security for Data Subjects' obligations;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "for operational purposes, credit assessment, credit scoring models or statistical analysis, whether on the Data Subjects or otherwise;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "meeting obligations, requirements or arrangements, whether compulsory or voluntary, of the Platform to comply with, or in connection with:",
          ),
          subparagraphs: [
            mapStringToDefinedMessage(
              "any law, regulation, judgment, court order, voluntary code or sanctions regime, within or outside Hong Kong existing currently and in the future;",
            ),
            mapStringToDefinedMessage(
              "any guidelines, guidance or requests given or issued by any legal, judicial, regulatory, governmental, tax, law enforcement or other authorities, or self-regulatory or industry bodies or associations of financial services providers within or outside Hong Kong existing currently and in the future (the “Authorities”) and any international guidance, internal policies or procedures;",
            ),
            mapStringToDefinedMessage(
              "any present or future contractual or other commitment with local and foreign Authorities that is assumed by or imposed on or applicable to the Platform by reason of its financial, commercial, business or other interest or activities in or related to the jurisdiction of the relevant local or foreign Authorities;",
            ),
          ],
        },
        {
          paragraph: mapStringToDefinedMessage(
            "complying with any obligations, requirements, policies, procedures, measures or arrangements for sharing data and information with the Platform and/or any other use of data and information in accordance with any programmes for compliance with sanctions or prevention or detection of money laundering, terrorist financing or other unlawful activities;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "creating and maintaining the Platform's credit scoring models;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "enabling an actual or proposed assignee of the Platform or participant or sub-participant of the Platform's rights in respect of the Data Subjects to evaluate the transaction intended to be the subject of the assignment, participation or sub-participation;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "conducting procedures specifically provided for in any particular service and/or credit facility offered by the Platform. Such procedures include matching procedures (as defined in the Ordinance, but broadly includes the comparison of two or more sets of the Data Subjects' data, for the purposes of taking actions adverse to the interests of the Data Subjects, such as declining an application);",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "meeting any obligations of the Platform to comply with any demand or request from the Authorities, such as exchange of information, withholding or deduction; and",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "all other incidental and associated purposes relating to any of the above purposes, including seeking professional advice.",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "The Platform keeps data only for as long as is reasonably required for the above purposes or as required by applicable laws. This includes keeping, for as long as reasonably required, such data as required for handling enquiries relating to any of the above purposes.",
          ),
        },
      ],
    },
    {
      article: mapStringToDefinedMessage(
        "Data held by the Platform relating to the Data Subjects will be kept confidential but the Platform may provide such information to the following parties (whether within or outside Hong Kong) for any of the purposes set out in paragraph (d) above:",
      ),
      paragraphs: [
        {
          paragraph: mapStringToDefinedMessage(
            "any agent, contractor, sub-contractor, service provider or associate of the Platform (including their employees, directors, officers, agents, contractors, service providers and professional advisers);",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "any third party service provider who provides administrative, telecommunications, computer, payment or securities clearing or other services to the Platform in connection with the operation of its business (including their employees, directors and officers);",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage("any Authorities;"),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "any other person which has undertaken expressly or impliedly to the Platform to keep such information confidential;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "any authorised institution (as such term is defined in the Banking Ordinance) or other authorised or regulated entity of similar nature in another jurisdiction with which the Data Subjects have or propose to have dealings;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "credit reference agencies, and, in the event of default, to debt collection agencies;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "any person to whom the Platform is under an obligation or required or expected to make disclosure under the requirements of any law binding on or applying to the Platform or any disclosure under and for the purposes set out in, or in connection with, paragraph (d)(xiii) and (d)(xiv);",
          ),
        },

        {
          paragraph: mapStringToDefinedMessage(
            "any actual or proposed assignee of the Platform or participant or sub-participant or transferee of the Platform's rights in respect of the Data Subject; and",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(" "),
          subparagraphs: [
            mapStringToDefinedMessage(
              "third party financial institutions and insurers, securities and investment services providers;",
            ),
            mapStringToDefinedMessage(
              "charitable and non-profit making organisations; and",
            ),
            mapStringToDefinedMessage(
              "external service providers (including but not limited to professional advisers, mailing houses, telecommunication companies, telemarketing and direct sales agents, call centres, data processing companies, information technology companies and market research firms),",
            ),
            mapStringToDefinedMessage(
              "that the Platform engages for the purposes set out in paragraph (d)(viii).",
            ),
          ],
        },
      ],
    },
    {
      article: mapStringToDefinedMessage("Use of Data in Direct Marketing"),
      descriptions: [
        mapStringToDefinedMessage(
          "The Platform may use the Data Subjects' data in direct marketing, provided always that the Platform shall obtain the prior written consent of the Data Subjects for that purpose, and if the Data Subjects do not provide such consent to the Platform, the Platform shall not use such Data Subjects' data for that purpose. In this connection, if the Data Subjects consent to the Platform's use of their data, please note that:",
        ),
      ],
      paragraphs: [
        {
          paragraph: mapStringToDefinedMessage(
            "the name, contact details, products and services portfolio information, transaction pattern and behaviour, financial background and demographic data of the Data Subjects held by the Platform from time to time may be used by the Platform in direct marketing;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "the following classes of services, products and subjects may be marketed:",
          ),
          subparagraphs: [
            mapStringToDefinedMessage(
              "financial, insurance and related services and products; and",
            ),
            mapStringToDefinedMessage(
              "donations and contributions for charitable and/or non-profit making purposes;",
            ),
          ],
        },
        {
          paragraph: mapStringToDefinedMessage(
            "the above services, products and subjects may be provided or (in the case of donations and contributions) solicited by the Platform and/or:",
          ),
          subparagraphs: [
            mapStringToDefinedMessage(
              "third party financial institutions, insurers, securities and investment services providers; and",
            ),
            mapStringToDefinedMessage(
              "charitable or non-profit making organisations;",
            ),
          ],
        },
        {
          paragraph: mapStringToDefinedMessage(
            "in addition to marketing the above services, products and subjects itself, the Platform may also provide the data described in paragraph (f)(i) above to all or any of the persons described in paragraph (f)(iii) above for use by them in marketing those services, products and subjects, provided always that the Platform shall obtain the prior written consent of the Data Subjects for that purpose, and if the Data Subjects do not provide such consent to the Platform, the Platform shall not provide such Data Subjects' data for that purpose;",
          ),
        },
      ],
      underlineDescription: mapStringToDefinedMessage(
        "If any of the Data Subjects does not wish the Platform to use or provide to other persons his/her data for the purpose of direct marketing as described above, the Data Subjects may exercise his/her opt-out right by notifying the Platform at any time and without charge.",
      ),
    },
    {
      article: mapStringToDefinedMessage(
        "Under and in accordance with the terms of the Ordinance and the Code, any of the Data Subjects has the right:",
      ),
      paragraphs: [
        {
          paragraph: mapStringToDefinedMessage(
            "to check whether the Platform holds data about him/her and access such data;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "to require the Platform to correct any data relating to him/her which is inaccurate;",
          ),
        },
        {
          paragraph: mapStringToDefinedMessage(
            "to ascertain the Platform's policies and practices in relation to data and to be informed of the kind of personal data held by the Platform; and",
          ),
        },
      ],
    },
    {
      article: mapStringToDefinedMessage(
        "The Platform may obtain a credit report on or access the database of the Data Subjects from a credit reference agency in considering any application for credit or conducting credit reviews from time to time. In the event that the Data Subjects wish to access the credit report, the Platform will advise the contact details of the relevant credit reference agency.",
      ),
    },
    {
      article: mapStringToDefinedMessage(
        "Data of the Data Subjects may be used, processed, kept, transferred or disclosed in and to any country as the Platform or any person who has obtained such data from the Platform referred to in paragraph (e) above considers appropriate. Such data may also be used, processed, kept, transferred or disclosed in accordance with the local practices and laws, rules and regulations (including any governmental acts and orders) in such country.",
      ),
    },
    {
      article: mapStringToDefinedMessage(
        "The Platform may charge a reasonable fee for the processing of any data access request.",
      ),
    },
    {
      article: mapStringToDefinedMessage(
        "Nothing in this Notice shall limit the rights of Data Subjects under the Ordinance.",
      ),
    },
    {
      article: mapStringToDefinedMessage(
        "In accordance with the Ordinance, Data Subjects may make data access or data correction requests or request information regarding the Platform's policies and practices and kinds of data held. Such requests should be addressed to:",
      ),
      descriptions: [
        mapStringToDefinedMessage("The Data Protection Officer"),
        mapStringToDefinedMessage("FundPark Limited"),
        mapStringToDefinedMessage(
          "16 / F, 9 Chong Yip Street, Kwun Tong, Kowloon, Hong Kong",
        ),
        mapStringToDefinedMessage("Telephone: 3460 2871"),
        mapStringToDefinedMessage("Email: admin@fundpark.com"),
      ],
    },
  ],
  date: mapStringToDefinedMessage("September 2019"),
};

