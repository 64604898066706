import React from "react";
import InfoBlock from "./infoBlock";
import {
  TranslatedIntroInfoListProps,
  TranslatedIntroInfoProps
} from "interface/introInfo.interface";
import "styles/benefits-block.scss";

const BenefitsWithDetailsBlock = ({
  title,
  subtitle,
  infos,
  className
}: TranslatedIntroInfoListProps): JSX.Element => {
  // console.log(infos, 'infos')
  const infoBlockList = infos.map(
    (info: TranslatedIntroInfoProps, index: number): JSX.Element => {
      return <InfoBlock key={`info_block_${index}`} {...info} />;
    }
  );

  return (
    <div
      className={`benefits-block-wrapper d-flex flex-column align-items-center ${className}`}
    >
      <h3>{title}</h3>
      
      <div className="info-blocks-wrapper d-flex flex-wrap justify-content-between">
        {infoBlockList}
      </div>
    </div>
  );
};

export default BenefitsWithDetailsBlock;
