import { createBrowserHistory } from 'history';

/* global ga */
const history = createBrowserHistory();

// NOTE: tore tracking data in both old and new Google Analytics properties

let trackers;
// check whether ga is initialed
if (typeof ga === 'function') {
  trackers = typeof ga.getAll === 'function' && ga.getAll();
}

history.listen((location) => {
  if (typeof ga === 'function') {
    if (!Array.isArray(trackers)) {
      trackers = typeof ga.getAll === 'function' && ga.getAll();
    }
    if (Array.isArray(trackers)) {
      ga(function() {
        trackers.forEach(function(tracker) {
          ga(`${tracker.get('name')}.send`, 'pageview', location.pathname + location.search);
        });
      });
    }
  }
});

export default history;
