
import { TranslatedIntroInfoListProps } from "interface/introInfo.interface";
import React from "react";

const DirectionalProcedureBlockWithTitle = (
    props: TranslatedIntroInfoListProps
): JSX.Element => {
    return (
        <div className="procedure-block-wrapper">
            <h3>{props.title}</h3>
            <div className="vertical-procedure-block">
                <div className="timeline"></div>
                {props.infos.map((el,index)=>(
                    <div className="procedure-element">
                        <div className="content-index">{index+1}</div>
                        <div className="element-content">
                            <div className="elemenet-title">{(el.title)}</div>
                            <div className="element-description">{el.description}</div>
                        </div>
                    </div>
                ))}
                
            </div>
        </div>
    );
};

export default DirectionalProcedureBlockWithTitle;