import React from "react";
import {
  TranslatedSimpleIntroInfoListProps,
  SimpleIntroInfoProps
} from "interface/introInfo.interface";
import TranslatedProps from "interface/translatedProps.interface";
import { getLanguage } from "lib/language";
import { BUTTON_CLASSNAME } from "constants/language";
import "styles/suitableBlock.scss";

const SuitableBlock = ({
  id,
  title,
  introInfoList,
  btn
}: TranslatedSimpleIntroInfoListProps): JSX.Element => {
  const language = getLanguage();

  return (
    <div id={id} className="suitable-block-wrapper">
      <h3>{title}</h3>
      <div className="icon-items-wrapper">
        {introInfoList.map(
          (
            el: TranslatedProps<SimpleIntroInfoProps>,
            idx: number
          ): JSX.Element => {
            return (
              <div className="icon-item" key={`introInfo_${idx}`}>
                <div>
                  <img alt={`image_${idx}`} src={el.image} />
                </div>
                <p>{el.title}</p>
              </div>
            );
          }
        )}
      </div>
      {btn && (
        <a
          className={
            btn.btnType &&
            `${BUTTON_CLASSNAME[btn.btnType][language]} btn btn-main`
          }
          href={`${btn.href}${language}`}
        >
          {btn.text}
        </a>
      )}
    </div>
  );
};

export default SuitableBlock;
