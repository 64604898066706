// import React from "react";
import {
  mapStringToDefinedMessage,
  mapStringToFormattedStringProps,
} from "lib/translate/mapStringToDefinedMessage";
import { SlideProps } from "interface/cardsWithTitle.interface";
// import ROUTES from "constants/ROUTES";

import newsItems from "constants/strapi/news-items.json";

const cards = newsItems.map((item, index) => {
  return {
    className: "media-info",
    title: mapStringToFormattedStringProps({
      id: `news-items-${item.id}.title`,
      defaultMessage: "",
    }),
    description: mapStringToFormattedStringProps({
      id: `news-items-${item.id}.description`,
      defaultMessage: "",
    }),
    descriptionIsHTML: false,
    btn: {
      text: mapStringToDefinedMessage("Learn More"),
      href: `/news-and-events/${item.id}/${item.link}`,
    },
  };
});

export const newsInfo: SlideProps = {
  title: mapStringToFormattedStringProps({
    defaultMessage: "News Announcement",
    // tagName: "h2",
  }),
  cards,
  isSlide: true,
  slideConfig: {
    slidesToShow: 3,
  },
};
