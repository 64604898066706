import React from 'react';
import * as ReactRouterDom from "react-router-dom";
import { onClickWithDataLayer } from 'utils/helpers';
import { getLanguage } from 'lib/language';

export function GeneralLink(props) {
  const { Element = 'a', dataLayer: dataLayerMeta, onClick, ...rest } = props;
  return (
    <Element
      onClick={onClickWithDataLayer(dataLayerMeta, onClick)}
      {...rest}
    />
  );
}

export function NavLink(props) {
  const { to = '', ...rest } = props;

  // TODO: need to refactor the way to "getLanguage"
  return (
    <GeneralLink
      Element={ReactRouterDom.NavLink}
      to={`/${getLanguage()}${to}`}
      {...rest}
    />
  );
};
