// @ts-nocheck
import React from 'react';
// import { getLanguage } from "lib/language";
import ROUTES from "constants/ROUTES";
// import { BUTTON_CLASSNAME } from "constants/language";
import { ExternalLinkProps } from "interface/linkProps.interface";

//Translate
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";
import mapStringToDefinedMessage, { mapStringToFormattedStringProps } from "lib/translate/mapStringToDefinedMessage";

const TalkToUs = () : JSX.Element => {
    // const language = getLanguage();
    // const lang = language == 'en' ? 'en' : 'zh-hk'
    const registerButton = mapToTranslatedProps({
        btn: {
            text: mapStringToDefinedMessage("Contact Us"),
            href: `${ROUTES.BUSINESS_PATH}?ref=faq`,
            externalLink: true,
            // btnType: "signup",
        }
    });
    const lookingForWordings = mapToTranslatedProps({
        title: [
            mapStringToFormattedStringProps({
              defaultMessage: "Come talk to us if you would like to learn more."
            }),
          ],
    });

    const { btn } = registerButton;
    const { title } = lookingForWordings;

    const renderBtn = ({
        text,
        href,
        btnType
      }: ExternalLinkProps): JSX.Element => {
        return (
          <a
            className="btn btn-dark"
            href="mailto: hello@fundpark.com"
          >
            {text}
          </a>
        );
      };

    return (
        <>
        <div className='talkToUsContainer'>
            <span className='talkToUsWordings'>{title}
            </span>
            <div className='buttonContainer'>
                {renderBtn(btn)}  
            </div>
        </div>
        </>
    );
}

export default TalkToUs;