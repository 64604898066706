import React from 'react';
import 'styles/back-button.scss';

function BackButton(
  props:
    {
      className?: String;
      onClick: ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
    }) {
  const effectiveClassName = `back-button fa fa-arrow-left fa-2x d-none d-sm-block ${props.className || ''}`;

  return (
    <i className={effectiveClassName} aria-hidden="true" onClick={props.onClick} />
  )
}

export default BackButton;
