// @ts-nocheck
import React from "react";
import { Route, Redirect } from "react-router-dom";
import NavBar from "components/NavBar/navBar";
import Footer from "components/Footer/footer";
import Home from "containers/Home/Home";
import Business from "containers/Business/Business";
import Funder from "containers/Funder/Funder";
import Referrer from "containers/Referrer/Referrer";
import InvoiceFinancing from "containers/OurProducts/ReceivableFinancing";
import PurchaseOrder from "containers/OurProducts/PurchaseOrder";
import AboutUs from "containers/AboutUs/AboutUs";
import NewsAndEvents from "containers/NewsAndEvents/NewsAndEvents";
import Content from "containers/NewsAndEvents/Content";
import Career from "containers/Career/Career";
import CareerDetail from "containers/Career/CareerDetails";
import CoreValue from "containers/CoreValue/CoreValue";
import {
  TermsAndConditions,
  PrivacyPolicy,
  PersonalPrivacyPolicy,
  // DataPolicy
} from "containers/FooterNav/FooterNav";
import FAQ from "containers/FAQ/FAQ";
import ContactUs from "containers/ContactUs/ContactUs";
import ROUTES from "constants/ROUTES";
import SupplyChainFinance from "containers/OurProducts/SupplyChainFinance";
import TradeAssetBasedFinance from "containers/OurProducts/TradeAssetBasedFinance";
import eCommerceFinance from "containers/OurProducts/ECommerceFinance"
import SOFR from "containers/SOFR/SOFR"
import Walmart from "containers/Walmart/Walmart"
export function NotFound(): JSX.Element {
  return <div>404 Not Found</div>;
}

// eslint-disable-next-line react/prop-types
export function ValidRouteComponent({ getCurrentLanguage, initialLanguage }): JSX.Element {
  
  return (
    <>
      <NavBar {...getCurrentLanguage} />
      <Route exact path={ROUTES.INDEX}>
        <Redirect to={initialLanguage} />
      </Route>
      <Route path={ROUTES.BUSINESS_PATH} component={Business} />
      <Route path={ROUTES.FUNDER_PATH} component={Funder} />
      <Route path={ROUTES.REFERRER_PATH} component={Referrer} />
      <Route
        path={ROUTES.INVOICE_FINANCING_PATH}
        component={InvoiceFinancing}
      />
      <Route path={ROUTES.PURCHASE_ORDER_PATH} component={PurchaseOrder} />
      <Route path={ROUTES.ABOUT_US_PATH} component={AboutUs} />
      <Route
        exact
        path={ROUTES.NEWS_AND_EVENTS_PATH}
        component={NewsAndEvents}
      />
      <Route path={ROUTES.NEWS_CONTENT_PATH} component={Content} />
      <Route path={ROUTES.FAQ_PATH} component={FAQ} />
      <Route path={ROUTES.CONTACT_US_PATH} component={ContactUs} />
      <Route
        path={ROUTES.TERMS_AND_CONDITIONS_PATH}
        component={TermsAndConditions}
      />
      <Route
        path={ROUTES.CORE_VALUE_PATH}
        component={CoreValue}
      />
      <Route path={ROUTES.PRIVACY_POLICY_PATH} component={PrivacyPolicy} />
      <Route path={ROUTES.PERSONAL_PRIVACY_POLICY_PATH} component={PersonalPrivacyPolicy} />
      {/* <Route path={ROUTES.DATA_POLICY_PATH} component={DataPolicy} /> */}
      <Route path={ROUTES.SUPPLY_CHAIN_FINANCE_PATH} component={SupplyChainFinance}></Route>
      <Route path={ROUTES.TRADE_ASSET_BASED_FINANCE_PATH} component={TradeAssetBasedFinance}></Route>
      <Route path={ROUTES.ECOMMERCE_FINANCE_PATH} component={eCommerceFinance}></Route>
      <Route exact path={ROUTES.CAREER_PATH} component={Career} />
      <Route path={ROUTES.CAREER_DETAILS_PATH} component={CareerDetail} />
      <Route path={ROUTES.INDEX_PATH} exact component={Home} />
      <Route path={ROUTES.SOFR} component={SOFR} /> 
      <Route path={ROUTES.WALMART} component={Walmart} /> 
      <Footer />
    </>
  );
}
