import "styles/biz-nav.scss";
import React from "react";
import { TranslatedNavInfoProps } from "interface/footer.interface";
import LinkProps from "interface/linkProps.interface";
import { getLanguage } from "lib/language";

const BizNav = ({ options }: TranslatedNavInfoProps): JSX.Element => {
  const language = getLanguage();
  return (
    <ul className="nav justify-content-center biz-nav">
      {options.map(
        (el: LinkProps, idx: number): JSX.Element => {
          const { text, href } = el;
          return (
            <a
              key={`link_${idx}`}
              className="nav-link active"
              href={`/${language}${href}`}
            >
              <li className="nav-item">{text}</li>
            </a>
          );
        }
      )}
    </ul>
  );
};

export default BizNav;
