import "styles/banner.scss";
import React from "react";
import { TranslatedBannerProps } from "interface/banner.interface";
import { ExternalLinkProps } from "interface/linkProps.interface";
import { getLanguage } from "lib/language";
import { BUTTON_CLASSNAME } from "constants/language";

const HeaderBanner = (props: TranslatedBannerProps): JSX.Element => {
  const { 
    // header,
    title,
    description,
    btn,
    // classNameText,
    isHomePage
   } = props;
  const language = getLanguage();
  
  const languageBackground = language === 'en' ? '' : '_cn';
  const languageTitleWidth = language === 'en' ? '' : 'titleWidth';

  const renderBtn = ({
    text,
    href,
    btnType
  }: ExternalLinkProps): JSX.Element => {
    return (
      <a
        className={
          btnType &&
          `${BUTTON_CLASSNAME[btnType][language]} btn btn-info font-weight-bold py-3`
        }
        href={`${href}${language}`}
      >
        {text}
      </a>
    );
  };

  return (
    <div className={`banner${isHomePage ? '_isHomePage' : ''} text-white `}>
      <section>
        {/* <div><b>{header}</b></div> */}
        {/* <div>&nbsp;</div> */}
        <div className={`banner-title${isHomePage ? '_isHomePage' : ''} ${languageTitleWidth}`}>{title}</div>
        {
          Array.isArray(description) && !isHomePage &&
          <div className="banner-description">
            <ul>
              {description.map((element,index)=>(
                <li key={index.toString()}>{element}</li>
              ))}
            </ul>
          </div>
        }
        {
          isHomePage && (
            <div className="banner-icon_isHomePage_container">
              <div className={`banner-icon_isHomePage${languageBackground}`}>
                
              </div>
            </div>
          )
        }
        {!Array.isArray(description) && <div className="banner-description">{description}</div>}
        {btn && renderBtn(btn)}
      </section>
    </div>
  );
};

export default HeaderBanner;