import { IntroInfoProps } from "interface/introInfo.interface";
import mapStringToDefinedMessage, { mapStringToFormattedStringProps } from "lib/translate/mapStringToDefinedMessage";
import rite from 'images/core-value/rite-mobile.png';

export const introductionBlockInfo: IntroInfoProps = {
  image: rite,
  title: mapStringToDefinedMessage('Introduction'),
  description: mapStringToFormattedStringProps({
    id: 'As an online financial platform connecting corporates and funders',
    defaultMessage: 'As an online financial platform connecting corporates and funders, we aim to provide a truly professional service to our different stakeholders. We believe that the right strategy and a RITE culture help us to achieve our vision. RITE culture stands for Respect, Integrity, Teamwork, Excellence and Embrace Changes. It determines how we work with our stakeholders and each other, how we recruit, and how we make decisions. We fully commit ourselves to live up to our RITE belief.' 
  }),
}