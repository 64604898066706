import moment from 'moment';
import apStorage from './localStorage';

declare var dataLayer: any;

export function onClickWithDataLayer(dataLayerMeta, onClick) {
  // NOTE: dataLayer is a global function from Google Analytics
  return () => {
    pushDataLayer(dataLayerMeta);
    if (typeof onClick === 'function') {
      onClick();
    }
  };
}

export function pushDataLayer(dataLayerMeta) {
  if (
    typeof dataLayer === 'object' &&
    typeof dataLayer.push === 'function'
  ) {
    dataLayer.push(dataLayerMeta);
  }
}

export function getParameterByName(key, altUrl = '') {
  const url = altUrl || window.location.href;
  const name = key.replace(/[\\[\\]]/g, '\\$&');

  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);

  if (!results) {
      return null;
  }
  if (!results[2]) {
      return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const RM_CODE_SESSION_NAME = 'fp_rm_code';
export const RM_CODE_LIFETIME = 180;
export function rmCodeHandler() {
  const rmCode = getParameterByName('r') || '';
  if (!rmCode) {
    return;
  }
  const existingRmCode = apStorage.get(RM_CODE_SESSION_NAME) || '';
  if (existingRmCode) { // do not override the existing record if it is not expired
    if (moment(existingRmCode.expiry, 'YYYY-MM-DD HH:MM:SS').isAfter()) return;
  }
  apStorage.set(RM_CODE_SESSION_NAME, {
    code: rmCode,
    date: moment().format('YYYY-MM-DD HH:MM:SS'),
    expiry: moment().add(RM_CODE_LIFETIME, 'days').format('YYYY-MM-DD HH:MM:SS'),
  });
}
