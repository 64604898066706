import React from "react";
import ProcedureBlock from "./procedureBlock";
import { TranslatedProcedureWithTitleInfoProps } from "interface/introInfo.interface";
import "styles/suitableBlock.scss";

const ProcedureBlockWithTitle = (
  props: TranslatedProcedureWithTitleInfoProps
): JSX.Element => {
  const { id, title, description } = props;
  return (
    <div id={id} className="procedure-block-wrapper">
      <h3>{title}</h3>
      {description && <p>{description}</p>}
      <ProcedureBlock {...props} />
    </div>
  );
};

export default ProcedureBlockWithTitle;
