// @ts-nocheck
// import React from "react";
import {
  mapStringToDefinedMessage,
  mapStringToFormattedStringProps,
} from "lib/translate/mapStringToDefinedMessage";
import {
  SlideProps,
  CardsWithTitleProps,
} from "interface/cardsWithTitle.interface";
import img1 from "images/mediaCoverage/img1.jpg";
// import img2 from "images/mediaCoverage/img2.jpg";
// import img3 from "images/mediaCoverage/img3.jpg";
// import leftImage from "images/mediaCoverage/left.jpg";
// import middleImage from "images/mediaCoverage/middle.jpg";
// import rightImage from "images/mediaCoverage/right.jpg";
import ROUTES from "constants/ROUTES";

import mediaItems from "constants/strapi/media-items.json";

export const mediaCoverageInfo: SlideProps = {
  isSlide: true,
  slideConfig: {
    slidesToShow: 3,
  },
  title: mapStringToFormattedStringProps({
    defaultMessage: "Media Coverage",
  }),
  cards: mediaItems.map((item) => {
    const id: string = `media-items-${item.id}.title`;
    if (item.image == null) {
      return null
    }
    return {
      image: item.image.url,
      className: "media-info",
      title: mapStringToDefinedMessage(id),
      description: mapStringToFormattedStringProps({
        id: `media-items-${item.id}.description`,
        defaultMessage: "",
      }),
      btn: {
        text: mapStringToDefinedMessage("Learn More"),
        href: item.path,
        externalLink: true,
      },
      descriptionIsHTML: false,
    };
  }).filter(item => item != null),
  // cards: [
  //   {
  //       image: leftImage,
  //       className: "media-info",
  //       title: mapStringToFormattedStringProps({
  //           id: 'Media Coverage Card Title 1',
  //           defaultMessage: '',
  //       }),
  //       description: mapStringToFormattedStringProps({
  //           id: 'Media Coverage Card Body 1',
  //           defaultMessage: ''
  //       }),
  //       btn: {
  //       text: mapStringToDefinedMessage("Learn More"),
  //       href: ROUTES.MEDIA_COVERAGE_JUMPSTARTMAG,
  //       externalLink: true,
  //     },
  //   },
  //   {
  //       image: middleImage,
  //       className: "media-info",
  //       title: mapStringToFormattedStringProps({
  //           id: 'Media Coverage Card Title 2',
  //           defaultMessage: '',
  //       }),
  //       description: mapStringToFormattedStringProps({
  //           id: 'Media Coverage Card Body 2',
  //           defaultMessage: ''
  //       }),
  //       btn: {
  //       text: mapStringToDefinedMessage("Learn More"),
  //       href: ROUTES.MEDIA_COVERAGE_ZGSWCN,
  //       externalLink: true,
  //     },
  //   },
  //   {
  //       image: rightImage,
  //       className: "media-info",
  //       title: mapStringToFormattedStringProps({
  //           id: 'Media Coverage Card Title 3',
  //           defaultMessage: '',
  //       }),
  //       description: mapStringToFormattedStringProps({
  //           id: 'Media Coverage Card Body 3',
  //           defaultMessage: ''
  //       }),
  //       btn: {
  //       text: mapStringToDefinedMessage("Learn More"),
  //       href: ROUTES.MEDIA_COVERAGE_PCHOME,
  //       externalLink: true,
  //     },
  //   },
  //   {
  //     image: img1,
  //     className: "media-info",
  //     title: mapStringToFormattedStringProps({
  //       id: "Home Page Card News Title MingPao",
  //       defaultMessage:
  //         "<h3>看發票訂單 平台推中小企大額貸款 融資額可達千萬 數工作天可批</h3>",
  //       // tagName: "h3",
  //       values: {
  //         h3: (...chunks) => <h3 className="card-title h5">{chunks}</h3>,
  //       },
  //     }),
  //     description: mapStringToFormattedStringProps({
  //       id: "【明報專訊】融資一向是本港中小企面對的難題，有網上融資平台針對市場，連結借貸雙方做應收帳和訂單融資。近年銀行針對中小企和初創企業推出相關貸款，不過網上融資平台FundPark聯合創辦人兼首席執行官孫慧來指出，銀行較專注小額的貸款，該行則注重審核貿易生意本身的資料，例如發票和訂單，因此可提供金額較大的貸款，向客戶提供的融資額可達1000萬元。",
  //       defaultMessage:
  //         "【明報專訊】融資一向是本港中小企面對的難題，有網上融資平台針對市場，連結借貸雙方做應收帳和訂單融資。近年銀行針對中小企和初創企業推出相關貸款，不過網上融資平台FundPark聯合創辦人兼首席執行官孫慧來指出，銀行較專注小額的貸款，該行則注重審核貿易生意本身的資料，例如發票和訂單，因此可提供金額較大的貸款，向客戶提供的融資額可達1000萬元。",
  //       tagName: "p",
  //     }),
  //     btn: {
  //       text: mapStringToDefinedMessage("Learn More"),
  //       href: ROUTES.MEDIA_COVERAGE_1,
  //       externalLink: true,
  //     },
  //   },
  //   {
  //     image: img2,
  //     className: "media-info",
  //     title: mapStringToFormattedStringProps({
  //       id: "Home Page Card News Title HKEJ",
  //       defaultMessage: "<h3>港產初創助小企融資周轉</h3>",
  //       // tagName: "h3",
  //       values: {
  //         h3: (...chunks) => <h3 className="card-title h5">{chunks}</h3>,
  //       },
  //     }),
  //     description: mapStringToFormattedStringProps({
  //       defaultMessage:
  //         "做生意離不開資金周轉，不少中小企向傳統銀行洽談借貸時手續繁瑣，令人苦惱。港產初創FundPark看準市場商機，提供網上貿易融資平台，讓企業毋須任何物業抵押，即能以訂單（Purchase Order）及應收款項賬單（Invoice）作融資。《信報》StartupBeat請來其聯合創辦人及行政總裁孫慧來，分享如何活用大數據，分析買家、供應商的信貸評分。",
  //       tagName: "p",
  //     }),
  //     btn: {
  //       text: mapStringToDefinedMessage("Learn More"),
  //       href: ROUTES.MEDIA_COVERAGE_2,
  //       externalLink: true,
  //     },
  //   },
  //   {
  //     image: img3,
  //     className: "media-info",
  //     title: mapStringToFormattedStringProps({
  //       id: "Home Page Card News Title Meet",
  //       defaultMessage:
  //         "<h3>USPACE、FundPark雀屏中選！基石創投總經理談中華網家一號投資看法</h3>",
  //       values: {
  //         h3: (...chunks) => <h3 className="card-title h5">{chunks}</h3>,
  //       },
  //     }),
  //     description: mapStringToDefinedMessage(
  //       "PChome與中華電信合資4億元的創投基金「中華網家一號」，從2018年3月宣布以來就備受矚目，除了是台灣電商界與電信界強強聯手外，外界更好奇哪些新創團隊能入選第一波名單？",
  //     ),
  //     btn: {
  //       text: mapStringToDefinedMessage("Learn More"),
  //       href: ROUTES.MEDIA_COVERAGE_3,
  //       externalLink: true,
  //     },
  //   },
  // ],
};

export const pressReleaseInfo: CardsWithTitleProps = {
  title: mapStringToDefinedMessage("Press Release"),
  cards: [
    {
      image: img1,
      title: mapStringToDefinedMessage("Title 1"),
      description: mapStringToDefinedMessage(
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo",
      ),
      btn: {
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.MEDIA_COVERAGE_1,
        externalLink: true,
      },
    },
    {
      image: img1,
      title: mapStringToDefinedMessage("Title 2"),
      description: mapStringToDefinedMessage(
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo",
      ),
      btn: {
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.MEDIA_COVERAGE_1,
        externalLink: true,
      },
    },
    {
      image: img1,
      title: mapStringToDefinedMessage("Title 3"),
      description: mapStringToDefinedMessage(
        "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo",
      ),
      btn: {
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.MEDIA_COVERAGE_1,
        externalLink: true,
      },
    },
  ],
};
