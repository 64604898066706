/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { responseMessageInfo } from "constants/contactUs/info";
import { TranslatedResponseMessageTypeProps } from "interface/connectForm.interface";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

const responseMessageInfoProps: TranslatedResponseMessageTypeProps = mapToTranslatedProps(
  responseMessageInfo
);

export default function ResponseMessage({
  messageOpen,
  setMessageOpen,
  messageType
}): JSX.Element {
  const [messageTitle, setMessageTitle] = useState("");
  const [messageDescription, setMessageDescription] = useState("");
  const [messageBtnText, setMessageBtnText] = useState("");

  useEffect((): void => {
    if (messageType) {
      const { title, description, btnText } = responseMessageInfoProps[
        messageType
      ];
      setMessageTitle(title);
      setMessageDescription(description);
      setMessageBtnText(btnText);
    }
  }, [messageType]);

  return (
    <div className={messageOpen ? "dialog-container open" : "dialog-container"}>
      <div className={messageOpen ? "message-wrapper open" : "message-wrapper"}>
        <Card.Body>
          <h2 style={messageType === "failed" ? { color: "#ed1c24" } : {}}>
            {messageTitle}
          </h2>
          <div className="py-3">
            <Card.Text key={`description`}>{messageDescription}</Card.Text>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className={`btn btn-info font-weight-bold`}
              onClick={(): void => {
                setMessageOpen(false);
              }}
            >
              {messageBtnText}
            </button>
          </div>
        </Card.Body>
      </div>
    </div>
  );
}
