import { ConnectFormProps } from "interface/connectForm.interface";
import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";

export const connectFormEligibilityInfo: ConnectFormProps = {
  title: mapStringToDefinedMessage("Connect with FundPark"),
  subtitle: mapStringToDefinedMessage("  "),
  nameLabel: mapStringToDefinedMessage("Name"),
  nameWarning: mapStringToDefinedMessage("Please enter your name"),
  countryCodeLabel: mapStringToDefinedMessage("Country Code"),
  countryCodeWarning: mapStringToDefinedMessage(
    "Please enter your country code"
  ),
  contactNumberLabel: mapStringToDefinedMessage("Contact Number"),
  contactNumberWarning: mapStringToDefinedMessage(
    "Please enter your phone number"
  ),
  emailLabel: mapStringToDefinedMessage("Email Address"),
  emailWarning: mapStringToDefinedMessage("Please enter a correct Email"),

  companyNameLabel: mapStringToDefinedMessage("Company Name (Optional)"),
  btnText: mapStringToDefinedMessage("Submit")
};
