import "styles/simpleBanner.scss";
import React from "react";
import { TranslatedSimpleBannerProps } from "interface/banner.interface";
import { getLanguage } from "lib/language";
import { BUTTON_CLASSNAME } from "constants/language";

const SimpleBanner = ({
  btn,
  title,
  classNameText = "" // use className to determine background image
}: TranslatedSimpleBannerProps): JSX.Element => {
  const { text, href, btnType } = btn;
  const language = getLanguage();

  return (
    <section className={`simple-banner ${classNameText}`}>
      <div>
        <h3>{title}</h3>
        <a
          className={btnType && `${BUTTON_CLASSNAME[btnType][language]} btn`}
          href={`${href}${language}`}
        >
          {text}
        </a>
      </div>
    </section>
  );
};

export default SimpleBanner;
