import React, {useState, useEffect} from 'react';
import "styles/productPage.scss";
import {TranslatedPageInfoProps} from "interface/slideInfo.interface";
import Slide from 'components/Slider/slide';
// import Arrow from 'components/Slider/arrow';
import Dot from 'components/Slider/dots'

function useWindowWidth() {
  const isBrowser = (typeof window !== "undefined");

  const [width, setWidth] = useState({
    width: isBrowser ? window.innerWidth : 1280,
  });

  useEffect(() => {
    function handleWidthChange() {
      setWidth({width: window.innerWidth});
    }

    window.addEventListener('resize', handleWidthChange);
    return () => {
      window.removeEventListener('resize', handleWidthChange);
    };
  },[]);

  return width;
}



const Slider = ({slideContent}:TranslatedPageInfoProps): JSX.Element =>{
    // console.log(slideContent);
    const [state, setState] = useState({
        activeIndex : 0,
        translate : 0,
        transition : 0.45,
    });
    
    const getWidth = useWindowWidth();
    
    const{activeIndex, transition, translate} = state;

    const changeSlide = (i) => {
        // console.log('clicked '+i)
        setState({
            ...state,
            activeIndex: i,
            translate: i * getWidth.width
        })
    }

    const Dots = ({ slides, activeIndex }) => (
        <div
          style={{ 
              position: 'absolute',
              bottom: '25px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
          }}
        >
          {slides.map((slide, i) => (
            <Dot key={slide} active={activeIndex === i} handleClick={()=>changeSlide(i)}/>
          ))}
        </div>
    )

    return (
        <div className="custom-slider">
            <div className="custom-slider-content" style={{ 
                width: getWidth.width*slideContent.length , 
                transform: `translateX(-${translate}px)`,
                transition: `transform ease-out ${transition}s`
            }}>
                {slideContent.map((slide,i)=>(
                    <Slide key={i+1} content={slide} keyValue={i+1}/>
                ))}
            </div>
            {slideContent.length > 1 && 
            <Dots slides={slideContent} activeIndex={activeIndex}  />}
        </div>
    )
};

// Slider.defaultProps = {
//     slideContent : [],
//     autoPlay: null
// }

export default Slider;