import "styles/funder.scss";
import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import Banner from "components/Banner/banner";
import { TranslatedBannerProps } from "interface/banner.interface";
import { funderBannerInfo } from "constants/banner/info";

import IconGroupSection from "components/iconGroupSection/iconGroupSection";
import benefitsBlockInfo from "constants/benefitsBlock/info";
import { TranslatedIconGroupInfo } from "interface/iconGroup.interface";

import ProcedureBlockWithTitle from "components/ProcedureBlock/procedureBlockWithTitle";
import { simpleStepsInfo } from "constants/procedureBlock/info";
import { TranslatedProcedureWithTitleInfoProps } from "interface/introInfo.interface";

import LearnMoreBlock from "components/LearnMoreBlock/funderLearnMoreBlock";
import { TranslatedSeekingBlockProps } from "interface/seekingBlock.interface";
import { funderBlockInfo } from "constants/learnMoreBlock/info";

const Funder = (): JSX.Element => {
  const bannerInfoProps: TranslatedBannerProps = mapToTranslatedProps(
    funderBannerInfo
  );

  const benefitsBlockInfoProps: TranslatedIconGroupInfo = mapToTranslatedProps(
    benefitsBlockInfo
  );

  const simpleStepsInfoProps: TranslatedProcedureWithTitleInfoProps = mapToTranslatedProps(
    simpleStepsInfo
  );

  const learnMoreBlockInfoProps: TranslatedSeekingBlockProps = mapToTranslatedProps(
    funderBlockInfo
  );

  return (
    <div className="funder-wrapper">
      <Banner {...bannerInfoProps} />
      <IconGroupSection {...benefitsBlockInfoProps} />
      <ProcedureBlockWithTitle {...simpleStepsInfoProps} />
      <LearnMoreBlock {...learnMoreBlockInfoProps} />
    </div>
  );
};

export default Funder;
