import React from "react";
import ContactUsForm from "./ContactUsForm";
import ContactUsCard from "./ContactUsCard";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";
import { headOfficeInfo,  } from "constants/footer/info";//branchOfficeInfo
import { TranslatedFooterInfoProps } from "interface/footer.interface";

import "styles/contact-us.scss";

function ContactUs() {
  const headOfficeInfoProps: TranslatedFooterInfoProps = mapToTranslatedProps(
    headOfficeInfo,
  );

  // const branchOfficeInfoProps: TranslatedFooterInfoProps = mapToTranslatedProps(
  //   branchOfficeInfo,
  // );
  return (
    <div className="contact-us-wrapper">
      <div className="content-wrapper">
        <div className="card-wrapper">
          <ContactUsCard {...headOfficeInfoProps} />
          {/* <ContactUsCard {...branchOfficeInfoProps} /> */}
        </div>
        <ContactUsForm />
      </div>
    </div>
  );
}

export default ContactUs;
