import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import Banner from "components/Banner/banner";
import { newsAndEventsBannerInfo } from "constants/banner/info";
import { TranslatedBannerProps } from "interface/banner.interface";

import MediaEnquiriesBlock from "components/MediaEnquiriesBlock/mediaEnquiriesBlock";
import { mediaEnquiriesBlockInfo } from "constants/mediaEnquiriesBlock/info";
import { TranslatedMediaEnquiryInfoProps } from "interface/mediaEnquiriesBlock.interface";

import MediaCoverage from "components/MediaCoverage";
import CardsWithTitle from "components/CardsWithTitle/cardsWithTitle";

// import { newsInfo } from "constants/cardsWithTitle/news";
import { TranslatedCardsWithTitleProps } from "interface/cardsWithTitle.interface";

import Lightbox from "components/Lightbox/lightbox";
// import { eventAndUpdatesInfo } from "constants/lightbox/info";
import { TranslatedLightboxProps } from "interface/lightbox.interface";

const NewsAndEventsPage = (props): JSX.Element => {
  const { newsInfo, events } = props;
  const newsAndEventsBannerInfoProps: TranslatedBannerProps = mapToTranslatedProps(
    newsAndEventsBannerInfo,
  );

  const mediaEnquiriesBlockInfoProps: TranslatedMediaEnquiryInfoProps = mapToTranslatedProps(
    mediaEnquiriesBlockInfo,
  );

  // const pressReleaseInfoProps: TranslatedCardsWithTitleProps = mapToTranslatedProps(
  //   pressReleaseInfo
  // );
  const newsInfoProps: TranslatedCardsWithTitleProps = mapToTranslatedProps(
    newsInfo,
  );

  const eventAndUpdatesInfoProps: TranslatedLightboxProps = mapToTranslatedProps(
    events,
    // eventAndUpdatesInfo,
  );
  return (
    <>
      <Banner {...newsAndEventsBannerInfoProps} />
      {/* hidden press release till market team provide the content. */}
      {/* <CardsWithTitle {...pressReleaseInfoProps} /> */}
      <Lightbox {...eventAndUpdatesInfoProps} />
      <CardsWithTitle {...newsInfoProps} />
      <MediaCoverage />
      <MediaEnquiriesBlock {...mediaEnquiriesBlockInfoProps} />
    </>
  );
};

export default NewsAndEventsPage;
