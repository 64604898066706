// @ts-nocheck

import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import "./styles/reset.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.css";
import OnLocationUpdate from "containers/OnLocationUpdate";
import history from "utils/history";
import "./styles/App.scss";
import App from "./containers/App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.hydrate(
  <Router history={history} >
    <>
      <OnLocationUpdate />
      <App />
    </>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
