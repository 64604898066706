import React from "react";
import { TranslatedPlainIntroWithSubtitleProps } from "interface/plainIntro.interface";

export default function PlainIntroWithSubtitle({
  paragraph
}: TranslatedPlainIntroWithSubtitleProps): JSX.Element {
  return (
    <div className={`plain-intro-block-wrapper d-flex justify-content-center`}>
      <div className="plain-intro-block ">
        <section>
          {paragraph.map(
            (el, idx): JSX.Element => {
              
              const {tag = "h5", title, description } = el;
              const TitleTags = {
                h1: "h1",
                h2: "h2",
                h3: "h3",
                h4: "h4",
                h5: "h5",
              };
            
              const Title = TitleTags[tag];
              return (
                <div key={`paragraph_${idx}`}>
                  <Title>{title}</Title>
                  {Array.isArray(description) && description.map(
                    (el, idx): JSX.Element => {
                      return <p key={`description_${idx}`}>{el}</p>;
                    }
                  )}
                </div>
              );
            }
          )}
        </section>
      </div>
    </div>
  );
}
