import React from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";
import img1 from "images/partners/img1.jpg";
import "styles/pure-image.scss";
export default function PureImage(props: {
  src?: string;
  isPure?: boolean;
  alt?: string;
}): JSX.Element {
  const intl = useIntl();
  
  return (
    <div className={clsx(props.isPure ? "pure-image" : "")}>
      <img
        src={props.src || img1}
        alt={
          props.alt ||
          intl.formatMessage({
            id: "app.Trade_Financing",
            defaultMessage: "Trade Financing",
          })
        }
      />
    </div>
  );
}
