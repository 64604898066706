import React from "react";
import { TranslatedPlainIntroProps } from "interface/plainIntro.interface";
import "styles/plain-intro-block.scss";

const PlainIntroBlock = (props): JSX.Element => {
  const {
    tag = "h3",
    title,
    description,
    paragraphIsHTML = false,
    isCareer = false,
    html,
    requirements,
    responsibilities,
    afterContentChildren,
  }: TranslatedPlainIntroProps = props;

  const TitleTags = {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
  };

  const Title = TitleTags[tag];
  
  return (
    <div className={`plain-intro-block-wrapper d-flex justify-content-center`}>
      <div className="plain-intro-block ">
        <Title className="title">{title}</Title>
        {isCareer && html && (
          <div className="content">Responsibilites:<br/><br/>{responsibilities}<br/>Requirements:<br/><br/>{requirements}<br/></div>
        )}
        {!isCareer && html && (
          <div className="content" dangerouslySetInnerHTML={{ __html: String(html) }}></div>
        )}
        {!paragraphIsHTML && Array.isArray(description) &&
          (
            <section>
              {description.map(
                (paragraph: JSX.Element, idx: number): JSX.Element => (
                  <div key={`paragraph_${idx}`}>{paragraph}</div>
                ),
              )}
            </section>
          )
        }
        {afterContentChildren}
      </div>
    </div>
  );
};

export default PlainIntroBlock;
