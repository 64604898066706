import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { SOFR_RATE_URL } from "api";
import { CircularProgress } from "@material-ui/core";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function RateTable() {
  
  const handleAutoScroll = (className: string) => {
    const element = document.getElementsByClassName(className)[0];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs().subtract(85, 'day'));
  const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs().add(4, 'day'));

  const [endDateAction, setEndDateAction] = React.useState({});

  useEffect(() => {
    if (startDate && endDate && startDate > endDate) {
      setEndDate(startDate)
    }
    if (startDate && endDate && startDate > dayjs()){
      setEndDateAction({})
    } else {
      setEndDateAction({ actions: ["today"] })
    }
    setPage(0)
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[startDate, endDate])

  const onAnyTextFieldChanged = (e: any) => {
    if (!!e?.preventDefault) { 
        e?.preventDefault();
        e?.stopPropagation();
    }
}

  function StartDatePicker() {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format="YYYY/MM/DD"
          onChange={(newValue) => {
            setStartDate(newValue)
          }}
          sx={{ width: 200 }}
          value={startDate}
          minDate={dayjs().subtract(85, 'day')}
          maxDate={dayjs().add(4, 'day')}
          slotProps={{
            actionBar: { actions: ["today"] },
            textField: { 
              onBeforeInput: onAnyTextFieldChanged, 
              InputProps: { 
                size: 'small', 
                readOnly: true, 
                sx: { fontSize: 18, width: 170 }, 
              }, 
              variant: 'standard' 
            } 
         }}
        />
      </LocalizationProvider>
    );
  }

  function EndDatePicker() {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          format="YYYY/MM/DD"
          onChange={(newValue) => {
            setEndDate(newValue)
          }}
          sx={{ width: 200 }}
          value={endDate}
          minDate={startDate}
          maxDate={dayjs().add(4, 'day')}
          className="SOFRTable"
          slotProps={{
            actionBar: endDateAction,
            textField: { 
              onBeforeInput: onAnyTextFieldChanged, 
              InputProps: { 
                size: 'small', 
                readOnly: true, 
                sx: { fontSize: 18, width: 170 }, 
              }, 
              variant: 'standard' 
            } 
         }}

        />
      </LocalizationProvider>
    );
  }

  function getData() {
    setIsLoading(true);
    try {
      fetch(`${SOFR_RATE_URL}?limit=${rowsPerPage}&offset=${offset}&start_date=${startDate && startDate.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')}&end_date=${endDate && endDate.subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setDataCount(data.content.count)
          setSOFRrates(data.content.results);
          setIsLoading(false)
          return;
        });
    } catch (err) {
      setIsLoading(false);
      console.log(err, "fetch err");
      throw err;
    }
  }
  interface Provider {
    rate: number,
    record_date: string,
  }
  const [isLoading, setIsLoading] = useState(false);
  const [SOFRrates, setSOFRrates] = useState<Provider[]>([]);
  const [dataCount, setDataCount] = useState<number>(0);

  const rowsPerPage = 20;
  const [offset, setOffSet] = useState<number>(0);
  const [page, setPage] = React.useState(0);
  const [firstRender, setFirstRender] = useState(true);
  
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOffSet(rowsPerPage * (page));
    if (!firstRender){
      handleAutoScroll('SOFRTable');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);
  
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, rowsPerPage - SOFRrates.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
    setFirstRender(false);
  };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  // ) => {
  //   setRowsPerPage(parseInt(event.target.value, 20));
  //   setPage(0);
  // };

  function incr_date(date_str: string){
    const parts = date_str.split("-");
    const dt = new Date(
      parseInt(parts[0], 10),      // year
      parseInt(parts[1], 10) - 1,  // month (starts with 0)
      parseInt(parts[2], 10)       // date
    );
    dt.setDate(dt.getDate() + 1);
    parts[0] = "" + dt.getFullYear();
    parts[1] = "" + (dt.getMonth() + 1);
    if (parts[1].length < 2) {
      parts[1] = "0" + parts[1];
    }
    parts[2] = "" + dt.getDate();
    if (parts[2].length < 2) {
      parts[2] = "0" + parts[2];
    }
    return parts.join("-");
  }

  return (
    <TableContainer>

      <div className='datePickerContainer'>
        <b>Start Date:</b>
        <StartDatePicker/>
      </div>
      <div className='datePickerContainer'>
        <b>End Date:</b>
        <EndDatePicker/>
      </div>
      

      <Table sx={{ maxWidth: 450 }} aria-label="custom pagination table">
        <TableBody>
          <TableRow className='SOFRtableHeader'>
            <TableCell component="th" scope="row">
              <b>Date</b>
            </TableCell>
            {!isLoading ? (              
              <TableCell style={{ width: 160 }} align="right">
                <b>SOFR</b>
              </TableCell>
            ) : (
              <></>
            )}
          </TableRow>
          {!isLoading ? (SOFRrates.map((row) => (
            <TableRow key={row.record_date}>
              <TableCell component="th" scope="row">
                {incr_date(row.record_date)} 
              </TableCell>
              <TableCell style={{ width: 160 }} align="right">
                {row.rate.toFixed(2)}%
              </TableCell>
            </TableRow>
          ))):(        
            <div style={{ height: 53 * Math.max(1, SOFRrates.length) }} className="loadingRow">
              <CircularProgress color="inherit" size={25} />
            </div>
        )}
          {/* {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              </TableCell colSpan={6} >
            </TableRow>
          )} */}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={3}
              count={dataCount}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
