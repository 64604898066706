import React from "react";
// import { Helmet } from "react-helmet";

import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";
// import { getLanguageFromPath } from "lib/language";

import Banner from "components/Banner/banner";
import SimpleBanner from "components/Banner/simpleBanner";
import {
  invFinancingBannerInfo,
  simpleBannerInfo,
} from "constants/banner/info";
import {
  TranslatedBannerProps,
  TranslatedSimpleBannerProps,
} from "interface/banner.interface";

import PlainIntroBlock from "components/PlainIntroBlock/plainIntroBlock";
import { invFinancingPlainIntroInfo } from "constants/plainIntro/info";
import { TranslatedPlainIntroProps } from "interface/plainIntro.interface";

// import CalculatorBlock from "components/Calculator/calculatorBlock";
// import { invFinancingCalculatorInfo } from "constants/calculator/info";
// import { TranslatedCalculatorProps } from "interface/calculator.interface";

import SuitableBlock from "components/SuitableBlock/suitableBlock";
import { suitableBlockInfo } from "constants/suitableBlock/info";
import { TranslatedSimpleIntroInfoListProps } from "interface/introInfo.interface";

// import MediaCoverage from "components/MediaCoverage";

const InvoiceFinancing = (props): JSX.Element => {
  const invFinancingBannerInfoProps: TranslatedBannerProps = mapToTranslatedProps(
    invFinancingBannerInfo,
  );

  const invFinancingPlainIntroInfoProps: TranslatedPlainIntroProps = mapToTranslatedProps(
    invFinancingPlainIntroInfo,
  );

  // const invFinancingCalculatorInfoProps: TranslatedCalculatorProps = mapToTranslatedProps(
  //   invFinancingCalculatorInfo,
  // );

  const suitableBlockInfoProps: TranslatedSimpleIntroInfoListProps = mapToTranslatedProps(
    suitableBlockInfo,
  );

  const simpleBannerInfoProps: TranslatedSimpleBannerProps = mapToTranslatedProps(
    simpleBannerInfo,
  );

  return (
    <div className="our-product">
      {/* <Helmet>
        <title>{title}</title>
        <meta name="description" content={contentMapping[language]} />
      </Helmet> */}

      <Banner {...invFinancingBannerInfoProps} />
      <PlainIntroBlock {...invFinancingPlainIntroInfoProps} />
      {/* <CalculatorBlock {...invFinancingCalculatorInfoProps} /> */}
      <SuitableBlock {...suitableBlockInfoProps} />
      {/* <MediaCoverage /> */}
      <SimpleBanner {...simpleBannerInfoProps} />
    </div>
  );
};

export default InvoiceFinancing;
