import React from 'react';

const Slide = (props) => (
    
    <div className="custom-slide">
        <div className="left-part">
            <div className="case">
                
                {props.content.case}
                {props.content.withIndex && <span>
                    {`0${props.keyValue}`}
                </span>}
            </div>
            {/* {console.log(props)} */}
            <div className='title'>{props.content.title}</div>
        </div>
        <div className="right-part">
            {props.content.description.map((el,index)=>(
                <div>
                    <div  className="sub-title">
                        {el.title}
                    </div>
                    <ul>
                        {el.description.map((el,index)=>(
                            <li>{el}</li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    </div>
);

export default Slide;