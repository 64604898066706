import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import Banner from "components/Banner/banner";
import { TranslatedBannerProps } from "interface/banner.interface";
import { referrerBannerInfo } from "constants/banner/info";

import ProcedureBlock from "components/ProcedureBlock/procedureBlock";
import { referrerProcedureInfo } from "constants/procedureBlock/info";
import { TranslatedProcedureInfoProps } from "interface/introInfo.interface";

import IconGroupSection from "components/iconGroupSection/iconGroupSection";
import { clientsReferBlockInfo } from "constants/iconGroupBlock/info";
import { TranslatedIconGroupInfo } from "interface/iconGroup.interface";

import ConnectFormBlock from "components/ConnectForm/connectFormBlock";
import {connectFormInfo} from 'constants/connectForm/info';
import {TranslatedConnectFormProps} from 'interface/connectForm.interface';


const Referrer = (): JSX.Element => {
  const referrerBannerInfoProps: TranslatedBannerProps = mapToTranslatedProps(
    referrerBannerInfo
  );

  const referrerProcedureInfoProps: TranslatedProcedureInfoProps = mapToTranslatedProps(
    referrerProcedureInfo
  );

  const clientsReferBlockInfoProps: TranslatedIconGroupInfo = mapToTranslatedProps(
    clientsReferBlockInfo
  );

  const connectFormInfoProps: TranslatedConnectFormProps = mapToTranslatedProps(
    connectFormInfo
  );

  return (
    <>
      <Banner {...referrerBannerInfoProps} />
      <ProcedureBlock {...referrerProcedureInfoProps} />
      <IconGroupSection {...clientsReferBlockInfoProps} />
      <ConnectFormBlock {...connectFormInfoProps}/>
    </>
  );
};

export default Referrer;
