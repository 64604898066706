import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";
import IconGroupInfo from "interface/iconGroup.interface";

import image1 from "images/sme/img1.png";
import image2 from "images/sme/img2.png";
//import image3 from "images/sme/img3.png";
//import image4 from "images/sme/img4.png";
import image5 from "images/sme/img5.png";
import image6 from "images/sme/img6.png";

import crImg1 from "images/clients-refer/img1.png";
import crImg2 from "images/clients-refer/img2.png";
import crImg3 from "images/clients-refer/img3.png";
import crImg4 from "images/clients-refer/img4.png";
import crImg5 from "images/clients-refer/img5.png";

import elImg1 from "images/eCommerceFinance/eCom-el-hk.svg";
import elImg2 from "images/eCommerceFinance/eCom-el-coin.svg";
// import elImg3 from "images/eCommerceFinance/eCom-el-amz.svg";
import elImg4 from "images/eCommerceFinance/eCom-el-shop2.png";

export const smeInfoBlockProps: IconGroupInfo = {
  id: "benefits",
  title: mapStringToDefinedMessage("Benefits"),
  icons: [
    {
      image: image1,
      title: mapStringToDefinedMessage("No Collaterals Required")
    },
    {
      image: image2,
      title: mapStringToDefinedMessage("Approval time as soon as 30 minutes")
    },
    // {
    //   image: image3,
    //   title: mapStringToDefinedMessage("Finance Up to 90% of Invoices")
    // },
    // {
    //   image: image4,
    //   title: mapStringToDefinedMessage("Trade Credit Insurance Covered")
    // },
    {
      image: image5,
      title: mapStringToDefinedMessage("No Hidden Cost & Account Review Fee")
    },
    {
      image: image6,
      title: mapStringToDefinedMessage("Credit limit up to USD 10M")
    }
  ]
};

export const eCommerceEligibilityInfoBlockProps: IconGroupInfo = {
  id: "eligibility",
  title: mapStringToDefinedMessage("Eligibility_ecommerce"),
  icons: [
    {
      image: elImg1,
      title: mapStringToDefinedMessage("Hong Kong Limited Company")
    },
    {
      image: elImg2,
      title: mapStringToDefinedMessage("Annual turnover above USD 500K")
    },
    {
      image: elImg4,
      title: mapStringToDefinedMessage("Merchants on e-Commerce platform")
    }
  ]
};

export const eCommerceEligibilityInfoBlockImportProps: IconGroupInfo = {
  id: "eligibility",
  title: mapStringToDefinedMessage("Eligibility_ecommerce"),
  icons: [
    {
      image: elImg1,
      title: mapStringToDefinedMessage("Hong Kong Limited Company")
    },
    {
      image: elImg2,
      title: mapStringToDefinedMessage("Annual turnover above USD 500K")
    },
    {
      image: elImg4,
      title: mapStringToDefinedMessage("Merchants on e-Commerce platform")
    }
  ]
};

export const clientsReferBlockInfo: IconGroupInfo = {
  title: mapStringToDefinedMessage("What Clients to Refer?"),
  icons: [
    {
      image: crImg1,
      title: mapStringToDefinedMessage("Hong Kong Limited Company")
    },
    {
      image: crImg2,
      title: mapStringToDefinedMessage(
        "Business to Business (B2B) Trading or Servicing Company"
      )
    },
    {
      image: crImg3,
      title: mapStringToDefinedMessage(
        "Prime Buyer with Stable Financial Trace"
      )
    },
    {
      image: crImg4,
      title: mapStringToDefinedMessage("Minimum 1 Year Business Operation")
    },
    {
      image: crImg5,
      title: mapStringToDefinedMessage("Track Record with Buyer and Supplier")
    }
  ],
  classNameText: "clients-refer-block"
};
