import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";
import { SimpleIntroInfoList } from "interface/introInfo.interface";
// import ROUTES from "constants/ROUTES";

import img1 from "images/suitableBlock/img1.png";
import img2 from "images/suitableBlock/img2.png";
import img3 from "images/suitableBlock/img3.png";

export const suitableBlockInfo: SimpleIntroInfoList = {
  id: "eligibility",
  title: mapStringToDefinedMessage("Suitable for"),
  introInfoList: [
    { image: img1, title: mapStringToDefinedMessage("B2B/ B2C/ Import/ Export Companies") },
    {
      image: img2,
      title: mapStringToDefinedMessage("Companies looking for growth")
    },
    {
      image: img3,
      title: mapStringToDefinedMessage("Selling on Online eCommerce platform(s)/ Trading Companies")
    },
  ],
  // btn: {
  //   text: mapStringToDefinedMessage("Register Now"),
  //   href: ROUTES.REGISTER,
  //   externalLink: true,
  //   btnType: "signup"
  // }
};
