import React from "react";
import { useIntl } from "react-intl";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

// import Banner from "components/Banner/banner";
import PlainIntroBlock from "components/PlainIntroBlock/plainIntroBlock";

// import { newsAndEventsBannerInfo } from "constants/banner/info";
// import { TranslatedBannerProps } from "interface/banner.interface";

// import { newsNotFoundInfo } from "constants/plainIntro/news1";
import { TranslatedPlainIntroProps } from "interface/plainIntro.interface";
import BackButton from "components/BackButton/BackButton";

// import news from "constants/news-items/all.json";

const NewsContentPage = (props): JSX.Element => {
  console.log("Production: NewsContentPage");
  const { newsId } = props.match.params;
  const intl = useIntl()

  const selectedNews: TranslatedPlainIntroProps = {
    tag: "h1",
    title: {
      id: `app.news-items-${newsId}.title`,
      defaultMessage: " ",
    },
    paragraphIsHTML: true,
    html: intl.formatMessage({
      id: `app.news-items-${newsId}.content`,
      defaultMessage: " ",
    }),
  };
  const newsProps = mapToTranslatedProps(selectedNews);

  return (
    <div id="news-content" style={{ marginTop: 80 }}>
      <BackButton
        onClick={() => props.history.replace(`/${props.match.params.language}/news-and-events`)}
      />

      {/* <Banner {...newsAndEventsBannerInfoProps} /> */}
      <PlainIntroBlock {...newsProps} />
    </div>
  );
};

export default NewsContentPage;
