import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import "styles/faq.scss";
import { SOFR_RATE_URL } from "api";
import RateTable from "./rateTable";
import dayjs from 'dayjs';

export default function SOFR(): JSX.Element {
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentSOFR, setCurrentSOFR] = useState();
  const currentDateHK = moment().tz("Asia/Hong_Kong").format("DD MMMM YYYY");
  const currentTimeET = moment().tz("America/New_York").format("LT");

  useEffect(() => {
    getData();
  }, []);
  
  function getData() {
    setIsLoading(true);
    try {
      fetch(`${SOFR_RATE_URL}?start_date=${dayjs().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')}&end_date=${dayjs().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          const latestRate = data.content.results[0].rate;
          setCurrentSOFR(latestRate.toFixed(2));
          setIsLoading(false);
          return latestRate;
        });
    } catch (err) {
      setIsLoading(false);
      console.log(err, "fetch err");
      throw err;
    }
  }

  return (
    <div className="sofrWrapper">
      <div className="container">
        {/* Header */}
        <div className="headerContainer">
          <h4 className="sofrTitle">Secured Overnight Financing Rate (SOFR)</h4>
          <div className="currentRateContainer">
            <span className="currentRateTitle">
              SOFR quoted by FundPark Limited, as at {currentDateHK} HKT:
            </span>
            {isLoading ? (
              <div>
                <br/>
                <CircularProgress color="inherit" size={25} />
              </div>
            ) : (
              <span className="currentRate">{currentSOFR}%</span>
            )}

            {/* Current Rate */}
          </div>
        </div>

        {/* Historical SOFR data */}
        <div className="dataContainer">
          {/* Table */} {/* Temp unused*/}
          <div className="tableContainer">
            <b className="refSOFRTitle">Reference SOFR</b>
            <div className="dataTableContainer">
                <RateTable/>
            </div>
          </div>
          {/* Notes */}
          <div className="notesContainer">
            <span className="currentRate">
              <b className="remarks">Remark:</b>
            </span>
          </div>
          <ul className="remarks">
            <li>The rate quotes is "value today"</li>
            <li>
              This refers to the SOFR published on{" "}
              <a
                href="https://www.newyorkfed.org/markets/reference-rates/sofr"
                target="_blank"
                className="remarkWithLink"
                rel="noopener noreferrer"
              >
                New York Fed
              </a>{" "}
              website at approximately {currentTimeET} ET
            </li>
            <li>
              New York time zone is Eastern Daylight Time (EDT) UTC-4 or Eastern
              Standard Time (EST) UTC-5.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
