import "styles/funder.scss";
import "styles/productPage.scss";

import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import Banner from "components/Banner/banner";
import { TranslatedBannerProps } from "interface/banner.interface";
import { tradeAssetBasedFinanceBannerInfo } from "constants/banner/info";
import { tradeAssetBasedFinance } from "constants/plainIntro/info";
import PlainIntroWithSubtitle from "components/PlainIntroBlock/plainIntroWithSubtitle";
import { TranslatedPlainIntroWithSubtitleProps } from "interface/plainIntro.interface";
import img1 from "images/tradeAssetBasedFinance/img1-en-desktop.png";
import img2 from "images/tradeAssetBasedFinance/img1-tc-desktop.png";
import img3 from "images/tradeAssetBasedFinance/img1-en-mobile.png";
import img4 from "images/tradeAssetBasedFinance/img1-tc-mobile.png";
import PureImage from "components/PureImage/pureImage";
// import LearnMoreBlock from "components/LearnMoreBlock/learnMoreBlock";
// import { TranslatedSeekingBlockProps } from "interface/seekingBlock.interface";
// import { tabfContactUsInfo } from "constants/learnMoreBlock/info";
// import { tabfSlideInfo } from "constants/slider/info";
// import Slider from "components/Slider/slider";
// import {TranslatedPageInfoProps} from "interface/slideInfo.interface";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getLanguage } from "lib/language";

import { eCommerceEligibilityInfoBlockImportProps } from "constants/iconGroupBlock/info";
import { TranslatedIconGroupInfo } from "interface/iconGroup.interface";
import IconGroupSection from "components/iconGroupSection/iconGroupSection";

import ConnectFormBlock from "components/ConnectForm/connectFormBlock";
import {connectFormEligibilityInfo} from 'constants/connectForm/eligibility';
import {TranslatedConnectFormProps} from 'interface/connectForm.interface';

const TradeAssetBasedFinance = (): JSX.Element => {
  const bannerInfoProps: TranslatedBannerProps = mapToTranslatedProps(
    tradeAssetBasedFinanceBannerInfo
  );

  const tradeAssetBasedFinanceIntroPros: TranslatedPlainIntroWithSubtitleProps = mapToTranslatedProps(
    tradeAssetBasedFinance
  );

  // const learnMoreBlockInfoProps: TranslatedSeekingBlockProps = mapToTranslatedProps(
  //   tabfContactUsInfo
  // );

  // const slideInfoProps: TranslatedPageInfoProps = mapToTranslatedProps(
  //   tabfSlideInfo
  // );
  
  
  const matches = useMediaQuery('(min-width:576px)');
  const language = getLanguage();
  var img = "";
  if(matches){
    //mobile size
    if(language === "zh-hk"){
      img = img2;
   }else{
      img = img1;
   }
  }else{
    // desktop size
    if(language === "zh-hk"){
      img = img4;
    }else{
      img = img3;
    }
  }

  const eCommerceEligibilityInfo: TranslatedIconGroupInfo = mapToTranslatedProps(
    eCommerceEligibilityInfoBlockImportProps
  );

  const connectFormInfoProps: TranslatedConnectFormProps = mapToTranslatedProps(
    connectFormEligibilityInfo
  );

  return (
    <div className="trade-asset-based-finance-wrapper">
      <Banner {...bannerInfoProps} />
      <PlainIntroWithSubtitle {...tradeAssetBasedFinanceIntroPros} />
      <PureImage isPure={true} src={img}/>
      {/* <Slider {...slideInfoProps}/> */}
      <IconGroupSection {...eCommerceEligibilityInfo} />
      <ConnectFormBlock {...connectFormInfoProps}/>
      {/* <LearnMoreBlock {...learnMoreBlockInfoProps} /> */}
    </div>
  );
};

export default TradeAssetBasedFinance;
