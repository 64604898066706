import React from "react";
import { newsInfo } from "constants/cardsWithTitle/news";
import { eventAndUpdatesInfo } from "constants/lightbox/info";

import NewsAndEventsPage from "./NewsAndEventsPage";

const NewsAndEvents = (): JSX.Element => {
  return <NewsAndEventsPage newsInfo={newsInfo} events={eventAndUpdatesInfo} />;
};

export default NewsAndEvents;
