// import React from "react";
import { CardListProps } from "interface/card.interface";
import { CardsWithTitleProps } from "interface/cardsWithTitle.interface";
import {
  mapStringToDefinedMessage,
  // mapStringToFormattedStringProps,
} from "lib/translate/mapStringToDefinedMessage";
// import img1 from "images/iconsForCard/img1.png";
import img1 from "images/eCommerceFinance/card-logo-1.svg";
import img2 from "images/eCommerceFinance/card-logo-2.svg";
import img3 from "images/eCommerceFinance/card-logo-3.svg";
import img4 from "images/eCommerceFinance/card-logo-4.svg";
// import img5 from "images/iconsForCard/img5.svg";


import ROUTES from "constants/ROUTES";

export const cardListInfo: CardListProps = {
  cards: [
    {
      image: img1,
      title: mapStringToDefinedMessage("No collateral"),
      className: "element-card",
      description: mapStringToDefinedMessage("Data driven assessment, no collateral needed",),
      btn: {
        display: false,
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.INVOICE_FINANCING,
        externalLink: false,
      },
    },
    {
      image: img2,
      title: mapStringToDefinedMessage("Quick approval"),
      className: "element-card",
      description: mapStringToDefinedMessage("Apply now, approval within 2-week"),
      btn: {
        display: false,
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.PURCHASE_ORDER,
        externalLink: false,
      },
    },
    {
      image: img3,
      className: "element-card",
      title: mapStringToDefinedMessage("Reasonable cost financing"),
      description: mapStringToDefinedMessage("Between 8% - 12% APR (subjected to credit assessment)"),
      btn: {
        display: false,
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.PURCHASE_ORDER,
        externalLink: false,
      },
    },
    {
      image: img4,
      title: mapStringToDefinedMessage("Loan tenor up to 90 days"),
      className: "element-card",
      description: mapStringToDefinedMessage("Full repayment due on day 90, utilising the entire tenor (subjected to credit assessment)"),
      btn: {
        display: false,
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.PURCHASE_ORDER,
        externalLink: false,
      },
    },
  ],
};

export const keyFeatureInfo: CardsWithTitleProps = Object.assign(
  { ...cardListInfo },
  { title: mapStringToDefinedMessage("Key Features") },
);
