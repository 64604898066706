// @ts-nocheck
import React from 'react';
// import { getLanguage } from "lib/language";
import ROUTES from "constants/ROUTES";
// import { BUTTON_CLASSNAME } from "constants/language";
import { ExternalLinkProps } from "interface/linkProps.interface";

//Translate
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";
import mapStringToDefinedMessage, { mapStringToFormattedStringProps } from "lib/translate/mapStringToDefinedMessage";

const LookingForFunding = () : JSX.Element => {
    // const language = getLanguage();
    // const lang = language == 'en' ? 'en' : 'zh-hk'
    const registerButton = mapToTranslatedProps({
        btn: {
            text: mapStringToDefinedMessage("Register Now"),
            href: `${ROUTES.BUSINESS_PATH}?ref=faq`,
            externalLink: true,
            // btnType: "signup",
        }
    });
    const lookingForWordings = mapToTranslatedProps({
        title: [
            mapStringToFormattedStringProps({
              defaultMessage: "Looking_for_funding_to_grow_your_business?"
            }),
          ],
        description: [
            mapStringToFormattedStringProps({
                defaultMessage: "FundPark provides working capital funding solutions to accelerate your growth."
        }),
        ],
    });

    const { btn } = registerButton;
    const { title, description } = lookingForWordings;

    const renderBtn = ({
        text,
        href,
        btnType
      }: ExternalLinkProps): JSX.Element => {
        // console.log(text, 'text')
        return (
          <a
            className="btn btn-blue"
            href={ROUTES.REGISTER}
          >
            {text}
          </a>
        );
      };


    return (
        <>
        <div className='lookingForFundingContainer'>
            <span className='lookingForFundingWordings'>
                {title}
            </span>
            <span className='lookingDescription'>
                {description}
            </span>
            <div className='buttonContainer'>
                {renderBtn(btn)}
            </div>
        </div>
        </>
    );
}

export default LookingForFunding;