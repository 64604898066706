import React from "react";
import { socialMediaTextInfo } from "constants/footer/info";

const SocialMediaNav = (): JSX.Element => {
  return (
    <div className="row social-media">
      <div className="d-flex">
        {socialMediaTextInfo.map(
          ({ image, href }, idx: number): JSX.Element => {
            return (
              <div key={`key_${idx}`} className="social-media-icon">
                <a href={href}>
                  <img alt={`image_${idx}`} src={image} />
                </a>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default SocialMediaNav;
