import { SeekingBlockProps } from "interface/seekingBlock.interface";
import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";
import ROUTES from "constants/ROUTES";

export const funderBlockInfo : SeekingBlockProps = {
  title: mapStringToDefinedMessage("Learn more about being a FundPark Funder"),
  description: mapStringToDefinedMessage(
    "Explore our alternative investment opportunities"
  ),
  btns: [
    {
      text: mapStringToDefinedMessage("Register Now"),
      href: ROUTES.REGISTER,
      externalLink: true,
      btnType: "signup"
    }
  ]
};

export const scfContactUsInfo: SeekingBlockProps = {
  title: mapStringToDefinedMessage("Learn more about being a FundPark Funder"),
  description: mapStringToDefinedMessage(
    "Please contact us at"
  ),
  btns: [
    {
      text: mapStringToDefinedMessage("scf@fundpark.com"),
      href: 'mailto:scf@fundpark.com',
      externalLink: true,
      btnType: "signup"
    }
  ]
};

export const tabfContactUsInfo: SeekingBlockProps = {
  title: mapStringToDefinedMessage("Learn more about being a FundPark Funder"),
  description: mapStringToDefinedMessage(
    "Please contact us at"
  ),
  btns: [
    {
      text: mapStringToDefinedMessage("if@fundpark.com"),
      href: 'mailto:if@fundpark.com',
      externalLink: true,
      btnType: "signup"
    }
  ]
};

export const eCommerceContactUsInfo: SeekingBlockProps = {
  title: mapStringToDefinedMessage("Learn more about being a FundPark Funder"),
  description: mapStringToDefinedMessage(
    "Please contact us at"
  ),
  btns: [
    {
      text: mapStringToDefinedMessage("wairongbao@fundpark.com"),
      href: 'mailto:wairongbao@fundpark.com',
      externalLink: true,
      btnType: "signup"
    }
  ]
};
