// @ts-nocheck

import React from "react";
import { Card } from "react-bootstrap";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";
import { faqCardInfo } from "constants/faq/info";
import { BUTTON_CLASSNAME } from "constants/language";
import { getLanguage } from "lib/language";
import { Link } from "react-router-dom";

export default function FAQCard(): JSX.Element {
  const faqCard = mapToTranslatedProps(faqCardInfo);
  const { title, descriptions, btn } = faqCard;
  const { text, href, btnType } = btn;
  const language = getLanguage();

  return (
    <Card>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <div className="py-3">
          {descriptions.map(
            (description, idx): JSX.Element => {
              return (
                <Card.Text key={`description_${idx}`}>{description}</Card.Text>
              );
            }
          )}
        </div>
        <div className="d-flex justify-content-center">
          <Link
            to={`/${language}${href}`}
            className={
              btnType &&
              `${BUTTON_CLASSNAME[btnType][language]} btn btn-info font-weight-bold py-3`
            }
          >
            {text}
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
}
