import React from "react";
import CardTemplate from "./cardTemplate";
import {
  TranslatedCardProps,
  TranslatedCardListProps
} from "interface/card.interface";

const Cards = ({ cards }: TranslatedCardListProps): JSX.Element => {
  return (
    <div className="cards-row d-flex align-items-center justify-content-center w-100">
      <div className="cards-container">
        {Array.isArray(cards) && cards.map(
          (card: TranslatedCardProps, index: number): JSX.Element => {
            return <CardTemplate key={`card_with_icon_${index}`} {...card} />;
          }
        )}
      </div>
    </div>
  );
};

export default Cards;
