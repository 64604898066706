import { Grid } from '@material-ui/core'
import { MediaObjectProps } from 'interface/mediaObject.interface'
import React from 'react'

function MediaObject(props: MediaObjectProps) {
  return (
    <Grid container className={`media-object ${props.className}`}>
      <Grid item {...props.leftGridProps}>
        {props.left}
      </Grid>
      <Grid item {...props.rightGridProps}>
        {props.right}
      </Grid>
    </Grid>
  )
}

export default MediaObject;
