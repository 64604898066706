import React from "react";
import {
  TranslatedIconGroupInfo,
  TranslatedIconData
} from "interface/iconGroup.interface";
import "styles/icon-group.scss";

const IconGroupSection = (props: TranslatedIconGroupInfo): JSX.Element => {
  const { id, title, description, icons, classNameText } = props;
  const d = {id}.id;
  //console.log(d);
  if (d === "eligibility"){
    return (
      <div
        id={id}
        className={
          classNameText
            ? `icon-group-wrapper ${classNameText}`
            : "icon-group-wrapper"
        }
      >
        <h4>{title}</h4>
        {description && <p>{description}</p>}
        <div className="icon-items-wrapper">
          {icons.map(
            (
              { title, image, description }: TranslatedIconData,
              idx: number
            ): JSX.Element => {
              return (
                <div className="icon-item" key={`icon_data_${idx}`}>
                  <div>
                    <img alt={`image_${idx}`} src={image} />
                  </div>
                  {title}
                  {description && (
                    <span className="icon-item-description">{description}</span>
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>
  
    );
  }else if(d === "benefits"){
    return (
      <div
        id={id}
        className={
          classNameText
            ? `icon-group-wrapper ${classNameText}`
            : "icon-group-wrapper"
        }
      >
        <h4>{title}</h4>
        {description && <p>{description}</p>}
        <div className="icon-items-wrapper">
          {icons.map(
            (
              { title, image, description }: TranslatedIconData,
              idx: number
            ): JSX.Element => {
              return (
                <div className="four-icon-item" key={`icon_data_${idx}`}>
                  <div>
                    <img alt={`image_${idx}`} src={image} />
                  </div>
                  <span className="icon-position">
                    {title}
                  </span>
                  {description && (
                    <span className="icon-item-description">{description}</span>
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>
    );
  }else{
    return (
      <div
        id={id}
        className={
          classNameText
            ? `icon-group-wrapper ${classNameText}`
            : "icon-group-wrapper"
        }
      >
        <h2>{title}</h2>
        {description && <p>{description}</p>}
        <div className="icon-items-wrapper">
          {icons.map(
            (
              { title, image, description }: TranslatedIconData,
              idx: number
            ): JSX.Element => {
              return (
                <div className="icon-item" key={`icon_data_${idx}`}>
                  <div>
                    <img alt={`image_${idx}`} src={image} />
                  </div>
                  {title}
                  {description && (
                    <span className="icon-item-description">{description}</span>
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>
  
    );
  }
};

export default IconGroupSection;
