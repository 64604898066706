import React from "react";
import { 
    TranslatedBubbleIntroBlockProps,
    TranslatedStaffIntroFormProps,
    // TranslatedStaffDescriptionProps,
} from "interface/bubbleIntroInfo.interface";

import "styles/bubble-block.scss";
import BubbleItem from "./BubbleItem";

const BubbleIntroBlock = (
    {
        block_title,
        block_description,
        items
    }:TranslatedBubbleIntroBlockProps
):JSX.Element => {

    const bubbleItemList = items.map(
        (item: TranslatedStaffIntroFormProps, index:number):JSX.Element => {
            return <BubbleItem key={`bubble_block_${index}`} {...item} />;
        }
    )

    return (
        <div>
            <h3>{block_title}</h3>
            <div>
                <div className="ourTeamTitleContainer">
                    <b className="ourTeamTitle">{block_description}</b>
                </div>
                <div className={`bubble-block-wrapper d-flex flex-column align-items-center`}>
                    <div className="bubble-item-blocks-wrapper d-flex flex-wrap ">
                        {bubbleItemList}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BubbleIntroBlock;