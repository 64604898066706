import React from "react";
import { Form } from "react-bootstrap";
import { injectIntl } from "react-intl";

function FormGroup(props) {
  const {
    controlId,
    type,
    as,
    label,
    placeholderString,
    warning,
    getCurrentValue,
    intl,
  } = props;
  const { textValue, changeHandler } = getCurrentValue;
  const reg = / /gi;
  const placeholderId = "app." + placeholderString.replace(reg, "_");

  return (
    <Form.Group controlId={controlId}>
      <div className="d-flex justify-content-between flex-wrap">
        <Form.Label>{label}</Form.Label>
        <Form.Control
          required
          type={type}
          as={as}
          onChange={changeHandler}
          value={textValue}
          placeholder={intl.formatMessage({ id: placeholderId })}
        />
        <Form.Control.Feedback type="invalid">{warning}</Form.Control.Feedback>
      </div>
    </Form.Group>
  );
}

export default injectIntl(FormGroup);
