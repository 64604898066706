import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import Banner from "components/Banner/banner";
import {
  TranslatedBannerProps,
} from "interface/banner.interface";

import "styles/core-value.scss";
import { coreValueBannerInfo } from "constants/banner/info";
import IntroductionBlock from "./IntroductionBlock";
import { introductionBlockInfo } from "constants/introductionBlock/info";
import { TranslatedIntroInfoProps } from "interface/introInfo.interface";

import { TranslatedCultureBlockProps } from "interface/cultureBlocks.interface";
import { cultureBlocksInfo } from "constants/cultureBlocks/info";
import CultureBlocks from "./CultureBlocks";
import ROUTES from "constants/ROUTES";
import BackButton from "components/BackButton/BackButton";

// eslint-disable-next-line @typescript-eslint/no-explicit-any

const CoreValue = (props): JSX.Element => {
  const coreValueBannerProps: TranslatedBannerProps = mapToTranslatedProps(coreValueBannerInfo);
  const introductionBlockProps: TranslatedIntroInfoProps = mapToTranslatedProps(introductionBlockInfo);
  const cultureBlockProps: TranslatedCultureBlockProps = mapToTranslatedProps(cultureBlocksInfo);

  return (
    <div className="core-value">
      <BackButton
        onClick={() => props.history.replace(`/${props.match.params.language}${ROUTES.CAREER}`)}
      />
      <Banner {...coreValueBannerProps} />
      <IntroductionBlock {...introductionBlockProps} />
      <CultureBlocks {...cultureBlockProps} />
    </div>
  );
};

export default CoreValue;
