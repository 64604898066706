import { useState } from "react";
import { GetCurrentLanguageProps } from "interface/navbar.interface";

export const useCurrentLanguage = (
  initialLanguage: string
): GetCurrentLanguageProps => {
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);
  const selectLanguage = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedLanguage = e.currentTarget.value;
    setCurrentLanguage(selectedLanguage);
  };
  return { currentLanguage, selectLanguage };
};
