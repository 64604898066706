import React from "react";
import { defineMessage, FormattedMessage, MessageDescriptor } from "react-intl";
import TranslatedProps from "interface/translatedProps.interface";
import DefinedMessage from "interface/defineMessage.interface";

function isDefinedMessage(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: DefinedMessage | any
): value is DefinedMessage {
  return value && typeof value.id === "string";
}

function mapToFormattedMessage(
  message: MessageDescriptor,
  key: string
): JSX.Element {
  defineMessage(message);
  return <FormattedMessage key={`message_${key}`} {...message} />;
}
/**
 * can map normal props to react-intl FormattedMessage, when a value is a DefinedMessage Type
 * that value will be map into an <FormattedMessage {...value} /> component, and its value
 * will be spread into that <FormattedMessage /> component
 * example: props like {title: {id: 'app.id', defaultMessage: 'hi'}, name: 'abc'} will be map into
 * {title: <FormattedMessage id={'app.id}, defaultMessage={'hi'}} />, name: 'abc'}
 * also support deeper level transition
 * @param props title: {id: 'app.id', defaultMessage: 'hi'}, name: 'abc'
 * @return {title: <FormattedMessage id={'app.id}, defaultMessage={'hi'}} />, name: 'abc'}
 */

export default function mapToTranslatedProps<T>(props: T): TranslatedProps<T> {
  const translatedProps = JSON.parse(JSON.stringify(props));

  for (const key in translatedProps) {
    const testingObject = props[key];
    if (isDefinedMessage(testingObject)) {
      translatedProps[key] = mapToFormattedMessage(testingObject, key);
    } else if (Array.isArray(testingObject)) {
      for (const idx in testingObject) {
        if (isDefinedMessage(testingObject[idx])) {
          translatedProps[key][idx] = mapToFormattedMessage(
            testingObject[idx],
            idx
          );
        } else {
          translatedProps[key][idx] = mapToTranslatedProps(
            testingObject[idx]
          );
        }
      }
    } else if (typeof testingObject === "object") {
      translatedProps[key] = mapToTranslatedProps(translatedProps[key]);
    }
  }

  return translatedProps;
}
