import { LightboxProps } from "interface/lightbox.interface";
import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";
import moment from "moment";
// import img1 from "images/event-update/img1.jpg";
// import img2 from "images/event-update/img2.jpg";
// import img3 from "images/event-update/img3.jpg";
// import img4 from "images/event-update/img4.jpg";
// import img5 from "images/event-update/img5.jpg";
// import img6 from "images/event-update/img6.jpg";

// import FinTech_Awards_2019 from "images/event-update/FinTech_Awards_2019.jpeg";

import events from "constants/strapi/corporate-events.json";

export const eventAndUpdatesInfo: LightboxProps = {
  title: mapStringToDefinedMessage("Event Update"),
  content: events.map((event) => {
    const id: string = `corporate-events-${event.id}.event_name`;

    const legend = mapStringToDefinedMessage(id);

    return {
      image: event.event_image.url,
      legend,
      date: moment(event.event_date).format("DD-MM-YYYY"),
    };
  }),
  // content: [
  //   {
  //     image: FinTech_Awards_2019,
  //     legend: mapStringToDefinedMessage("FinTech Awards 2019"),
  //     date: "17-01-2020"
  //   },
  //   {
  //     image: img1,
  //     legend: mapStringToDefinedMessage("Corporate Treasurer Hong Kong 2019"),
  //     date: "13-11-2019"
  //   },
  //   {
  //     image: img2,
  //     legend: mapStringToDefinedMessage("2019 Hong Kong FinTech Week"),
  //     date: "07-11-2019"
  //   },
  //   {
  //     image: img3,
  //     legend: mapStringToDefinedMessage(
  //       "Seminar: SME Financing and Risk Management to Help Companies Expand Overseas Markets"
  //     ),
  //     date: "26-04-2019"
  //   },
  //   {
  //     image: img4,
  //     legend: mapStringToDefinedMessage(
  //       "Greater Bay Area Microsoft Technology Innovation Summit"
  //     ),
  //     date: "06-03-2019"
  //   },
  //   {
  //     image: img5,
  //     legend: mapStringToDefinedMessage("FinTech Awards 2018"),
  //     date: "06-03-2019"
  //   },
  //   {
  //     image: img6,
  //     legend: mapStringToDefinedMessage(
  //       "Seminar: Expanding your Business through Trade Financing"
  //     ),
  //     date: "09-01-2018"
  //   }
  // ]
};
