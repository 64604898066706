// @ts-nocheck
/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from "react";
import { IntlProvider } from "react-intl";
import registerLanguageLocale from "lib/translate/registerLanguageLocale";
import { Route, Switch, withRouter } from "react-router-dom";

import { useCurrentLanguage } from "components/NavBar/language.custom.hook";
import { LANGUAGE } from "constants/language";
import ROUTES from "constants/ROUTES";
import { titleMapping } from "constants/title";

import { RouteComponentProps } from "react-router";
import { getLanguage, getLanguageFromPath, getPathnameWithoutLanguage } from "lib/language";
import { ValidRouteComponent, NotFound } from "containers/ValidRoute";
import { rmCodeHandler } from "utils/helpers";

import HTMLHeader from "components/HTMLHeader";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const App: React.FC<RouteComponentProps<any>> = (props): JSX.Element => {

  // enum: en, zh-hk
  const initialLanguage =
    getLanguageFromPath(props.location.pathname) || getLanguage();
  // const languageFromPath = getLanguageFromPath();

  const languageMessages = registerLanguageLocale();

  // zh-hk -> zh
  const getCurrentLanguage = useCurrentLanguage(initialLanguage.slice(0, 2));
  const { currentLanguage }: { currentLanguage: string } = getCurrentLanguage;
  const isFirstRender = useRef(true);
  // console.log(getCurrentLanguage);
  // console.log(`currentLanguage: ${currentLanguage}`);

  useEffect((): void => {
    const { pathname } = props.location;

    if (typeof window !== "undefined") {
      localStorage.setItem(LANGUAGE, currentLanguage);
      if (currentLanguage === "zh") {
        localStorage.setItem(LANGUAGE, `${currentLanguage}-hk`);
      }
    }

    // Prevent push same page to history on refreshing
    if(isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // pathname.lastIndexOf("/") is 0 in home page
    // the route will be `/en/en` if switch language in home page
    if (pathname.lastIndexOf("/")) {
      props.history.push({
        pathname: `/${getLanguage()}/${pathname.split('/').slice(2).join('/')}`,
        search: props.location.search,
        hash: props.location.hash,
      });
    } else {
      props.history.push({
        pathname: `/${getLanguage()}`,
        search: props.location.search,
        hash: props.location.hash,
      });
    }

    // handle RM code
    rmCodeHandler();

    // eslint-disable-next-line
  }, [currentLanguage]);

  let href: string = process.env.REACT_APP_WEBSITE_HOST + props.location.pathname;;
  let pathname: string = getPathnameWithoutLanguage(props.location.pathname);

  return (
    <IntlProvider
      locale={currentLanguage}
      messages={languageMessages[`${currentLanguage}`]}
    >
      <>
        <HTMLHeader
          title={titleMapping[initialLanguage]}
          href={href}
          pathname={pathname}
          language={initialLanguage}
        />
        <Switch>
          <Route
            exact
            path={[
              ROUTES.INDEX,
              ROUTES.INDEX_PATH,
              ROUTES.BUSINESS_PATH,
              ROUTES.FUNDER_PATH,
              ROUTES.REFERRER_PATH,
              ROUTES.INVOICE_FINANCING_PATH,
              ROUTES.PURCHASE_ORDER_PATH,
              ROUTES.ABOUT_US_PATH,
              ROUTES.NEWS_AND_EVENTS_PATH,
              ROUTES.FAQ_PATH,
              ROUTES.CONTACT_US_PATH,
              ROUTES.TERMS_AND_CONDITIONS_PATH,
              ROUTES.PRIVACY_POLICY_PATH,
              ROUTES.PERSONAL_PRIVACY_POLICY_PATH,
              // ROUTES.DATA_POLICY_PATH,
              ROUTES.NEWS_CONTENT_PATH,
              ROUTES.CAREER_DETAILS_PATH,
              ROUTES.CAREER_PATH,
              ROUTES.CORE_VALUE_PATH,
              ROUTES.SUPPLY_CHAIN_FINANCE_PATH,
              ROUTES.TRADE_ASSET_BASED_FINANCE_PATH,
              ROUTES.ECOMMERCE_FINANCE_PATH,
              ROUTES.SOFR,
              ROUTES.WALMART,
            ]}
            component={(): JSX.Element => {
              return (
                <ValidRouteComponent getCurrentLanguage={getCurrentLanguage} initialLanguage={initialLanguage} />
              );
            }}
          />
          <Route component={NotFound} />
        </Switch>
      </>
    </IntlProvider>
  );
};

export default withRouter(App);
