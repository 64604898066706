/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { TranslatedConnectFormProps } from "interface/connectForm.interface";
import { REFERRER_URL } from "api";
import ResponseMessage from "components/ContactUs/responseMessage";
import "styles/contact-us.scss";

const ConnectForm = ({
  nameLabel,
  nameWarning,
  countryCodeLabel,
  countryCodeWarning,
  contactNumberLabel,
  contactNumberWarning,
  emailLabel,
  emailWarning,
  companyNameLabel,
  btnText
}: TranslatedConnectFormProps): JSX.Element => {
  const [name, setName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [validated, setValidated] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [messageType, setMessageType] = useState("");

  useEffect((): void => {
    if (messageType === "success") {
      setName("");
      setCountryCode("");
      setContactNumber("");
      setEmail("");
      setCompanyName("");
      setValidated(false);
    }
  }, [messageType]);

  const handleNameChange = (e): void => {
    setName(e.currentTarget.value);
  };

  const handleCountryCodeChange = (e): void => {
    setCountryCode(e.currentTarget.value);
  };

  const handleContactNumberChange = (e): void => {
    setContactNumber(e.currentTarget.value);
  };

  const handleEmailChange = (e): void => {
    setEmail(e.currentTarget.value);
  };

  const handleCompanyNameChange = (e): void => {
    setCompanyName(e.currentTarget.value);
  };

  const handleSubmit = async (e): Promise<void> => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity()) {
      e.stopPropagation();
    }
    setValidated(true);

    const data = {
      title: "Referrers",
      content: {
        Name: name,
        Contact_number: countryCode + contactNumber,
        Email: email,
        Company_name: companyName
      }
    };

    if (form.checkValidity()) {
      try {
        const response = await fetch(REFERRER_URL, {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json"
          }
        });
        const { content } = await response.json();
        if (content === "success") {
          setMessageOpen(true);
          setMessageType(content);
          console.log("success:", content);
        }
      } catch (error) {
        setMessageOpen(true);
        setMessageType("failed");
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="d-flex flex-column"
      >
        <Form.Group controlId="name">
          <Form.Label>{nameLabel}</Form.Label>
          <Form.Control
            required
            type="text"
            as="input"
            onChange={handleNameChange}
            value={name}
          />
          <Form.Control.Feedback type="invalid">
            {nameWarning}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="phone-number d-flex justify-content-between">
          <Form.Group controlId="countryCode" className="country-code">
            <Form.Label>{countryCodeLabel}</Form.Label>
            <Form.Control
              required
              type="text"
              as="input"
              onChange={handleCountryCodeChange}
              value={countryCode}
            />
            <Form.Control.Feedback type="invalid">
              {countryCodeWarning}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="contactNumber" className="contact-number">
            <Form.Label>{contactNumberLabel}</Form.Label>
            <Form.Control
              required
              type="number"
              as="input"
              onChange={handleContactNumberChange}
              value={contactNumber}
            />
            <Form.Control.Feedback type="invalid">
              {contactNumberWarning}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <Form.Group controlId="email">
          <Form.Label>{emailLabel}</Form.Label>
          <Form.Control
            required
            type="email"
            as="input"
            onChange={handleEmailChange}
            value={email}
          />
          <Form.Control.Feedback type="invalid">
            {emailWarning}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="companyName">
          <Form.Label>{companyNameLabel}</Form.Label>
          <Form.Control
            type="text"
            as="input"
            onChange={handleCompanyNameChange}
          />
        </Form.Group>
        <Button type="submit" className="btn-dark">
          {btnText}
        </Button>
      </Form>
      <ResponseMessage
        messageOpen={messageOpen}
        setMessageOpen={setMessageOpen}
        messageType={messageType}
      />
    </>
  );
};

export default ConnectForm;
