// @ts-nocheck
import React from 'react';
import procedureImgWeb from "images/procedure/procedure.jpg";
import procedureImgMobile from "images/procedure/procedure-mobile.jpg";
import procedureImgWeb_cn from "images/procedure/procedure_cn.jpg";
import procedureImgMobile_cn from "images/procedure/procedure-mobile_cn.jpg";
import { getLanguage } from "lib/language";



const Procedure = () : JSX.Element => {
    const language = getLanguage();
    const webPicture = language === 'en' ? procedureImgWeb : procedureImgWeb_cn;
    const mobilePicture = language === 'en' ? procedureImgMobile : procedureImgMobile_cn;

    return (
        <>
            <div className='procedureContainer'>
                <img src={webPicture} className="procedureImg-web" alt="procedureImg-web"/>
                <img src={mobilePicture} className="procedureImg-mobile" alt="procedureImg-mobile"/>                
            </div> 
        </>

    );

}

export default Procedure;