import { ContactUsFormProps } from "interface/connectForm.interface";
import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";

export const contactUsFormInfo: ContactUsFormProps = {
  title: mapStringToDefinedMessage("Leave us a message"),
  inputFields: [
    {
      controlId: "name",
      type: "text",
      as: "input",
      label: mapStringToDefinedMessage("Name:"),
      placeholder: mapStringToDefinedMessage("Enter your name"),
      placeholderString: "Enter your name",
      warning: mapStringToDefinedMessage("Please enter your name"),
    },
    {
      controlId: "phoneNumber",
      type: "tel",
      as: "input",
      label: mapStringToDefinedMessage("Contact No.:"),
      placeholder: mapStringToDefinedMessage(
        "Include your country code eg. +852 1234 5678"
      ),
      placeholderString: "Include your country code eg. +852 1234 5678",
      warning: mapStringToDefinedMessage("Please enter your phone number"),
    },
    {
      controlId: "email",
      type: "email",
      as: "input",
      label: mapStringToDefinedMessage("Email:"),
      placeholder: mapStringToDefinedMessage("Enter your email"),
      placeholderString: "Enter your email",
      warning: mapStringToDefinedMessage("Please enter a correct Email"),
    },
    {
      controlId: "message",
      type: "text",
      as: "textarea",
      label: mapStringToDefinedMessage("Message:"),
      placeholder: mapStringToDefinedMessage("Enter your message..."),
      placeholderString: "Enter your message...",
      warning: mapStringToDefinedMessage("Please enter your message"),
    },
  ],
  btnText: mapStringToDefinedMessage("Submit"),
};

export const responseMessageInfo = {
  success: {
    title: mapStringToDefinedMessage("Submitted"),
    description: mapStringToDefinedMessage(
      "Thank you for reaching out to FundPark! Your details have been submitted successfully. Our FundPark representatives will get back to you within 24 hours."
    ),
    btnText: mapStringToDefinedMessage("OK")
  },
  failed: {
    title: mapStringToDefinedMessage("Error Occurred"),
    description: mapStringToDefinedMessage(
      "There was an error in submitting your details. Please click ‘Try again’, resubmit your contact information again so that our representatives can answer to your queries.",
    ),
    btnText: mapStringToDefinedMessage("Try again")
  }
};
