// @ts-nocheck

import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import {
  NavItemProps,
  NavBtnProps,
  TranslatedNavItemListProps,
  GetCurrentLanguageProps
} from "interface/navbar.interface";
import { ExternalLinkProps } from "interface/linkProps.interface";
import { navItemsList } from "constants/navbar/info";
import mapToTransltedProps from "lib/translate/mapToTranslatedProps";
import logo from "images/navbar/logo.png";
import { getLanguage } from "lib/language";
import { NavLink, GeneralLink } from "components/Link";
import { BUTTON_CLASSNAME } from "constants/language";
import { pushDataLayer } from "utils/helpers";
import "styles/navBar.scss";

const NavBar = ({
  currentLanguage,
  selectLanguage
}: GetCurrentLanguageProps): JSX.Element => {
  const TranslatedNavItemsList: TranslatedNavItemListProps = mapToTransltedProps(
    navItemsList
  );
  const { navItemList, navBtnList } = TranslatedNavItemsList;
  const [navExpanded, setNavExpanded] = useState(false);
  const language = getLanguage();

  const navItems = navItemList.map(
    ({ link, dropdownItems }: NavItemProps, idx: number): JSX.Element => {
      const { text, href, dataLayer } = link;

      if (dropdownItems) {
        return (
          <NavDropdown
            title={text}
            id="collasible-nav-dropdown"
            key={`dropdown_menu_${idx}`}
            className={`dropdown-menu-${idx}`}
          >
            {dropdownItems.map(
              (
                {
                  text,
                  href,
                  externalLink,
                  btnType,
                  dataLayer
                }: ExternalLinkProps,
                idx: number,
              ): JSX.Element => {
                return (
                  <NavDropdown.Item
                    key={`dropdown_item_${idx}`}
                    active={false}
                    as="div"
                    className="nav-link"
                  >
                    {externalLink ? (
                      <GeneralLink
                        href={href}
                        className={
                          btnType && `${BUTTON_CLASSNAME[btnType][language]}`
                        }
                        dataLayer={dataLayer}
                      >
                        {text}
                      </GeneralLink>
                    ) : (
                      <NavLink
                        to={href}
                        activeStyle={{
                          color: "#64ccc9",
                        }}
                        onClick={(): void => {
                          setNavExpanded(false);
                        }}
                        dataLayer={dataLayer}
                      >
                        {text}
                      </NavLink>
                    )}
                  </NavDropdown.Item>
                );
              }
            )}
          </NavDropdown>
        );
      }

      return (
        <NavLink
          key={`nav_item_${idx}`}
          to={href}
          className="nav-link"
          aria-current="true"
          activeStyle={{
            color: "#64ccc9"
          }}
          onClick={(): void => {
            setNavExpanded(false);
          }}
          dataLayer={dataLayer}
        >
          {text}
        </NavLink>
      );
    }
  );

  const navBtns = navBtnList.map(
    ({ btn, className }: NavBtnProps, idx: number): JSX.Element => {
      const { text, href, btnType } = btn;

      return (
        <React.Fragment key={`nav_btn_${idx}`}>
          <Nav.Link
            key={`nav_btn_${idx}`}
            className={
              btnType
                ? `${BUTTON_CLASSNAME[btnType][language]} ${className}`
                : className
            }
            href={`${href}${language}`}
          >
            {text}
          </Nav.Link>
        </React.Fragment>
      );
    }
  );

  return (
    <div>
      <Navbar
        expand="lg"
        expanded={navExpanded}
        onToggle={(): void => {
          setNavExpanded(!navExpanded);
        }}
      >
        <Navbar.Brand className="ml-6">
          <NavLink>
            <img className="logo" src={logo} width="173" alt="FundPark" />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav.Item key={`nav_item_select`}>
            <div className="select-wrapper">
              <select
                className="language"
                onChange={(e): void => {
                  selectLanguage(e);
                  setNavExpanded(false);
                  pushDataLayer({ label: "Language" });
                }}
                value={currentLanguage}
                onSelect={(): void => {
                  setNavExpanded(false);
                }}
              >
                <option value="en">English</option>
                <option value="zh">中文</option>
              </select>
              <div className="select-arrow" />
            </div>
          </Nav.Item>
          <Nav>
            {navItems}
            <div className="nav-btns">{navBtns}</div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {/* <ContactUs formOpen={formOpen} setFormOpen={setFormOpen} id="navBar" /> */}
    </div>
  );
};

export default NavBar;
