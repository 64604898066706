import { FooterInfo, NavInfo } from "interface/footer.interface";
import LinkProps from "interface/linkProps.interface";
import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";
import DefinedMessage from "interface/defineMessage.interface";
import ROUTES from "constants/ROUTES";
import facebook from "images/footer/facebook.png";
import linkedin from "images/footer/linkedin.png";
import logo from "images/footer/FP-logo-white.png";

const headOfficeInfo: FooterInfo = {
  title: mapStringToDefinedMessage("Hong Kong Headquarter"),
  address: mapStringToDefinedMessage(
    "16/F, 9 Chong Yip Street, Kwun Tong, Kowloon, Hong Kong",
  ),
  phone: "(852) 3460 2871",
  mailto: "hello@fundpark.com",
};
// will affect footer.tsx, ContactUs.tsx
// const branchOfficeInfo: FooterInfo = {
//   title: mapStringToDefinedMessage("Taiwan Branch Office"),
//   address: mapStringToDefinedMessage(
//     "FinTech Space 13F. NO 1, Nanhai Road, ZhongZheng District Taipei Taiwan",
//   ),
//   phone: "(886) 2 2343 5413",
//   mailto: "",
// };

const shenZhenbranchOfficeInfo: FooterInfo = {
  title: mapStringToDefinedMessage("Shenzhen Branch Office"),
  address: mapStringToDefinedMessage(
    "Room 1903, Block C, Dachong Business Center, Yuehai Community, Nanshan District, Shenzhen",
  ),
  phone: "(86) 0755-86339693",
  mailto: "",
};

const footNavMainTap:LinkProps[] = [
  {
    text:"About FundPark",
    href: ROUTES.ABOUT_US
  },
  {
    text:"Solutions",
    href: ROUTES.BUSINESS
  },
  {
    text:"Careers",
    href: ROUTES.CAREER
  },
  {
    text:"Our Funders",
    href: ROUTES.FUNDER
  },
  {
    text:"Contact Us",
    href: ROUTES.CONTACT_US
  }
] 

const footerNavOptions: LinkProps[] = [
  {
    text: "Terms of use",
    href: ROUTES.TERMS_AND_CONDITIONS,
  },
  { text: "Privacy Policy", href: ROUTES.PRIVACY_POLICY },
  // { text: "Data Policy", href: ROUTES.DATA_POLICY },
  { text: "FAQ", href: ROUTES.FAQ},
  { text: "Press Room", href: ROUTES.NEWS_AND_EVENTS},
  { text: "Referrer", href: ROUTES.REFERRER},
];

const socialMediaTextInfo = [
  { image: facebook, href: ROUTES.FACEBOOK },
  { image: linkedin, href: ROUTES.LINKEDIN },
];

const footerWarningInfo: DefinedMessage[] = [
  mapStringToDefinedMessage(
    "Warning: You have to repay your loans. Don't pay any intermediaries.",
  ),
  mapStringToDefinedMessage(
    "Complaint Hotline: (852) 3460 2871",
  ),
  mapStringToDefinedMessage("Money Lender's License: 2214/2023"),
];

const footerCopyRightInfo: DefinedMessage[] = [
  mapStringToDefinedMessage("© COPYRIGHT"),
  mapStringToDefinedMessage("FUNDPARK - ALL RIGHTS RESERVED"),
];

// if the text is not map to definedMessage, map it first
const footerNavInfo: NavInfo = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: footerNavOptions.map(
    ({ text, href }: LinkProps): LinkProps => {
      if (typeof text === "string") {
        return {
          text: mapStringToDefinedMessage(text),
          href,
        };
      }
      return {
        text,
        href,
      };
    },
  ),
};

// if the text is not map to definedMessage, map it first
const footNavMainTapInfo: NavInfo = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: footNavMainTap.map(
    ({ text, href }: LinkProps): LinkProps => {
      if (typeof text === "string") {
        return {
          text: mapStringToDefinedMessage(text),
          href,
        };
      }
      return {
        text,
        href,
      };
    },
  ),
};

export {
  logo,
  headOfficeInfo,
  // branchOfficeInfo,
  shenZhenbranchOfficeInfo,
  footerNavInfo,
  footerWarningInfo,
  footerCopyRightInfo,
  socialMediaTextInfo,
  footNavMainTapInfo,
};
