// @ts-nocheck

import React from "react";
import { NavLink } from "react-router-dom";
import { TranslatedNavInfoProps } from "interface/footer.interface";
import { getLanguage } from "lib/language";

// for short hand reference
type JX = JSX.Element;

const FooterNav = ({ options }: TranslatedNavInfoProps): JX => {
  const language = getLanguage();

  return (
    <div className="col footer-nav">
      {options.map(
        (option, idx: number): JX => {
          const { text, href } = option;
          return (
            <NavLink key={`footer_nav_${idx}`} to={`/${language}${href}`}>
              {text}
            </NavLink>
          );
        }
      )}
    </div>
  );
};

export default FooterNav;
