import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import CardsWithTitle from "components/CardsWithTitle/cardsWithTitle";
import { businessBannerInfo } from "constants/card/product";
import { TranslatedCardsWithTitleProps } from "interface/cardsWithTitle.interface";

import BizNav from "components/BizNav/BizNav";
import bizNavInfo from "constants/bizNav/info";
import { TranslatedNavInfoProps } from "interface/footer.interface";

import IconGroupSection from "components/iconGroupSection/iconGroupSection";
import { smeInfoBlockProps } from "constants/iconGroupBlock/info";
import { TranslatedIconGroupInfo } from "interface/iconGroup.interface";

import ProcedureBlockWithTitle from "components/ProcedureBlock/procedureBlockWithTitle";
import { procedureBlockInfoList } from "constants/procedureBlock/info";
import {
  TranslatedSimpleIntroInfoListProps,
  TranslatedProcedureWithTitleInfoProps
} from "interface/introInfo.interface";

// import CalculatorBlock from "components/Calculator/calculatorBlock";
// import { calculatorWithTabsInfo } from "constants/calculator/info";
// import { TranslatedCalculatorProps } from "interface/calculator.interface";
// import YtbVideo from "components/YtbVideo/ytbVideo";

// import BizCarousel from "components/BizCarousel/bizCarousel";
// import { carouselCardListInfo } from "constants/bizCarousel/info";
// import { TranslatedCarouselCardListProps } from "interface/card.interface";

import SuitableBlock from "components/SuitableBlock/suitableBlock";
import { suitableBlockInfo } from "constants/suitableBlock/info";
import { useMediaQuery } from "@material-ui/core";
// import Products from "components/Products";

import ConnectFormBlock from "components/ConnectForm/connectFormBlock";
import {connectFormEligibilityInfo} from 'constants/connectForm/eligibility';
import {TranslatedConnectFormProps} from 'interface/connectForm.interface';

const Business = (): JSX.Element => {
  const businessBannerInfoProps: TranslatedCardsWithTitleProps = mapToTranslatedProps(
    businessBannerInfo
  );

  const bizNavInfoProps: TranslatedNavInfoProps = mapToTranslatedProps(
    bizNavInfo
  );

  const smeBlockInfo: TranslatedIconGroupInfo = mapToTranslatedProps(
    smeInfoBlockProps
  );

  const procedureBlockInfo: TranslatedProcedureWithTitleInfoProps = mapToTranslatedProps(
    procedureBlockInfoList
  );

  // const calculatorWithTabsInfoProps: TranslatedCalculatorProps = mapToTranslatedProps(
  //   calculatorWithTabsInfo
  // );

  // const carouselCardListInfoProps: TranslatedCarouselCardListProps = mapToTranslatedProps(
  //   carouselCardListInfo
  // );

  const suitableBlockInfoProps: TranslatedSimpleIntroInfoListProps = mapToTranslatedProps(
    suitableBlockInfo
  );

  const connectFormInfoProps: TranslatedConnectFormProps = mapToTranslatedProps(
    connectFormEligibilityInfo
  );

  const matches = useMediaQuery('(min-width:768px)');

  return (
    <>
      {/* <Products /> */}
      <CardsWithTitle {...businessBannerInfoProps} isSlide={matches} />
      <BizNav {...bizNavInfoProps} />
      <IconGroupSection {...smeBlockInfo} />
      <ProcedureBlockWithTitle {...procedureBlockInfo} />
      {/* <CalculatorBlock {...calculatorWithTabsInfoProps} /> */}
      {/* <YtbVideo titleName="Customer_Testimonial"/> */}
      {/* <BizCarousel {...carouselCardListInfoProps} /> */}
      <SuitableBlock {...suitableBlockInfoProps} />
      <ConnectFormBlock {...connectFormInfoProps}/>
    </>
  );
};

export default Business;
