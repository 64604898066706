import React, { useState } from "react";
import "styles/video.scss";
import { FormattedMessage } from 'react-intl';

const ytbVideoIdList = [
  // "whDA5wfx5wg", 
  "lo2ZIEjI050", 
  "tk5hpc6TtkY"
];

function YtbVideo(props: any) {
  const titleName = props.titleName;
  const [currentVideoId, setCurrentVideoId] = useState("");
  return (
    <div className="videoContainer">
      {/* Watch_our_customer_stories */}
      {/* Customer_Testimonial */}
      <h3 className="videoTitle"><FormattedMessage id={`app.${titleName}`}/></h3>
      <div className="video-wrapper">
        {ytbVideoIdList.map((ytbVideoId) => {
          return (
            <div
              key={ytbVideoId}
              className="video-thumb"
              id={ytbVideoId}
              style={{
                backgroundImage: `url(https://img.youtube.com/vi/${ytbVideoId}/0.jpg)`,
              }}
              onClick={(e) => {
                setCurrentVideoId(e.currentTarget.id);
              }}
            >
              <div className="play-btn"></div>
              <div className="play-btn-inner"></div>
            </div>
          );
        })}

        <div
          className={
            currentVideoId ? "dialog-container open" : "dialog-container"
          }
        >
          <div className="video-dialog">
            <iframe
              src={`https://www.youtube.com/embed/${currentVideoId}?autoplay=1&modestbranding=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={`${currentVideoId}`}
            />
            <div
              className="close-btn"
              onClick={() => {
                setCurrentVideoId("");
              }}
            >
              X
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YtbVideo;
