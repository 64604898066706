import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import Banner from "components/Banner/banner";
import SimpleBanner from "components/Banner/simpleBanner";
import { aboutUsBannerInfo, simpleBannerInfo } from "constants/banner/info";
import {
  TranslatedBannerProps,
  TranslatedSimpleBannerProps
} from "interface/banner.interface";

import PlainIntroBlock from "components/PlainIntroBlock/plainIntroBlock";
import { aboutUsPlainIntroInfo } from "constants/plainIntro/info";
import { TranslatedPlainIntroProps } from "interface/plainIntro.interface";

import BenefitsWithDetailsBlock from "components/BenefitsWithDetailsBlock/BenefitsWithDetailsBlock";
import { TranslatedIntroInfoListProps } from "interface/introInfo.interface";
import { benefitsIntroInfo } from "constants/BenefitsWithDetails/info";

import PureImage from "components/PureImage/pureImage";

// import { mgmtTeamInfo } from "constants/filpCard/info";
// import { TranslatedFlipCardListProps } from "interface/flipCard.interface";
// import FlipCards from "components/FlipCard/FlipCard";

import { TranslatedBubbleIntroBlockProps } from "interface/bubbleIntroInfo.interface";
import { staffIntroInfo } from "constants/bubbleIntroForm/bubbleIntroInfo";
import  BubbleIntroBlock  from "components/BubbleIntroBlock/BubbleIntroBlock"
 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// const FlipCards = React.lazy((): any => {
//   return import("components/FlipCard/flipCards");
// });

const AboutUs = (): JSX.Element => {
  const aboutUsBannerInfoProps: TranslatedBannerProps = mapToTranslatedProps(
    aboutUsBannerInfo
  );

  const aboutUsPlainIntroInfoProps: TranslatedPlainIntroProps = mapToTranslatedProps(
    aboutUsPlainIntroInfo
  );

  const benefitsIntroInfoProps: TranslatedIntroInfoListProps = mapToTranslatedProps(
    benefitsIntroInfo
  );

  // const mgmtTeamInfoProps: TranslatedFlipCardListProps = mapToTranslatedProps(
  //   mgmtTeamInfo
  // );

  const simpleBannerInfoProps: TranslatedSimpleBannerProps = mapToTranslatedProps(
    simpleBannerInfo
  );

  const staffIntroInfoProps: TranslatedBubbleIntroBlockProps = mapToTranslatedProps(
    staffIntroInfo
  )

  // const isClient = typeof window !== "undefined";

  return (
    <div className="about-us">
      <Banner {...aboutUsBannerInfoProps} />
      <PlainIntroBlock {...aboutUsPlainIntroInfoProps} />
      <BenefitsWithDetailsBlock {...benefitsIntroInfoProps} />
      <BubbleIntroBlock {...staffIntroInfoProps}/>
      {/* <FlipCards {...mgmtTeamInfoProps} /> */}
      <PureImage isPure/>
      <SimpleBanner {...simpleBannerInfoProps} />
    </div>
  );
};

export default AboutUs;
