import DefinedMessage from "interface/defineMessage.interface";
import FormattedMessageInterface from "interface/formattedMessage.interface";

export function mapStringToDefinedMessage(
  defaultMessage: string,
  description?: string,
): DefinedMessage {
  const id: string = "app." + defaultMessage.split(" ").join("_");
  return {
    id,
    defaultMessage,
    description,
  };
}

export function mapStringToFormattedStringProps(props: {
  defaultMessage: string;
  id?: string;
  description?: string;
  tagName?: string;
  values?: object;
}): FormattedMessageInterface {
  const id: string =
    "app." + (props.id || props.defaultMessage).split(" ").join("_");
  return {
    id,
    defaultMessage: props.defaultMessage,
    description: props.description,
    tagName: props.tagName,
    values: props.values,
  };
}

export function mapZhStringToDefinedMessage(
  str: string,
  defaultId: string,
): DefinedMessage {
  const id: string = "app." + defaultId;
  return {
    id,
    defaultMessage: str,
  };
}

export default mapStringToDefinedMessage;
