export const LANGUAGE = "fundPark-Language";
export const EN = "en";
export const ZH = "zh-hk";
export const AVAILABLE_LANGUAGE = ['en', 'zh-hk'];

export const LANGUAGE_MAPPING = {
  [EN]: 'English',
  [ZH]: 'TraditionalChinese',
}

const SIGNUP = "signup";
const IM = "IM";
const SIF = "SIF";

export const BUTTON_CLASSNAME = {
  [SIGNUP]: {
    [EN]: "signup button eng",
    [ZH]: "signup button"
  },
  [IM]: {
    [EN]: "IM button eng",
    [ZH]: "IM button"
  },
  [SIF]: {
    [EN]: "SIF button eng",
    [ZH]: "SIF button"
  }
};
