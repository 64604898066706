import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import Banner from "components/Banner/banner";
import SimpleBanner from "components/Banner/simpleBanner";
import {
  purchaseOrderBannerInfo,
  simpleBannerInfo,
} from "constants/banner/info";
import {
  TranslatedBannerProps,
  TranslatedSimpleBannerProps,
} from "interface/banner.interface";

import PlainIntroBlock from "components/PlainIntroBlock/plainIntroBlock";
import { purchaseOrderPlainIntroInfo } from "constants/plainIntro/info";
import { TranslatedPlainIntroProps } from "interface/plainIntro.interface";

// import CalculatorBlock from "components/Calculator/calculatorBlock";
// import { POFinancingCalculatorInfo } from "constants/calculator/info";
// import { TranslatedCalculatorProps } from "interface/calculator.interface";

import SuitableBlock from "components/SuitableBlock/suitableBlock";
import { suitableBlockInfo } from "constants/suitableBlock/info";
import { TranslatedSimpleIntroInfoListProps } from "interface/introInfo.interface";

// import MediaCoverage from "components/MediaCoverage";

const PurchaseOrder = (): JSX.Element => {
  const purchaseOrderBannerInfoProps: TranslatedBannerProps = mapToTranslatedProps(
    purchaseOrderBannerInfo,
  );

  const purchaseOrderPlainIntroInfoProps: TranslatedPlainIntroProps = mapToTranslatedProps(
    purchaseOrderPlainIntroInfo,
  );

  // const POFinancingCalculatorInfoProps: TranslatedCalculatorProps = mapToTranslatedProps(
  //   POFinancingCalculatorInfo,
  // );

  const suitableBlockInfoProps: TranslatedSimpleIntroInfoListProps = mapToTranslatedProps(
    suitableBlockInfo,
  );

  const simpleBannerInfoProps: TranslatedSimpleBannerProps = mapToTranslatedProps(
    simpleBannerInfo,
  );

  return (
    <div className="our-product">
      <Banner {...purchaseOrderBannerInfoProps} />
      <PlainIntroBlock {...purchaseOrderPlainIntroInfoProps} />
      {/* <CalculatorBlock {...POFinancingCalculatorInfoProps} /> */}
      <SuitableBlock {...suitableBlockInfoProps} />
      {/* <MediaCoverage /> */}
      <SimpleBanner {...simpleBannerInfoProps} />
    </div>
  );
};

export default PurchaseOrder;
