import React from "react";
import PureImage from 'components/PureImage/pureImage';
import { 
    TranslatedStaffIntroFormProps,
    TranslatedStaffDescriptionProps,
} from "interface/bubbleIntroInfo.interface";


const BubbleItem =({
    img,
    title,
    description,
    point_list,
}:TranslatedStaffIntroFormProps): JSX.Element => {

    const points = point_list.map(
        (point:TranslatedStaffDescriptionProps, index:number):JSX.Element => {
            return <span key={`bubble_staff_${index}`}>{point.point}</span>
        }
    );

    return (
        <div className="bubble-item-block d-flex my-4 mobileContainer">
            <div className="bubble-item-block-img">
                <PureImage src={img} />
            </div>
            <section>
                <div className="bubble-item-block-title font-weight-bold mobileTitleSize" style={{ color:"rgb(63,71,104)", fontSize:"20px" }}> {title} </div>
                <div className="bubble-item-block-title font-weight-bold mb-2 mobileDescriptionSize"> {description} </div>
                <span className="mobileDetailsSize" style={{ textAlign: "left", fontSize: "14px"}}>
                    {points}
                </span>
            </section>
        </div>
    );
};

export default BubbleItem;