const apStorage = {
  get: (key) => {
    const value = localStorage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  },
  set: (key, value) => localStorage.setItem(key, (typeof value === 'object' ? JSON.stringify(value) : value)),
  remove: (key) => localStorage.removeItem(key),
  clear: () => localStorage.clear(),
};

export default apStorage;
