// @ts-nocheck

import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import OpportunitiesBlock from "./OpportunitiesBlock";

import "styles/career.scss";
import { opportunitiesBlockProps } from "constants/opportunitiesBlock/info";
import { TranslatedOpportunitiesBlockProps } from "interface/opportunitiesBlock.interface";

// eslint-disable-next-line @typescript-eslint/no-explicit-any

const Career = (): JSX.Element => {
  const translatedOpportunitiesBlockProps: TranslatedOpportunitiesBlockProps = mapToTranslatedProps(opportunitiesBlockProps);

  return (
    <div className="career">
      <OpportunitiesBlock {...translatedOpportunitiesBlockProps} />
    </div>
  );
};

export default Career;
