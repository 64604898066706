import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import CardsWithTitle from "components/CardsWithTitle/cardsWithTitle";
import { TranslatedCardsWithTitleProps } from "interface/cardsWithTitle.interface";
import { mediaCoverageInfo } from "constants/cardsWithTitle/info";

const MediaCoverage = () => {
  const mediaCoverageInfoProps: TranslatedCardsWithTitleProps = mapToTranslatedProps(
    mediaCoverageInfo,
  );

  return <CardsWithTitle {...mediaCoverageInfoProps} />;
};

export default MediaCoverage;
