// @ts-nocheck
import React from 'react';
import solutionImgWebEng from "images/solutions/solutions.jpg";
import solutionImgMobileEng from "images/solutions/solutions-mobile.jpg";
import solutionImgWebCn from "images/solutions/solutions_cn.jpg";
import solutionImgMobileCn from "images/solutions/solutions-mobile_cn.jpg";
import { getLanguage } from "lib/language";
// import ROUTES from "constants/ROUTES";
// import { BUTTON_CLASSNAME } from "constants/language";
// import { ExternalLinkProps } from "interface/linkProps.interface";

//Translate
// import {
    // mapStringToDefinedMessage,
  // } from "lib/translate/mapStringToDefinedMessage";
// import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

const Solutions = () : JSX.Element => {
    const language = getLanguage();
    const webPicture = language === 'en' ? solutionImgWebEng : solutionImgWebCn;
    const mobilePicture = language === 'en' ? solutionImgMobileEng : solutionImgMobileCn;

    // const lang = language === 'en' ? 'en' : 'zh-hk'
    // const learnMoreButton = mapToTranslatedProps({
    //     btn: {
    //         text: mapStringToDefinedMessage("Learn_More"),
    //         href: `${lang}${ROUTES.BUSINESS}?ref=faq`,
    //         externalLink: true,
    //         // btnType: "signup",
    //     }
    // });
    // const { btn } = learnMoreButton;

    // const renderBtn = ({
    //     text,
    //     href,
    //     btnType
    //   }: ExternalLinkProps): JSX.Element => {
    //     return (
    //       <a
    //         className="btn btn-dark"
    //         href={`${href}${language}`}
    //       >
    //         {text}
    //       </a>
    //     );
    //   };

    // console.log(learnMoreButton,'learnMoreButton')

    return (
        <>
            <div className='solutionsContainer'>
                <img src={webPicture} className="solutionImg-web" alt="solutionImg-web"/>    
                <img src={mobilePicture} className="solutionImg-mobile" alt="solutionImg-mobile"/>
                {/* <div className='buttonContainer'>
                    {renderBtn(btn)}
                </div> */}
            </div>
        </>

    );

}

export default Solutions;