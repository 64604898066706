// import { addLocaleData } from "react-intl";
// import locale that you want to ues in App
// import localeEn from "react-intl/locale-data/en";
// import localeZh from "react-intl/locale-data/zh";
// import all translations
import en from "translations/en.json";
import zh from "translations/zh.json";

interface Messages<JSON> {
  zh: JSON;
  en: JSON;
}

/**
 * register locales use in this project and return translation as an object
 */
function registerLanguageLocale(): Messages<{}> {
  // addLocaleData([...localeEn, ...localeZh]);
  return {
    zh: zh,
    en: en
  };
}

export default registerLanguageLocale;
