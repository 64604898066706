/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

function OnLocationUpdate({ history }): null {
  const { location } = history;
  const [bizPageFirstRender, setBizPageFirstRender] = useState(true);
  const isBizPage = location.pathname.includes("business");
  useEffect(() => {
    if (isBizPage && bizPageFirstRender) {
      window.scrollTo(0, 0);
      setBizPageFirstRender(false);
    }
    else if (location.hash.match('#')) {
      const targetSection = document.querySelector(location.hash);

      if (targetSection) {
        targetSection.scrollIntoView();
      }
      setBizPageFirstRender(true);

    } else if (!isBizPage) {
      window.scrollTo(0, 0);
      setBizPageFirstRender(true);
    }
    // eslint-disable-next-line
  }, [location]);

  return null;
}

export default withRouter(OnLocationUpdate);
