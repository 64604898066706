import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";
import {
  ProcedureWithTitleInfoProps,
  ProcedureInfoProps,
  IntroInfoListProps
} from "interface/introInfo.interface";
import ROUTES from "constants/ROUTES";

import proceduresImg1 from "images/procedures/img1.png";
import proceduresImg2 from "images/procedures/img2.png";
import proceduresImg3 from "images/procedures/img3.png";
import proceduresImg4 from "images/procedures/img4.png";

import simpleStepImg1 from "images/simpleSteps/img1.png";
import simpleStepImg2 from "images/simpleSteps/img2.png";
import simpleStepImg3 from "images/simpleSteps/img3.png";

import rpImg1 from "images/referrer-procedure/img1.png";
import rpImg2 from "images/referrer-procedure/img2.png";
import rpImg3 from "images/referrer-procedure/img3.png";
import rpImg4 from "images/referrer-procedure/img4.png";

export const procedureBlockInfoList: ProcedureWithTitleInfoProps = {
  id: "procedures",
  title: mapStringToDefinedMessage("Application Process"),
  introInfoList: [
    {
      image: proceduresImg1,
      title: mapStringToDefinedMessage("Online Registration")
    },
    {
      image: proceduresImg2,
      title: mapStringToDefinedMessage("Documents Submission")
    },
    {
      image: proceduresImg3,
      title: mapStringToDefinedMessage("Quick Approval")
    },
    {
      image: proceduresImg4,
      title: mapStringToDefinedMessage("Receive Funding")
    }
  ],
  // btn: {
  //   text: mapStringToDefinedMessage("Register Now"),
  //   href: ROUTES.REGISTER,
  //   externalLink: true,
  //   btnType: "signup"
  // },
  description: mapStringToDefinedMessage(
    "4 simple steps to unlock potential cash flow"
  )
};

export const simpleStepsInfo: ProcedureWithTitleInfoProps = {
  title: mapStringToDefinedMessage("3 Simple Steps to Invest"),
  introInfoList: [
    {
      image: simpleStepImg1,
      title: mapStringToDefinedMessage("Register")
    },
    {
      image: simpleStepImg2,
      title: mapStringToDefinedMessage("Invest in high potential companies")
    },
    {
      image: simpleStepImg3,
      title: mapStringToDefinedMessage("Receive Return")
    }
  ]
};

export const referrerProcedureInfo: ProcedureInfoProps = {
  introInfoList: [
    {
      image: rpImg1,
      title: mapStringToDefinedMessage("Register")
    },
    {
      image: rpImg2,
      title: mapStringToDefinedMessage("Refer New Corporate Customers")
    },
    {
      image: rpImg3,
      title: mapStringToDefinedMessage("Successful Loan Drawdown")
    },
    {
      image: rpImg4,
      title: mapStringToDefinedMessage("Referral Fee")
    }
  ],
  btn: {
    text: mapStringToDefinedMessage("Contact Us"),
    href: `${ROUTES.CONTACT_US}?ref=referrer`,
    externalLink: true,
    btnType: "IM"
  }
};

export const finTradeAcrossAsiaInfo: ProcedureWithTitleInfoProps = {
  title: mapStringToDefinedMessage("Financing Trade Across Asia"),
  introInfoList: [
    {
      image: simpleStepImg1,
      title: mapStringToDefinedMessage(
        "Ac et nisl, praesent elit viverra dolor nulla. Sapien diam etiam diam ad platea metus cras semper in felis nibh praesent."
      )
    },
    {
      image: simpleStepImg2,
      title: mapStringToDefinedMessage(
        "Ac et nisl, praesent elit viverra dolor nulla. Sapien diam etiam diam ad platea metus cras semper in felis nibh praesent."
      )
    },
    {
      image: simpleStepImg3,
      title: mapStringToDefinedMessage(
        "Ac et nisl, praesent elit viverra dolor nulla. Sapien diam etiam diam ad platea metus cras semper in felis nibh praesent."
      )
    }
  ]
};

export const eCommerceHowItWorkList: IntroInfoListProps = {
  title:mapStringToDefinedMessage("How it works"),
  infos:[
    {
      title:mapStringToDefinedMessage("Connect to your eCommerce platforms"),
      description:mapStringToDefinedMessage("Minimise paperwork needed - accessing your dashboard & data directly"),
    },
    {
      title:mapStringToDefinedMessage("Data enabled credit assessment"),
      description:mapStringToDefinedMessage("Quick and real time assessment of your current status"),
    },
    {
      title:mapStringToDefinedMessage("Approval of facility within 2-week"),
      description:mapStringToDefinedMessage("Apply once and use as you go"),
    },
    {
      title:mapStringToDefinedMessage("Watermark of the facility is adjusted based on live data of your recent sales"),
      description:mapStringToDefinedMessage("Instant reflection of your recent sales, supporting your growth trend"),
    },
    {
      title:mapStringToDefinedMessage("Withdraw funds within 24 hours"),
      description:mapStringToDefinedMessage("Online drawdown requests, funds dispersed within 24 hours"),
    },
  ],
  className:"vertical-procedure"
};
