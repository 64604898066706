import { NavInfo } from "interface/footer.interface";
import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";
import ROUTES from "constants/ROUTES";

const bizNavInfo: NavInfo = {
  options: [
    {
      text: mapStringToDefinedMessage("Benefits"),
      href: ROUTES.BENEFITS
    },
    {
      text: mapStringToDefinedMessage("Application Process"),
      href: ROUTES.PROCEDURES
    },
    {
      text: mapStringToDefinedMessage("Eligibility"),
      href: ROUTES.ELIGIBILITY
    }
  ]
};

export default bizNavInfo;
