import { IntroInfoListProps } from "interface/introInfo.interface";
import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";
import img1 from "images/benefits-with-details/img1.png";
import img2 from "images/benefits-with-details/img2.png";
import img3 from "images/benefits-with-details/img3.png";
import img4 from "images/benefits-with-details/img4.png";
import img5 from "images/benefits-with-details/img5.png";
import img6 from "images/benefits-with-details/img6.png";


export const benefitsIntroInfo: IntroInfoListProps = {
  title: mapStringToDefinedMessage("Our Strengths"),
  subtitle: mapStringToDefinedMessage("Get cash in less than 48 hours"),
  infos: [
    {
      image: img1,
      title: mapStringToDefinedMessage("Experienced Management Team"),
      description: mapStringToDefinedMessage(
        "Our team brings together leading professionals with experience across a diverse range of industries including e-Commerce, Working Capital Solutions, Commercial Banking and Information and Technology etc."
      )
    },
    {
      image: img2,
      title: mapStringToDefinedMessage("Proprietary Predictive Credit model"),
      description: mapStringToDefinedMessage(
        "Our proprietary credit model supports dynamic data collection and analysis that allows us to better assess underlying risks as well as take a forward-looking approach, unlike traditional lenders that rely on historic, often outdated data."
      ),
      
    },
    {
      image: img3,
      title: mapStringToDefinedMessage(
        "Predictive Funding Solutions Powered by AI"
      ),
      description: [
        mapStringToDefinedMessage(
          "Our platform connects with various industry leading companies’ databases via APIs. This enables us to continually enhance our Credit Model with real-time data and advance our predictive AI capabilities."
        )
    ]
    },
    {
      image: img4,
      title: mapStringToDefinedMessage("Supporting eCommerce entrepreneurs to capture growth"),
      description: mapStringToDefinedMessage(
        "FundPark continues to provide even larger, faster and forward-looking funding solutions to thousands of for eCommerce entrepreneurs to optimize their cash flow, and thus accelerating their growth."
      )
    },
    {
      image: img5,
      title: mapStringToDefinedMessage(
        "Strong Funding Firepower"
      ),
      description: [
        mapStringToDefinedMessage(
          'Our funders range from Global Fixed Income Funds, Hedge Funds, to Investment Banks, Family Offices, Asset Management companies and Banks. In 2022 we secured a $250 million ABS facility in one of the largest deals of its kind in Asia, bolstering our firepower to help even more SMEs.'
        ),
      ],
    },
    {
      image: img6,
      title: mapStringToDefinedMessage(
        "Strong Partner Ecosystem"
      ), 
      description: [
        mapStringToDefinedMessage(
          "FundPark works with industry leading business service providers, professional organizations and governments to establish global strategic partnerships. Some of our partners include e-Commerce Platforms, Payment Companies, and Logistics & Warehouses."
        ),
        mapStringToDefinedMessage("　"),
        mapStringToDefinedMessage(
          "We have also won numerous different innovative financial technology awards from reputable institutions and governments, including Hong Kong ICT Award by HKSAR government, IFTA FinTech Achievement Awards by Institute of Financial Technologists of Asia, and FinTech Awards by Hong Kong Economic Times, to name but a few."
        ),
      ]
    }
  ],
  className: "benefits-block"
};
