import { useState } from "react";

export function useCurrentTextValue(initalTextValue) {
  const [textValue, setTextValue] = useState(initalTextValue);
  function changeHandler(e: React.ChangeEvent<HTMLInputElement>): void {
    setTextValue(e.currentTarget.value);
  }
  function resetInput() {
    setTextValue(initalTextValue);
  }
  return { textValue, changeHandler, resetInput };
}
