import React, { useState, useEffect } from "react";
import "styles/cardsWithTitle.scss";

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowButtonProps from 'interface/arrowButton.interface';
import { TranslatedCardsWithTitleProps } from "interface/cardsWithTitle.interface";
import Cards from "components/Card/cards";

import { Container, Row, Col } from 'react-bootstrap';

const BackArrow = (props: ArrowButtonProps) => (
  // <div onClick={props.onClick} style={{fontSize: '2em', marginRight: '12px', cursor: 'pointer',backgroundColor: "#FFF", height :'40px', width: '40px', borderRadius: '6px',lineHeight: '40px', textAlign:'center'}}>
    
  <div onClick={props.onClick} className="back-arrow-i">
  <i className="fa fa-angle-left fa-1x" aria-hidden="true"></i>
  </div>
)

const NextArrow = (props: ArrowButtonProps) => (
  <div onClick={props.onClick} className="next-arrow-i">
    <i className="fa fa-angle-right fa-1x" aria-hidden="true"></i>
  </div>
);

const CardsWithTitle = (props: TranslatedCardsWithTitleProps): JSX.Element => {
  const {
  title = "",
  cards,
  isSlide = false,
  } : TranslatedCardsWithTitleProps = props;
  
  const [currentSlide, setCurrentSlide] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
      if (isMobile === false) {
        setCurrentSlide(0);
      }
  }, [isMobile]);

  // Use Container, Row and Col in react-bootstrap to position arrows
  if (isSlide === false) {
    return (
    <div className="cards-wrapper">
        {title && <h2>{title}</h2>}
        <Cards cards={cards} />
    </div>
    );
  }

  const { slideConfig = {}} = props;

  // TODO: find TS master to solve any in the below line
  let { slidesToShow = 3} = slideConfig as any;
  if (isMobile === true) {
      slidesToShow = 1;
  }

  // Split cards into subset i.e. cards1-6 => [[card1, card2, card3], [card4, card5, card6]]
  const slides = cards.reduce((resultArray: Array<any>, item, index) => { 
    const chunkIndex = Math.floor(index / slidesToShow);

    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
  // TODO: responsive design of arrows
  const numberOfSlides:number = slides.length;
  const showBackArrow = currentSlide !== 0;
  const showNextArrow = currentSlide < numberOfSlides - 1;

  return (
    <div className="cards-wrapper">
      {title && <h1 className="ourSolutionsHeader">{title}</h1>}
      <Container fluid>
        <Row>
            {!isMobile && (
            <Col sm={1} className="align-items-center justify-content-center d-flex">
                {showBackArrow && <BackArrow onClick={() => setCurrentSlide(currentSlide - 1)} />}
            </Col>
            )}

            <Col xs={12} sm>
                <Cards cards={currentSlide > slides.length ? slides[0] : slides[currentSlide]} />
            </Col>

            {isMobile && showBackArrow && (
                <Col xs={6} sm={1} className="align-items-center justify-content-center d-flex">
                    <BackArrow onClick={() => setCurrentSlide(currentSlide - 1)} />
                </Col>
            )}
            <Col xs sm={1} className="align-items-center d-none justify-content-center d-flex">
                {showNextArrow && <NextArrow onClick={() => setCurrentSlide(currentSlide + 1)} />}
            </Col>
        </Row>
        </Container>
    </div>
  );
};

export default CardsWithTitle;
