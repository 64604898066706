import React from "react";
import { TranslatedFooterInfoProps } from "interface/footer.interface";

const InfoBlock = (props: TranslatedFooterInfoProps): JSX.Element => {
  const { title, mailto, phone, address } = props;
  return (
    <div className="d-flex info-block">
      <div>
        <h5 className="font-weight-bold">{title}</h5>
        <div>
          <p>{address}</p>
          <p>{phone}</p>
          <p>
            <a href={`mailto:${mailto}`}>{mailto}</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfoBlock;
