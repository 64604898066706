import React from 'react';

import { TranslatedIntroInfoProps } from 'interface/introInfo.interface';

function IntroductionBlock(props: TranslatedIntroInfoProps) {
  return (
    <section className="introduction-block">
      <p className="introduction-block-title">
        {props.title}
      </p>
      <img src={props.image} alt="introduction" className="introduction-block-image-mobile" ></img>
      <p className="introduction-block-description">
        {props.description}
      </p>
    </section>
  )
}

export default IntroductionBlock
