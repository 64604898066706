import React from 'react';

const Dot = ({ active, handleClick }) => (
    <span
        style={{ 
            padding:'6px',
            marginRight: '10px',
            cursor: 'pointer', 
            borderRadius: '50%',
            background: `${active? '#64ccc9':'white'}`
        }}
        onClick={handleClick}
    />
)
export default Dot;