import "styles/funder.scss";
import "styles/productPage.scss";

import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import Banner from "components/Banner/banner";
import { TranslatedBannerProps } from "interface/banner.interface";
import { supplyChainFinaceBannerInfo } from "constants/banner/info";
import { supplyChainFinanceIntro } from "constants/plainIntro/info";
import PlainIntroBlock from "components/PlainIntroBlock/plainIntroBlock";
import { TranslatedPlainIntroProps } from "interface/plainIntro.interface";
import img1 from "images/supplyChainFinance/img1-en-desktop.png";
import img2 from "images/supplyChainFinance/img1-tc-desktop.png";
import img3 from "images/supplyChainFinance/img1-en-mobile.png";
import img4 from "images/supplyChainFinance/img1-tc-mobile.png";
import PureImage from "components/PureImage/pureImage";
import LearnMoreBlock from "components/LearnMoreBlock/learnMoreBlock";
import { TranslatedSeekingBlockProps } from "interface/seekingBlock.interface";
import { scfContactUsInfo } from "constants/learnMoreBlock/info";
import { benefitToBuyerInfo } from "constants/cardsWithTitle/benefitToBuyer";
import { benefitToSupplierInfo } from "constants/cardsWithTitle/benefitToSuppliers";
import CardsWithTitle from "components/CardsWithTitle/cardsWithTitle";
import { TranslatedCardsWithTitleProps } from "interface/cardsWithTitle.interface";
import { scfSlideInfo } from "constants/slider/info";
import Slider from "components/Slider/slider";
import {TranslatedPageInfoProps} from "interface/slideInfo.interface";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getLanguage } from "lib/language";


const SupplyChainFinance = (): JSX.Element => {
  const bannerInfoProps: TranslatedBannerProps = mapToTranslatedProps(
    supplyChainFinaceBannerInfo
  );

  const supplyChainFinanceIntroPros: TranslatedPlainIntroProps = mapToTranslatedProps(
    supplyChainFinanceIntro
  );

  const learnMoreBlockInfoProps: TranslatedSeekingBlockProps = mapToTranslatedProps(
    scfContactUsInfo
  );

  const benefitToBuyerInfoProps: TranslatedCardsWithTitleProps = mapToTranslatedProps(
    benefitToBuyerInfo,
  );

  const benefitToSupplierInfoProps: TranslatedCardsWithTitleProps = mapToTranslatedProps(
    benefitToSupplierInfo,
  )

  const slideInfoProps: TranslatedPageInfoProps = mapToTranslatedProps(
    scfSlideInfo
  );
  
  
  const matches = useMediaQuery('(min-width:576px)');
  const language = getLanguage();
  var img = "";
  if(matches){
    //mobile size
    if(language === "zh-hk"){
      img = img2;
   }else{
      img = img1;
   }
  }else{
    // desktop size
    if(language === "zh-hk"){
      img = img4;
    }else{
      img = img3;
    }
  }

  return (
    <div className="supply-chain-finace-wrapper">
      <Banner {...bannerInfoProps} />
      <PlainIntroBlock {...supplyChainFinanceIntroPros} />
      <PureImage isPure={true} src={img}/>
      <div className="map-bg-image">
        <CardsWithTitle {...benefitToBuyerInfoProps} />
        <CardsWithTitle {...benefitToSupplierInfoProps} />
      </div>
      <Slider {...slideInfoProps}/>
      <LearnMoreBlock {...learnMoreBlockInfoProps} />
    </div>
  );
};

export default SupplyChainFinance;
