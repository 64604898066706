// import React from "react";
import { CardListProps } from "interface/card.interface";
import { CardsWithTitleProps } from "interface/cardsWithTitle.interface";
import {
  mapStringToDefinedMessage,
  // mapStringToFormattedStringProps,
} from "lib/translate/mapStringToDefinedMessage";
// import img1 from "images/iconsForCard/img1.png";
import img1 from "images/supplyChainFinance/card-logo-5.svg";
import img2 from "images/supplyChainFinance/card-logo-6.svg";
import img3 from "images/supplyChainFinance/card-logo-7.svg";
import img4 from "images/supplyChainFinance/card-logo-8.svg";
// import img5 from "images/iconsForCard/img5.svg";


import ROUTES from "constants/ROUTES";

export const cardListInfo: CardListProps = {
  cards: [
    {
      image: img1,
      className: "element-card",
      description: mapStringToDefinedMessage(
        "Tailor-made financing solution based on the supply chain arrangement with the Buyer which on-boarding and approval is more efficient than traditional banking channels",
      ),
      btn: {
        display: false,
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.INVOICE_FINANCING,
        externalLink: false,
      },
    },
    {
      image: img2,
      className: "element-card",
      description: mapStringToDefinedMessage(
        "No minimum requirement for the facility size and business volume",
      ),
      btn: {
        display: false,
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.PURCHASE_ORDER,
        externalLink: false,
      },
    },
    {
      image: img3,
      className: "element-card",
      description: mapStringToDefinedMessage(
        "Flexible and efficient working capital solution for sales growth and meeting seasonal needs",
      ),
      btn: {
        display: false,
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.PURCHASE_ORDER,
        externalLink: false,
      },
    },
    {
      image: img4,
      className: "element-card",
      description: mapStringToDefinedMessage(
        "Strengthen relationship with Buyers",
      ),
      btn: {
        display: false,
        text: mapStringToDefinedMessage("Learn More"),
        href: ROUTES.PURCHASE_ORDER,
        externalLink: false,
      },
    },
  ],
};

export const benefitToSupplierInfo: CardsWithTitleProps = Object.assign(
  { ...cardListInfo },
  { title: mapStringToDefinedMessage("Benefit to Suppliers") },
);
