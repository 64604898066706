import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";
import {PageInfo} from "interface/slideInfo.interface";

export const scfSlideInfo: PageInfo = {
    slideContent:[
        {
            case: mapStringToDefinedMessage('Case'),
            withIndex: true,
            title: mapStringToDefinedMessage('Import e-commerce B2B leader'),
            description:[
                {
                    title:mapStringToDefinedMessage('Core enterprise background'),
                    description:[
                        mapStringToDefinedMessage('Core enterprise (Buyer) is a listed company, with a turnover of more than CNY 5 billion, and a growth of more than 50% in the past two years'),
                        mapStringToDefinedMessage('Customers are mainly from China and Asian Market'),
                        mapStringToDefinedMessage('Products sold on its own platform include watches, apparel, cosmetics luxury goods, etc'),
                        mapStringToDefinedMessage('Mainland, Hong Kong and overseas suppliers'),
                    ]
                },
                {
                    title:mapStringToDefinedMessage('Pain points'),
                    description:[
                        mapStringToDefinedMessage('Buyer faces funding pressure as supplier would like to receive its receivables as soon as possible'),
                    ]
                },
                {
                    title:mapStringToDefinedMessage('Solutions provided by FundPark'),
                    description:[
                        mapStringToDefinedMessage('FundPark offers Supply Chain Finance which release the pressure of the cash flow from the rapid growth of the core enterprise while there is no need for the buyer to bear additional liability and cost'),
                        mapStringToDefinedMessage('Our SCF portal is simple to use and makes approval and funding quick and effective')
                    ]
                }

            ]
        },{
            case: mapStringToDefinedMessage('Case'),
            withIndex: true,
            title: mapStringToDefinedMessage('Export e-commerce pioneer'),
            description:[
                {
                    title:mapStringToDefinedMessage('Core enterprise background'),
                    description:[
                        mapStringToDefinedMessage('Customers are mainly foreign e-commerce platforms, including electronic 3C products, computer peripherals, clothing and household products, etc., with more than 500k SKU'),
                        mapStringToDefinedMessage('More than CNY 15 billion of sales turnover, with annual growth of over 350% in the past two years'),
                        mapStringToDefinedMessage('Mainland, Hong Kong and overseas suppliers'),
                    ]
                },
                {
                    title:mapStringToDefinedMessage('Pain points'),
                    description:[
                        mapStringToDefinedMessage('Buyer would like to leverage on its payment terms to generate working capital while suppliers require early payment to ease funding pressure.'),
                    ]
                },
                {
                    title:mapStringToDefinedMessage('Solutions provided by FundPark'),
                    description:[
                        mapStringToDefinedMessage('FundPark offers Supply Chain Finance which releases the pressure of the cash flow from the rapid growth of the buyer while buyer does not have to bear additional financial liability'),
                        mapStringToDefinedMessage('Our SCF Portal requires dual approval from different functional departments of the buyer, ensuring trade is genuine and correct')
                    ]
                }

            ]
        }
    ]
    
}

export const tabfSlideInfo: PageInfo = {
    slideContent:[
        {
            case: mapStringToDefinedMessage('Successful cases'),
            withIndex: false,
            title: mapStringToDefinedMessage('Leading cross-border e-commerce B2C merchant on Tmall Global'),
            description:[
                {
                    title:mapStringToDefinedMessage('Core enterprise background'),
                    description:[
                        mapStringToDefinedMessage('Merchant’s e-commerce stores on Tmall Global are generating an annual turnover of more than CNY 150 million'),
                        mapStringToDefinedMessage('Customers are mainly from China'),
                        mapStringToDefinedMessage('Products sold on their Tmall Global stores mainly include cosmetics and health-care products'),
                        mapStringToDefinedMessage('German, American and Australian suppliers'),
                    ]
                },
                {
                    title:mapStringToDefinedMessage('Pain points'),
                    description:[
                        mapStringToDefinedMessage('Merchant is lack of offshore funding source. However, the foreign suppliers’ growing payables bring a huge funding pressure to the merchant, especially during the inventory preparation period before e-commerce promotional event. The difficulty of settling advance payment affects the merchant’s inventory procurement and sales plan'),
                    ]
                },
                {
                    title:mapStringToDefinedMessage('Solutions provided by FundPark_tabf_import'),
                    description:[
                        mapStringToDefinedMessage('FundPark offers Trade Asset Based Finance which release the pressure of the cash flow from the business growth of the cross-border e-commerce merchant'),
                        mapStringToDefinedMessage('Merchant is able to apply financing directly on the e-commerce operational portal. Via API integration with CaiNiao, FundPark makes approval and funding quick and effective')
                    ]
                }

            ]
        }
    ]
    
}