// @ts-nocheck
// import React from "react";
import {
  mapStringToDefinedMessage,
  mapStringToFormattedStringProps,
} from "lib/translate/mapStringToDefinedMessage";
import {
  SlideProps,
  // CardsWithTitleProps,
} from "interface/cardsWithTitle.interface";
import img1 from "images/iconsForCard/img1.svg";
import img2 from "images/iconsForCard/img2.svg";
//import img3 from "images/iconsForCard/img3.svg";
import img4 from "images/iconsForCard/img4.svg";
import img5 from "images/iconsForCard/img5.svg";
// import { useMediaQuery } from "@material-ui/core";
// import useMediaQuery from '@material-ui/core/useMediaQuery';

// import img2 from "images/mediaCoverage/img2.jpg";
// import img3 from "images/mediaCoverage/img3.jpg";
// import leftImage from "images/mediaCoverage/left.jpg";
// import middleImage from "images/mediaCoverage/middle.jpg";
// import rightImage from "images/mediaCoverage/right.jpg";
// import ROUTES from "constants/ROUTES";

// import mediaItems from "constants/strapi/media-items.json";

const productItems = [
  {
    id: 'Export: Trade Asset Based Finance',
    image:{
      url:img5
    },
    description:'Quick and easy access to funds in support of your eCommerce business on Amazon.',
    path: '/eCommerce-finance',
  },
  {
    id: 'Import: Trade Asset Based Finance',
    image:{
      url:img4
    },
    description:'An immediate and accessible short-term loan for your e-Commerce business on Tmall, based on the trade assets collateral.',
    path: '/trade-asset-based-finance',
  },
  {
    id: 'Receivable Finance2',
    image:{
      url:img1
    },
    description:'Receivables financing to finance your account receivables and keep cash flow running.',
    path: '/invoice-financing',
  },
  {
    id: 'Purchase Order Finance',
    image:{
      url:img2
    },
    description:'Exclusive pre-shipment solution based on Purchase Order. Get your working capital before goods are made.',
    path: '/purchase-order',
  },
  // {
  //   id: 'Supply Chain Finance',
  //   image:{
  //     url:img3
  //   },
  //   description:'Help buyers or core enterprise to improve their cashflow and liquidity by leveraging payment terms while providing an option for suppliers to receive the payment early.',
  //   path: '/supply-chain-finance',
  // },
  
  
]

export const businessBannerInfo: SlideProps = {
  isSlide: false,
  slideConfig: {
    slidesToShow: 4
  },
  title: mapStringToFormattedStringProps({
    defaultMessage: "FundPark For Business",
  }),
  cards: productItems.map((item) => {
    // const id: string = `product-items-${item.id}.title`;
    if (item.image == null) {
      return null
    }
    return {
      image: item.image.url,
      className: "product-info",
      title: mapStringToFormattedStringProps({
        id: item.id,
        defaultMessage: item.id
      }),
      description: mapStringToFormattedStringProps({ 
        id: item.description,
        defaultMessage: item.description,
      }),
      btn: {
        text: mapStringToDefinedMessage("Learn More"),
        href: item.path,
        externalLink: false,
      },
      descriptionIsHTML: true,
    };
  }).filter(item => item != null),
};

// export const pressReleaseInfo: CardsWithTitleProps = {
//   title: mapStringToDefinedMessage("Press Release"),
//   cards: [
//     {
//       image: img1,
//       title: mapStringToDefinedMessage("Title 1"),
//       description: mapStringToDefinedMessage(
//         "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo",
//       ),
//       btn: {
//         text: mapStringToDefinedMessage("Learn More"),
//         href: ROUTES.MEDIA_COVERAGE_1,
//         externalLink: true,
//       },
//     },
//     {
//       image: img1,
//       title: mapStringToDefinedMessage("Title 2"),
//       description: mapStringToDefinedMessage(
//         "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo",
//       ),
//       btn: {
//         text: mapStringToDefinedMessage("Learn More"),
//         href: ROUTES.MEDIA_COVERAGE_1,
//         externalLink: true,
//       },
//     },
//     {
//       image: img1,
//       title: mapStringToDefinedMessage("Title 3"),
//       description: mapStringToDefinedMessage(
//         "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo",
//       ),
//       btn: {
//         text: mapStringToDefinedMessage("Learn More"),
//         href: ROUTES.MEDIA_COVERAGE_1,
//         externalLink: true,
//       },
//     },
//   ],
// };
