import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";
import image1 from "images/benefitsBlock/img1.png";
import image2 from "images/benefitsBlock/img2.png";
import image3 from "images/benefitsBlock/img3.png";
import image4 from "images/benefitsBlock/img4.png";
import image5 from "images/benefitsBlock/img5.png";
// import image6 from "images/benefitsBlock/img6.png";

import IconGroupInfo from "interface/iconGroup.interface";

const benefitsBlockInfo: IconGroupInfo = {
  title: mapStringToDefinedMessage("The Benefits"),
  icons: [
    {
      image: image1,
      title: mapStringToDefinedMessage("Funding Flexibility"),
      description: mapStringToDefinedMessage(
        "Flexibility to select different types of Assets according to Funder’s risk appetite and return expectations."
      )
    },
    {
      image: image2,
      title: mapStringToDefinedMessage("Low Default Rate"),
      description: mapStringToDefinedMessage(
        "Lower default rate as compared to other similar asset classes."
      )
    },
    {
      image: image3,
      title: mapStringToDefinedMessage("Attractive Returns"),
      description: mapStringToDefinedMessage("Attractive returns compared to those of similar asset classes.")
    },
    {
      image: image4,
      title: mapStringToDefinedMessage("Short Duration"),
      description: mapStringToDefinedMessage("Average duration is 45-60 days")
    },
    {
      image: image5,
      title: mapStringToDefinedMessage("Trade Credit Insurance"),
      description: mapStringToDefinedMessage(
        "Trade credit insurance available for some assets"
      )
    },
    // {
    //   image: image6,
    //   title: mapStringToDefinedMessage("Attractive Fee Structure"),
    //   description: mapStringToDefinedMessage(
    //     "FundPark offers attractive & reasonable fee structure"
    //   )
    // }
  ]
};

export default benefitsBlockInfo;
