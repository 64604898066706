import React from "react";
/**
 * moving the package from node_moudles for customizing features
 * 1. Give a constant as the width of thumb nails for determining the number of them will be showed, and calculate the percise margin value.
 * 2. Render the status message (n of total) in the Thumbs component instead of the Carousel component.
 * GItHub repo: https://github.com/leandrowd/react-responsive-carousel
 */
import { Carousel } from "lib/react-responsive-carousel";
import "lib/react-responsive-carousel/lib/styles/carousel.min.css";
import {
  TranslatedLightboxProps,
  TranslatedLightboxImageProps,
} from "interface/lightbox.interface";
import "styles/lightbox.scss";

const Lightbox = ({ title, content }: TranslatedLightboxProps): JSX.Element => {
  const lightboxContent = content.map(
    (
      { image, legend, date }: TranslatedLightboxImageProps,
      idx: number,
    ): JSX.Element => {
      return (
        <div key={`lightbox_${idx}`}>
          <img src={image} alt={image} />
          <div className="legend d-flex justify-content-between">
            <p>{legend}</p>
            <p>{date}</p>
          </div>
        </div>
      );
    },
  );

  return (
    <div className="lightbox-wrapper d-flex flex-column align-items-center">
      <h3>{title}</h3>
      {Array.isArray(content) && content.length > 0 && (
        <div className="lightbox-content">
          <Carousel
            showIndicators={false}
            showArrows={true}
            onClickThumb={() => console.log("onClickThumb")}
          >
            {lightboxContent}
          </Carousel>
        </div>
      )}
    </div>
  );
};

export default Lightbox;
