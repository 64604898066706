import "styles/banner.scss";
import React from "react";
import { TranslatedBannerProps } from "interface/banner.interface";
import { ExternalLinkProps } from "interface/linkProps.interface";
import { getLanguage } from "lib/language";
import { BUTTON_CLASSNAME } from "constants/language";

const Banner = (props: TranslatedBannerProps): JSX.Element => {
  const { title, description, btn, classNameText } = props;
  const language = getLanguage();

  const renderBtn = ({
    text,
    href,
    btnType
  }: ExternalLinkProps): JSX.Element => {
    return (
      <a
        className={
          btnType &&
          `${BUTTON_CLASSNAME[btnType][language]} btn btn-info font-weight-bold py-3`
        }
        href={`${href}${language}`}
      >
        {text}
      </a>
    );
  };

  return (
    <div className={`banner text-white ${classNameText}`}>
      <section>
        <div className="banner-title banner2">{title}</div>
        {
          Array.isArray(description) && <div className="banner-description">
            <ul>
              {description.map((element,index)=>(
                <li key={index.toString()}>{element}</li>
              ))}
            </ul>
          </div>
        }
        {!Array.isArray(description) && <div className="banner-description">{description}</div>}
        {btn && renderBtn(btn)}
      </section>
    </div>
  );
};

export default Banner;
