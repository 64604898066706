import React from "react";
import { TranslatedMediaEnquiryInfoProps } from "interface/mediaEnquiriesBlock.interface";
import "styles/flip-card.scss";

const MediaEnquiriesBlock = ({
  title,
  subtitle,
  content,
  mailto
}: TranslatedMediaEnquiryInfoProps): JSX.Element => {
  return (
    <div className="media-enquiries-wrapper">
      <h3>{title}</h3>
      {/* <p className="text-center">{subtitle}</p> */}
      <div className="media-enquiry-blocks d-flex justify-content-center">
        <div className="media-enquiry-block">
          {content}&nbsp;
          <a href={`mailto:${mailto}`}>{mailto}</a>
          &nbsp;/ +852 3460 2871
        </div>
      </div>
    </div>
  );
};

export default MediaEnquiriesBlock;
