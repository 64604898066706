import React from "react";
import "styles/footer.scss";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";
import InfoBlock from "./infoBlock";
import {
  TranslatedFooterInfoProps,
  TranslatedNavInfoProps,
  TranslatedDefinedMessageAryProps,
} from "interface/footer.interface";
import {
  logo,
  headOfficeInfo,
  // branchOfficeInfo,
  footerNavInfo,
  footerWarningInfo,
  footerCopyRightInfo,
  shenZhenbranchOfficeInfo,
  footNavMainTapInfo,
} from "constants/footer/info";
import FooterNav from "./footerNav";
import SocialMediaNav from "./socialMediaNav";
import { injectIntl } from "react-intl";
// import fp_wechat_qr_code from "images/QR-code/fp_wechat_qr_code.png";

const Footer = (): JSX.Element => {
  const headOfficeInfoProps: TranslatedFooterInfoProps =
    mapToTranslatedProps(headOfficeInfo);

  // const branchOfficeInfoProps: TranslatedFooterInfoProps = mapToTranslatedProps(
  //   branchOfficeInfo,
  // );

  const shenZhenBranchOfficeInfoProps: TranslatedFooterInfoProps = mapToTranslatedProps(
    shenZhenbranchOfficeInfo,
  );

  const footerNavProps: TranslatedNavInfoProps = mapToTranslatedProps(
    footerNavInfo,
  );
  const footNavMainTabProps: TranslatedNavInfoProps = mapToTranslatedProps(
    footNavMainTapInfo,
  );
    
  const warningText: TranslatedDefinedMessageAryProps = mapToTranslatedProps(
    footerWarningInfo,
  );

  const copyRightText: TranslatedDefinedMessageAryProps = mapToTranslatedProps(
    footerCopyRightInfo,
  );

  const copyRightText1 = copyRightText[0];
  const copyRightText2 = copyRightText[1];
  const copyRightText3 = copyRightText[2];
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="logo">
        <img alt="FundPark" src={logo} />
      </div>
      <div className="d-flex info-block-wrapper">
        <InfoBlock {...headOfficeInfoProps} />
        {/* <InfoBlock {...branchOfficeInfoProps} /> */}
        <InfoBlock {...shenZhenBranchOfficeInfoProps} />
        {/* <img src={fp_wechat_qr_code} alt="FundPark Wechat QR code" width="120" height="120" style={{paddingTop: '16px', boxSizing: 'content-box',}}/> */}
        <FooterNav {...footNavMainTabProps} />
        <FooterNav {...footerNavProps} />
      </div>
      <SocialMediaNav />
      <div className="warning-copy-right-wrapper">
        <div>
          {warningText.map((el, index) => {
            if (index === 1) {
              return (
                <h5 key={"warning-text" + index} className="warning-text-1">
                  {el}
                </h5>
              );
            } else {
              return (
                <h5 key={"warning-text" + index} className={`warning-text-${index}`}>
                  {el}
                </h5>
              );
            }
          })}
        </div>
      </div>
      <div className="copy-right">{copyRightText3}</div>
      <div className="copy-right">
        {copyRightText1}
        {` ${year} `}
        {copyRightText2}
      </div>
    </footer>
  );
};

export default injectIntl(Footer);
