import MediaObject from 'components/MediaObject/MediaObject';
import { TranslatedCultureBlockProps } from 'interface/cultureBlocks.interface'
import React from 'react'

function CultureBlocks(props: TranslatedCultureBlockProps) {
  return (
    <section className="culture-blocks">
      {
        props.cultures.map((info, index) => {
          const isOddNumber = index % 2 === 1;

          const imageEle = <img className="culture-block-image" alt={`culture-${index}`} src={info.image} />;
          const paragraphEle = (<div>
            <p className="culture-block-title">
              {info.title}
            </p>
            <img className="culture-block-image-mobile" alt={`culture-${index}`} src={info.image} />
            {info.paragraphs.map(({ className, text }, index) => (
              <p className={className} key={index}>{text}</p>
            ))}
          </div>);

          return (
            <MediaObject
              className={`culture-block ${info.className}`}
              rightGridProps={{ sm: isOddNumber ? 8 : 4, xs: 12 }}
              leftGridProps={{ sm: isOddNumber ? 4 : 8, xs: 12 }}
              left={isOddNumber ? imageEle : paragraphEle}
              right={isOddNumber ? paragraphEle : imageEle}
            />
          )
        })
      }
    </section>
  )
}

export default CultureBlocks
