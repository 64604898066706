import mapStringToDefinedMessage from "lib/translate/mapStringToDefinedMessage";
import ROUTES from "constants/ROUTES";

export const faqTitleInfo = {
  title: mapStringToDefinedMessage("Frequently Asked Questions")
};

export const faqCardInfo = {
  title: mapStringToDefinedMessage("Have A Question?"),
  descriptions: [
    mapStringToDefinedMessage("Can’t find an answer?"),
    mapStringToDefinedMessage(
      "Drop us an email anytime or speak to us through the live chat during office hours."
    )
  ],
  btn: {
    text: mapStringToDefinedMessage("Contact Us"),
    href: `${ROUTES.CONTACT_US}?ref=faq`,
    btnType: "IM"
  },
};
