import "styles/funder.scss";
import "styles/productPage.scss";

import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import Banner from "components/Banner/banner";
import { TranslatedBannerProps } from "interface/banner.interface";
import { eCommerceFinance } from "constants/banner/info";
import { eCommerceFinanceIntro } from "constants/plainIntro/info";
import PlainIntroBlock from "components/PlainIntroBlock/plainIntroBlock";
import { TranslatedPlainIntroProps } from "interface/plainIntro.interface";
import img1 from "images/eCommerceFinance/img1-en-desktop.png";
import img2 from "images/eCommerceFinance/img1-tc-desktop.png";
import img3 from "images/eCommerceFinance/img1-en-mobile.png";
import img4 from "images/eCommerceFinance/img1-tc-mobile.png";
import PureImage from "components/PureImage/pureImage";
// import LearnMoreBlock from "components/LearnMoreBlock/learnMoreBlock";
// import { TranslatedSeekingBlockProps } from "interface/seekingBlock.interface";
// import { eCommerceContactUsInfo } from "constants/learnMoreBlock/info";
import { keyFeatureInfo } from "constants/cardsWithTitle/keyFeature";
import CardsWithTitle from "components/CardsWithTitle/cardsWithTitle";
import { TranslatedCardsWithTitleProps } from "interface/cardsWithTitle.interface";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconGroupSection from "components/iconGroupSection/iconGroupSection";
import { getLanguage } from "lib/language";
import { eCommerceEligibilityInfoBlockProps } from "constants/iconGroupBlock/info";
import { TranslatedIconGroupInfo } from "interface/iconGroup.interface";
import { eCommerceHowItWorkList } from "constants/procedureBlock/info";
import { TranslatedIntroInfoListProps } from "interface/introInfo.interface";
import DirectionalProcedureBlockWithTitle from "components/ProcedureBlock/verticalProcedureBlockWithTitle";
// import CalculatorBlock from "components/Calculator/calculatorBlock";
// import { calculatorWithTabsInfo } from "constants/calculator/info";
// import { fundingCalculatorInfo } from "constants/calculator/info";
// import { TranslatedCalculatorProps } from "interface/calculator.interface";
import ConnectFormBlock from "components/ConnectForm/connectFormBlock";
import {connectFormEligibilityInfo} from 'constants/connectForm/eligibility';
import {TranslatedConnectFormProps} from 'interface/connectForm.interface';


const ECommerceFinance = (): JSX.Element => {
  const bannerInfoProps: TranslatedBannerProps = mapToTranslatedProps(
    eCommerceFinance
  );

  const eCommerceFinanceFinanceIntroPros: TranslatedPlainIntroProps = mapToTranslatedProps(
    eCommerceFinanceIntro
  );

  // const learnMoreBlockInfoProps: TranslatedSeekingBlockProps = mapToTranslatedProps(
  //   eCommerceContactUsInfo
  // );

  const keyFeatureInfoProps: TranslatedCardsWithTitleProps = mapToTranslatedProps(
    keyFeatureInfo,
  );

  const eCommerceEligibilityInfo: TranslatedIconGroupInfo = mapToTranslatedProps(
    eCommerceEligibilityInfoBlockProps
  );
  
  const eCommerceHowItWorkListInfo: TranslatedIntroInfoListProps = mapToTranslatedProps(
    eCommerceHowItWorkList
  );

  // const calculatorWithTabsInfoProps: TranslatedCalculatorProps = mapToTranslatedProps(
  //   //calculatorWithTabsInfo
  //   fundingCalculatorInfo
  // );

  const connectFormInfoProps: TranslatedConnectFormProps = mapToTranslatedProps(
    connectFormEligibilityInfo
  );

  
  const matches = useMediaQuery('(min-width:576px)');
  const language = getLanguage();
  var img = "";
  if(matches){
    //mobile size
    if(language === "zh-hk"){
      img = img2;
   }else{
      img = img1;
   }
  }else{
    // desktop size
    if(language === "zh-hk"){
      img = img4;
    }else{
      img = img3;
    }
  }

  return (
    <div className="e-commerce-finance-wrapper">
      <Banner {...bannerInfoProps} />
      <PlainIntroBlock {...eCommerceFinanceFinanceIntroPros} />
      <PureImage isPure={true} src={img}/>
      <div className="map-bg-image">
        <CardsWithTitle {...keyFeatureInfoProps} />
      </div>
      <DirectionalProcedureBlockWithTitle {...eCommerceHowItWorkListInfo}/>
      {/* <CalculatorBlock {...calculatorWithTabsInfoProps} /> */}
      <IconGroupSection {...eCommerceEligibilityInfo} />
      <ConnectFormBlock {...connectFormInfoProps}/>
      {/* <LearnMoreBlock {...learnMoreBlockInfoProps} /> */}
    </div>
  );
};

export default ECommerceFinance;
