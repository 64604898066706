// @ts-nocheck

/* eslint-disable react/prop-types */
import React from "react";
import {
  TranslatedProcedureInfoProps,
  SimpleIntroInfoProps
} from "interface/introInfo.interface";
import TranslatedProps from "interface/translatedProps.interface";
import { getLanguage } from "lib/language";
import { BUTTON_CLASSNAME } from "constants/language";
import { Link } from "react-router-dom";
import "styles/suitableBlock.scss";

const ProcedureBlock = ({
  introInfoList,
  btn
}: TranslatedProcedureInfoProps): JSX.Element => {
  const language = getLanguage();
  return (
    <div className="procedure-wrapper">
      <div className="icon-items-wrapper">
        {introInfoList.map(
          (
            el: TranslatedProps<SimpleIntroInfoProps>,
            idx: number
          ): JSX.Element => {
            return (
              <div className="icon-item" key={`introInfo_${idx}`}>
                <div>
                  <img alt={`image_${idx}`} src={el.image} />
                </div>
                <p>{el.title}</p>
              </div>
            );
          }
        )}
      </div>
      {btn && <Btn btn={btn} language={language} />}
    </div>
  );
};

function Btn({ btn, language }): JSX.Element {
  const { href, text, btnType } = btn;
  // return href === "/contact-us" ? (

  // internal links
  return href.substring(0, 1) === '/' ? (
    <>
      <Link
        to={`/${language}${href}`}
        className={
          btn.btnType && `${BUTTON_CLASSNAME[btnType][language]} btn btn-main`
        }
      >
        {text}
      </Link>
    </>
  ) : (
    <a
      className={
        btn.btnType && `${BUTTON_CLASSNAME[btnType][language]} btn btn-main`
      }
      href={`${href}${language}`}
    >
      {text}
    </a>
  );
}

export default ProcedureBlock;
