import React from "react";
import mapToTranslatedProps from "lib/translate/mapToTranslatedProps";

import Banner from "components/Banner/banner";
import {
  termsAndConditionsBannerInfo,
  privacyPolicyBannerInfo,
  dataPolicyBannerInfo,
  personalPrivacyPolicyBannerInfo
} from "constants/banner/info";
import { TranslatedBannerProps } from "interface/banner.interface";

import PlainIntroWithSubtitle from "components/PlainIntroBlock/plainIntroWithSubtitle";
import NestedIntro from "components/PlainIntroBlock/nestedIntro";
import {
  termsAndConditionsInfo,
  privacyPolicyInfo,
  dataPolicyInfo,
  personalPrivacyPolicyInfo,
} from "constants/plainIntro/info";
import {
  TranslatedPlainIntroWithSubtitleProps,
  TranslatedNestedIntroProps
} from "interface/plainIntro.interface";

export const TermsAndConditions = (): JSX.Element => {
  const termsAndConditionsBannerInfoProps: TranslatedBannerProps = mapToTranslatedProps(
    termsAndConditionsBannerInfo
  );

  const termsAndConditionsInfoProps: TranslatedPlainIntroWithSubtitleProps = mapToTranslatedProps(
    termsAndConditionsInfo
  );

  return (
    <div className="about-us">
      <Banner {...termsAndConditionsBannerInfoProps} />
      <PlainIntroWithSubtitle {...termsAndConditionsInfoProps} />
    </div>
  );
};

export const PersonalPrivacyPolicy = (): JSX.Element => {
  const privacyPolicyBannerInfoInfoProps: TranslatedBannerProps = mapToTranslatedProps(
    personalPrivacyPolicyBannerInfo
  );

  const personalPrivacyPolicyInfoProps: TranslatedPlainIntroWithSubtitleProps = mapToTranslatedProps(
    personalPrivacyPolicyInfo
  );

  return (
    <div className="about-us">
      <Banner {...privacyPolicyBannerInfoInfoProps} />
      <PlainIntroWithSubtitle {...personalPrivacyPolicyInfoProps} />
    </div>
  );
};


export const PrivacyPolicy = (): JSX.Element => {
  const privacyPolicyBannerInfoInfoProps: TranslatedBannerProps = mapToTranslatedProps(
    privacyPolicyBannerInfo
  );

  const privacyPolicyInfoProps: TranslatedPlainIntroWithSubtitleProps = mapToTranslatedProps(
    privacyPolicyInfo
  );

  return (
    <div className="about-us">
      <Banner {...privacyPolicyBannerInfoInfoProps} />
      <PlainIntroWithSubtitle {...privacyPolicyInfoProps} />
    </div>
  );
};

export const DataPolicy = (): JSX.Element => {
  const dataPolicyBannerInfoProps: TranslatedBannerProps = mapToTranslatedProps(
    dataPolicyBannerInfo
  );

  const dataPolicyInfoProps: TranslatedNestedIntroProps = mapToTranslatedProps(
    dataPolicyInfo
  );

  return (
    <div className="about-us">
      <Banner {...dataPolicyBannerInfoProps} />
      <NestedIntro {...dataPolicyInfoProps} />
    </div>
  );
};
